<template>
  <select
    class="email-registration-dropdown"
    v-model="valueCopy"
    :required="config.required"
  >
    <option v-if="placeholderText" value="" disabled selected>
      {{ placeholderText }}
    </option>
    <option
      v-for="{ value, label } in options"
      :key="`${value}-${uid}`"
      :value="value"
    >
      {{ label }}
    </option>
  </select>
</template>

<script lang="ts">
import type { EmailRegistrationField } from "@cna/api/editor";
import {
  useCommonGeneralStore,
  useCommonStylesStore,
  type BodyStyles,
} from "@cna/common/stores";
import { useVModel } from "@vueuse/core";
import { storeToRefs } from "pinia";
import {
  computed,
  defineComponent,
  toRefs,
  type PropType,
  type Ref,
} from "vue";
import { useOptionsHelper } from "../hooks/useOptionsHelper";

let uid = 0;

export default defineComponent({
  name: "FormDropdown",
  props: {
    value: String,
    config: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
    uid: {
      type: Number,
      default() {
        return uid++;
      },
    },
  },
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);
    const { config } = toRefs(props);

    const commonGeneral = useCommonGeneralStore();
    const { currentLanguage } = storeToRefs(commonGeneral);

    const { options } = useOptionsHelper(config, valueCopy);

    const placeholderText = computed(
      () =>
        config.value.payload.texts?.[currentLanguage.value].placeholder ?? ""
    );

    const styles = useCommonStylesStore();
    const mainBlockStyles = storeToRefs(styles)
      .mainBlockStyles as Ref<BodyStyles>;

    const selectBackground = computed(
      () =>
        `white url('data:image/svg+xml,${encodeURIComponent(
          `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none"><path d="M1 2.5L5 6.5L9 2.5" stroke="${
            mainBlockStyles.value.form_element_input_style?.color ?? "black"
          }" stroke-width="1.5" stroke-linecap="round" /></svg>`
        )}') no-repeat calc(100% - 10px) 50%`
    );

    return {
      options,
      valueCopy,
      selectBackground,
      placeholderText,
    };
  },
});
</script>

<style lang="scss" scoped>
.email-registration-dropdown {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  @include input-styles;
  background: v-bind(selectBackground);
}
</style>
