import { useProjectsStore } from "@/stores/projects";
import type { PostStyleInput, Template } from "@cna/api/editor";
import type { StylesData } from "@cna/common";
import { useCommonStylesStore } from "@cna/common";
import { api } from "./api";

class StylesService {
  async fetchStyles() {
    const projects = useProjectsStore();
    const commonStyles = useCommonStylesStore();
    commonStyles.appliedStyles = (
      await api.v1.projects.project(projects.currentProjectId!).styles.get()
    ).data.result as unknown as StylesData;
  }

  async updateTemplateStyles(data: PostStyleInput, templateName: Template) {
    const projects = useProjectsStore();
    await api.v1.projects
      .project(projects.currentProjectId!)
      .styles.post(templateName, data);
  }
}

const stylesService = new StylesService();

export default stylesService;
