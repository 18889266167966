import { type FilterSettings } from "@/components/vehicleEventsFiltering/types";
import type {
  GetPublishedDomainsResult,
  VehicleEventsResult,
} from "@cna/api/editor";

export const INITIAL_PROJECT_VEHICLES_EVENTS: VehicleEventsResult["result"] = {
  events_list: [],
};

export const INITIAL_DOMAINS_DATA: GetPublishedDomainsResult["result"] = {
  domains: {
    main: "",
    preview: "",
  },
  suffix: "",
};

export const INITIAL_FILTER_SETTINGS: FilterSettings = {
  system_id: [],
  gvc_version: [],
  timestamp: undefined,
  published_by: [],
  content_version: [],
  status: "",
};
