import { computed, type Ref } from "vue";
import { type DropdownItem, type DropdownItems } from "./types";

export default function useDropdownSearchHandler(
  searchValue: Ref<string>,
  items: DropdownItems,
  currentOptionKey: string | number,
  onChange: (...args: any[]) => any
) {
  const currentElementIndex = computed(() =>
    items?.findIndex(
      (element: DropdownItem) => element.key === currentOptionKey
    )
  );
  const selectItemBySearch = () => {
    if (searchValue.value !== "") {
      let isFindItem = false;
      const startIndex = currentElementIndex.value + 1;
      const maxIndex = items.length;

      isFindItem = findItemBySearchValue(startIndex, maxIndex);

      if (!isFindItem) {
        findItemBySearchValue(0, currentElementIndex.value);
      }
    }

    searchValue.value = "";
  };

  const findItemBySearchValue = (startIndex: number, maxIndex: number) => {
    for (let i = startIndex; i < maxIndex; i++) {
      if (
        String(items[i].value)
          .toLowerCase()
          .indexOf(searchValue.value.toLowerCase()) === 0
      ) {
        onChange(items[i].key);
        return true;
      }
    }
    return false;
  };

  return {
    selectItemBySearch,
  };
}
