<template>
  <div id="welcome" class="page welcome-page">
    <HeaderLayout />
    <BodyLayout />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { welcomePageConfig } from "@/mixins/editIcon/configs/welcomePage";
import { BodyLayout, FooterLayout, HeaderLayout } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WelcomePage",
  components: { HeaderLayout, BodyLayout, FooterLayout },
  mixins: [editIconMixin(welcomePageConfig)],
});
</script>
