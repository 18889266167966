<template>
  <OneColumnLayout>
    <EmailRegistrationPageContent />
  </OneColumnLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OneColumnLayout from "../../columns/OneColumnLayout.vue";
import EmailRegistrationPageContent from "./EmailRegistrationPageContent.vue";

export default defineComponent({
  name: "EmailRegistrationPageBody",
  components: {
    EmailRegistrationPageContent,
    OneColumnLayout,
  },
});
</script>
