import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const dbWelcomePageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".db_welcome-page",
      pageName: PageNames.DB_WELCOME,
      properties: [
        {
          selector: ".db-page__title",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_text",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit welcome message",
        },
        {
          selector: ".db-fv .db-page__title",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_online_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit venue active welcome message",
        },
        {
          selector: ".db-page__checkbox",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "login_text_below_form",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-page__description",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_text_below_form",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: "#teaser_other_text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "teaser_other_text",
          modifiers: {},
          modalTitle: "Edit text",
        },
        {
          selector: "#teaser_image_text",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "teaser_image_text",
          modifiers: {},
          modalTitle: "Edit text",
        },
        {
          selector: ".db-page__link",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "login_text_terms_label",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-primary-button",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "connect_button",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".db-footer__nav-link:nth-child(1)",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "terms_and_conditions_label",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-footer__nav-link:nth-child(2)",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "privacy_policy_label",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-footer__nav-link:nth-child(3)",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "contact_us_label",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-footer__nav-link:nth-child(4)",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "faq_label",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-page__below-main-block-content-text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "below_main_block_content",
          modifiers: {
            top: true,
            left: true,
            offsetX: 160,
          },
          modalTitle: "Edit text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".db_welcome-page",
      pageName: PageNames.DB_WELCOME,
      properties: [],
    },
  ],
]);
