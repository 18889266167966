import type { AxiosRequestConfig } from "axios";
import type { PathBuilder } from "../../utils";
import { client } from "./axios";

export type PostFileResult = {
  stored_row_id: number;
};

export const files = (path: PathBuilder) => {
  const post = (file: File, config?: AxiosRequestConfig<FormData>) => {
    const formData = new FormData();
    formData.append("file", file);

    return client.postForm<PostFileResult>(path.value, formData, config);
  };

  const get = (
    fileName: string,
    config?: Omit<AxiosRequestConfig<undefined>, "responseType">
  ) =>
    client.get<File>(path.slash(fileName).value, {
      ...config,
      responseType: "blob",
    });

  return {
    post,
    get,
  };
};
