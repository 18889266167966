<template>
  <div class="email-registration-form-element">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.email-registration-form-element {
  display: block;
  margin-bottom: 20px;
}

.email-registration-form-element:deep(
    input:not([type="radio"]):not([type="checkbox"])
  ),
.email-registration-form-element:deep(.simple-dropdown__selected-item) {
  padding-top: var(--form_element_input_style_padding_top);
  padding-bottom: var(--form_element_input_style_padding_bottom);
  padding-left: var(--form_element_input_style_padding_left);
  padding-right: var(--form_element_input_style_padding_right);
  font-size: 16px;
}

.input-markdown {
  display: inline-block;
}
</style>
