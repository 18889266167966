<template>
  <ul class="header-navigation flex-row-center">
    <li class="header-navigation__item" v-for="item in items" :key="item.route">
      <router-link
        class="header-navigation__link"
        :to="{ path: `/${currentProjectId}${item.route}`, query }"
      >
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { usePermissions } from "@/hooks/usePermissions";
import { useProjectsStore } from "@/stores/projects";
import { storeToRefs } from "pinia";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import type { HeaderNavigationItems } from "./types";

export default defineComponent({
  name: "HeaderNavigation",
  setup() {
    const projects = useProjectsStore();
    const { currentProjectId } = storeToRefs(projects);
    const route = useRoute();

    const { projectdesignwrite } = usePermissions();

    const query = computed(() => route.query);

    const items = computed(
      () =>
        [
          {
            route: "/text-editor",
            title: projectdesignwrite.value ? "Text editor" : "Design",
          },
          projectdesignwrite.value && {
            route: "/theme-editor",
            title: "Theme editor",
          },
          {
            route: "/settings",
            title: "Settings",
          },
          {
            route: "/versions-history",
            title: "Versions",
          },
        ].filter((x) => !!x) as HeaderNavigationItems
    );

    return {
      currentProjectId,
      query,
      items,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation {
  @include reset-list;
}

.header-navigation__link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin: 0 16px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    color: #a3bac6;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    height: 2px;
    width: 0;
    transition: all 0.3s;
  }

  &:hover:after {
    width: 100%;
    border-bottom: 2px solid #a3bac6;
  }
}

.router-link-active {
  &:after {
    width: 100%;
    background: $white;
    transition: all 0.1s;
  }
}
</style>
