<template>
  <div class="flex-row-center">
    <a :href="itemLink">{{ text }}</a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "LinkColumn",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const itemLink = computed(() => `https://${props.text}`);

    return { itemLink };
  },
});
</script>
