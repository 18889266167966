import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type Payload = {
  texts?: Record<string, { label?: string; placeholder?: string }>;
  items?: Record<string, any>;
};

export type EmailRegistrationField = {
  project_id?: number;
  key: string;
  kind:
    | "email"
    | "text"
    | "phone"
    | "dropdown"
    | "checkbox"
    | "other_text"
    | "radio_button";
  ordinal_num: number;
  display_name: string;
  required: boolean;
  enabled: boolean;
  payload: Payload;
  custom?: boolean;
};

export type GetEmailRegistrationFieldsResult = Result<EmailRegistrationField[]>;

export type PostEmailRegistrationFieldInput = {
  key: string;
  kind: "checkbox" | "other_text";
  ordinal_num: number;
  display_name?: string;
  required?: boolean;
  enabled?: boolean;
  payload?: Payload;
};

export type SingleEmailRegistrationFieldsResult =
  Result<EmailRegistrationField>;

export type PutEmailRegistrationFieldInput = {
  kind: "checkbox" | "other_text";
  display_name?: string;
  required?: boolean;
  enabled?: boolean;
  payload?: Payload;
};

export type PostEnableEmailRegistrationFieldsInput = {
  key: string;
  enabled: boolean;
}[];

export type PostReorderEmailRegistrationFieldsInput = {
  key: string;
  ordinal_num: number;
}[];

export const fields = (path: PathBuilder) => {
  const getAll = (config?: AxiosRequestConfig) =>
    client.get<GetEmailRegistrationFieldsResult>(
      path.slash("all").value,
      config
    );

  const get = (config?: AxiosRequestConfig) =>
    client.get<GetEmailRegistrationFieldsResult>(path.value, config);

  const post = (
    body: PostEmailRegistrationFieldInput,
    config?: AxiosRequestConfig
  ) =>
    client.post<SingleEmailRegistrationFieldsResult>(path.value, body, config);

  const put = (
    fieldKey: string,
    body: PutEmailRegistrationFieldInput,
    config?: AxiosRequestConfig
  ) =>
    client.put<SingleEmailRegistrationFieldsResult>(
      path.slash(fieldKey).value,
      body,
      config
    );

  const del = (fieldKey: string, config?: AxiosRequestConfig) =>
    client.delete<undefined>(path.slash(fieldKey).value, config);

  const postDefault = (config?: AxiosRequestConfig) =>
    client.post<undefined>(path.slash("default").value, undefined, config);

  const postEnabled = (
    body: PostEnableEmailRegistrationFieldsInput,
    config?: AxiosRequestConfig
  ) => client.post(path.slash("enabled").value, body, config);

  const postReorder = (
    body: PostReorderEmailRegistrationFieldsInput,
    config?: AxiosRequestConfig
  ) => client.post(path.slash("reorder").value, body, config);

  return {
    getAll,
    get,
    post,
    put,
    delete: del,
    postDefault,
    postEnabled,
    postReorder,
  };
};

export const emailRegistration = (path: PathBuilder) => {
  return {
    fields: fields(path.slash("fields")),
  };
};
