<template>
  <div class="flex-row-center-space-between">
    <span>{{ title }}</span>
    <ButtonsGroup :buttons-params="buttonsParams" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import { type ButtonProps } from "@/components/button/types";

export default defineComponent({
  name: "SettingsConfirmationRow",
  components: {
    ButtonsGroup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonsParams: {
      type: Object as PropType<ButtonProps[]>,
      required: true,
    },
  },
});
</script>
