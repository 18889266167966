<template>
  <EditorLabel
    :label="label"
    :prepend-icon-class-name="prependIconClassName"
    :prepend-icon-symbol="prependIconSymbol"
  >
    <input
      v-maska="mask"
      class="editor-input__input-element"
      type="text"
      :value="value"
      :disabled="disabled"
      @input="onInput(($event.target as HTMLInputElement)?.value)"
    />
  </EditorLabel>
</template>

<script lang="ts">
import type { Maybe } from "@cna/common";
import { maska } from "maska";
import { defineComponent } from "vue";
import EditorLabel from "./EditorLabel.vue";

export default defineComponent({
  name: "EditorInput",
  components: {
    EditorLabel,
  },
  directives: { maska },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    prependIconClassName: {
      type: String,
      required: false,
      default: "",
    },
    prependIconSymbol: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      type: [String, Object],
      required: false,
      default: "",
    },
  },
  emits: ["update:option"],
  setup(_, { emit }) {
    const onInput = (value: Maybe<string>) => {
      emit("update:option", value);
    };

    return {
      onInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-input__input-element {
  padding: 12px 14px 12px 24px;
  border: solid 1px $grey3;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.14;
  color: #123962;
  max-width: 95px;
}
</style>
