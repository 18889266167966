import { type ExtractPropTypes } from "vue";
import { modalProps } from "../elements";

export const pageBackgroundEditingModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  fieldName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Page Background",
  },
  ...modalProps,
} as const;

export type PageBackgroundEditingModalProps = ExtractPropTypes<
  typeof pageBackgroundEditingModalProps
>;
