const route = (opts: { path: string; title: string }) =>
  ({
    path: opts.path,
    name: `admin-${opts.path}`,
    component: () => import(`@/admin/views/${opts.path}/index.vue`),
    meta: {
      title: opts.title,
      key: `admin/${opts.path}`,
    },
  } as const);

export const views = [
  route({ path: "attributes", title: "Attributes" }),
  route({ path: "features", title: "Features" }),
  route({ path: "templates", title: "Templates" }),
] as const;
