<template>
  <main id="secondary-page-content" class="db-secondary-page__container">
    <DBPageHeader class="db-secondary-page__header" :logo-url="logoUrl" />
    <DBBackToLoginButton
      class="db-secondary-page__btn"
      :config="buttonConfig"
    />
    <!--    Slot for FAQ image-->
    <slot />
    <div class="db-secondary-page__content">
      <div class="db-secondary-page__content-column" />
      <div class="db-secondary-page__text">
        <Markdown :data="markdownData" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import Markdown from "../../markdown/Markdown.vue";
import DBBackToLoginButton from "../button/DBBackToLoginButton.vue";
import { type ButtonProps } from "../button/types";
import DBPageHeader from "../pageHeader/DBPageHeader.vue";

export default defineComponent({
  name: "DBSecondaryPageBody",
  components: {
    DBPageHeader,
    DBBackToLoginButton,
    Markdown,
  },
  props: {
    markdownData: {
      type: String,
    },
    buttonConfig: {
      type: Object as PropType<ButtonProps>,
      required: true,
    },
    logoUrl: {
      type: String,
    },
  },
});
</script>
