<template>
  <router-view></router-view>
</template>

<script lang="ts">
import projectsService from "@/services/projects.service";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    projectsService.initProjectsData();
  },
});
</script>
