<template>
  <div id="email-registration-page" class="page email-registration-page">
    <HeaderLayout />
    <EmailRegistrationPageBodyTwoColumn v-if="isTwoColumn" />
    <EmailRegistrationPageBody v-else />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { type EditIconProperties } from "@/mixins/editIcon/types";
import { useProjectsStore } from "@/stores/projects";
import {
  EmailRegistrationPageBody,
  EmailRegistrationPageBodyTwoColumn,
  FooterLayout,
  HeaderLayout,
  useEmailRegistrationHandler,
} from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent, onBeforeMount } from "vue";
import { emailRegistrationPageConfig } from "../mixins/editIcon/configs/emailRegistrationPage";

const EDIT_ICON_VALUE: EditIconProperties = {
  selector: "",
  iconType: "text",
  modalType: "EmailRegistrationPayloadsEditingModal",
  templateName: "body",
  fieldName: "",
  modifiers: {
    left: true,
    outer: true,
  },
};

const EDIT_INPUT_ICON_VALUE: EditIconProperties = {
  selector: "",
  iconType: "brush",
  modalType: "EmailRegistrationInputSettingsModal",
  templateName: "body",
  fieldName: "",
  modifiers: {
    left: true,
    outer: true,
  },
};

const EDIT_CHECKBOX_ICON_VALUE: EditIconProperties = {
  selector: "",
  iconType: "brush",
  modalType: "CheckboxThemeModal",
  templateName: "body",
  fieldName: "",
  modifiers: {
    left: true,
    outer: true,
  },
};

const EDIT_RADIO_BUTTON_ICON_VALUE: EditIconProperties = {
  selector: "",
  iconType: "brush",
  modalType: "RadioButtonThemeModal",
  templateName: "body",
  fieldName: "",
  modifiers: {
    left: true,
    outer: true,
  },
};

const textEditorConfigData = {
  ...emailRegistrationPageConfig.get("text-editor"),
};
const themeEditorConfigData = {
  ...emailRegistrationPageConfig.get("theme-editor"),
};

export default defineComponent({
  name: "EmailRegistrationPage",
  components: {
    HeaderLayout,
    FooterLayout,
    EmailRegistrationPageBody,
    EmailRegistrationPageBodyTwoColumn,
  },
  mixins: [editIconMixin(emailRegistrationPageConfig)],
  setup() {
    const projects = useProjectsStore();

    const { isTwoColumn } = storeToRefs(projects);

    const { allProjectFieldsKeys, allProjectFields } =
      useEmailRegistrationHandler();

    const matchByKey = (fieldKey: string) => (element: { key?: string }) =>
      element.key === fieldKey;

    const isTextField = (fieldKey: string) => {
      const element = allProjectFields.value?.find(matchByKey(fieldKey));
      return element?.kind === "other_text";
    };

    const isInputField = (fieldKey: string) => {
      const element = allProjectFields.value?.find(matchByKey(fieldKey));
      return element?.kind === "text" || element?.kind === "email";
    };

    const isCheckboxField = (fieldKey: string) => {
      const element = allProjectFields.value?.find(matchByKey(fieldKey));
      return element?.kind === "checkbox";
    };

    const isRadioButtonField = (fieldKey: string) => {
      const element = allProjectFields.value?.find(matchByKey(fieldKey));
      return element?.kind === "radio_button";
    };

    onBeforeMount(() => {
      allProjectFieldsKeys?.forEach((fieldName: string) => {
        return textEditorConfigData.properties?.push({
          ...EDIT_ICON_VALUE,
          selector: `.${fieldName}`,
          modalTitle: isTextField(fieldName) ? "Edit text" : "Edit label",
          fieldName,
        });
      });

      allProjectFieldsKeys?.some((fieldName: string) => {
        if (isInputField(fieldName)) {
          themeEditorConfigData.properties?.push({
            ...EDIT_INPUT_ICON_VALUE,
            selector: `.${fieldName}`,
            fieldName: `registration-email`,
          });

          return true;
        }
      });

      allProjectFieldsKeys?.some((fieldName: string) => {
        if (isCheckboxField(fieldName)) {
          themeEditorConfigData.properties?.push({
            ...EDIT_CHECKBOX_ICON_VALUE,
            selector: `.${fieldName}`,
            fieldName: "registration_form_element_checkbox_style",
          });

          return true;
        }
      });

      allProjectFieldsKeys?.some((fieldName: string) => {
        if (isRadioButtonField(fieldName)) {
          themeEditorConfigData.properties?.push({
            ...EDIT_RADIO_BUTTON_ICON_VALUE,
            selector: `.${fieldName}`,
            fieldName: "registration_form_element_radio_button_style",
          });

          return true;
        }
      });
    });

    return {
      isTwoColumn,
    };
  },
});
</script>
