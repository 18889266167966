<template>
  <div class="user-actions">
    <SimpleTable
      :rows="userActions"
      :columns="tableColumnsConfig"
      row-key="id"
    />
    <div class="user-actions__show-more" v-if="showMoreButton">
      <EditorButton title="Show more" :on-click="nextUserActions" />
    </div>
  </div>
</template>

<script lang="ts">
import SimpleTable from "@/components/table/SimpleTable.vue";
import type { TableColumnConfig } from "@/components/table/types";
import { useProjectsStore } from "@/stores/projects";
import type { UserAction } from "@cna/api/editor";
import { computed, defineComponent, ref, h, type Ref } from "vue";
import EditorButton from "@/components/button/EditorButton.vue";
import { api } from "@/services/api";
import TextColumn from "@/components/table/columns/TextColumn.vue";

export default defineComponent({
  name: "UserActions",
  components: {
    SimpleTable,
    EditorButton,
  },
  setup() {
    const projects = useProjectsStore();

    let showMoreButton = ref(true);
    let userActions: Ref<Array<UserAction>> = ref([]);
    let page = ref(1);
    const nextUserActions = async () => {
      const actions = (
        await api.v1.projects
          .project(projects.currentProjectId!)
          .userActions.get(page.value)
      ).data.result.actions;

      userActions.value.push(...actions);
      if (actions.length == 0) {
        showMoreButton.value = false;
      }
      page.value++;
    };

    nextUserActions();

    const renderActionColumn = (row: UserAction) => {
      const hasVersionNumberRelation =
        row.action_name.startsWith("publish_") ||
        row.action_name.startsWith("version_");

      const actionText = hasVersionNumberRelation
        ? `${row.action_name}: ${row.relation_id}`
        : row.action_name;

      return h(TextColumn, { text: actionText });
    };

    const tableColumnsConfig = computed(() => {
      const actionColumn: TableColumnConfig<UserAction> = {
        type: "Render",
        heading: "Action",
        width: "55%",
        columnKey: "action_name",
        render: renderActionColumn,
      };

      const cols: TableColumnConfig<UserAction>[] = [
        {
          type: "DateTime",
          heading: "Time",
          width: "15%",
          columnKey: "created",
        },
        {
          type: "Text",
          heading: "Email",
          width: "30%",
          columnKey: "email",
        },
        actionColumn,
      ];
      return cols;
    });

    return {
      userActions,
      tableColumnsConfig,
      nextUserActions,
      showMoreButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-actions:deep(.table-column) {
  font-weight: normal;
  font-size: 14px;
}

.user-actions__show-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
