<template>
  <component :is="config.type" to="/" class="header__logo">
    <img
      v-if="config.imageUrl"
      class="header__img"
      :src="config.imageUrl"
      alt="Logo"
    />
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { type LogoConfig } from "../types";

export default defineComponent({
  name: "LogoImage",
  props: {
    config: {
      type: Object as PropType<LogoConfig>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.header__img {
  max-height: 3rem;
  max-width: min(100%, 400px);
}
</style>
