import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type PostLocalBody = {
  system_ids: string[];
};

export type PostLocalResult = Result<{
  snapshot_id: number;
  upserted_ids: number[];
}>;
export type PostCloudResult = Result<{
  domain?: string;
  snapshot_id: number;
  cloud_publish_result?: {
    dropped: number;
    upserted: number[];
    duplicated: boolean;
  };
}>;

export type PublishVersionInput = { versionId: number } & (
  | {
      type: "cloud";
    }
  | { type: "local"; system_ids: string[] }
);

export const publish = (path: PathBuilder) => {
  const postCloud = (versionId: number, config?: AxiosRequestConfig) =>
    client.post<PostCloudResult>(
      path.slash("cloud").slash("version").slash(versionId).value,
      undefined,
      config
    );

  const postPreview = (versionId: number, config?: AxiosRequestConfig) =>
    client.post<PostCloudResult>(
      path.slash("preview").slash("version").slash(versionId).value,
      undefined,
      config
    );

  const postLocal = (
    versionId: number,
    body: PostLocalBody,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostLocalResult>(
      path.slash("local").slash("version").slash(versionId).value,
      body,
      config
    );

  const postAllLocal = (versionId: number, config?: AxiosRequestConfig) =>
    client.post<PostLocalResult>(
      path.slash("local").slash("version").slash(versionId).slash("all").value,
      undefined,
      config
    );

  const publishVersion = (
    input: PublishVersionInput,
    config?: AxiosRequestConfig
  ) =>
    input.type === "cloud"
      ? postCloud(input.versionId, config)
      : postLocal(
          input.versionId,
          {
            system_ids: input.system_ids,
          },
          config
        );

  return {
    postCloud,
    postPreview,
    postLocal,
    postAllLocal,
    publishVersion,
  };
};
