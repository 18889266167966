import { inject, provide, ref, type InjectionKey, type Ref } from "vue";

export type UsageData = {
  used: number;
  limit: number;
};

const symShowTrafficUsageMessage: InjectionKey<Ref<boolean>> = Symbol(
  "symShowTrafficUsageMessage"
);
const symFetchUsageData: InjectionKey<() => Promise<UsageData>> =
  Symbol("symFetchUsageData");

export const provideFetchUsageData = (fn: () => Promise<UsageData>) => {
  provide(symFetchUsageData, fn);
};

export const injectFetchUsageData = () =>
  inject(symFetchUsageData, async () => ({ used: 48, limit: 100 }));

export const provideShowTrafficUsageMessage = (bool: Ref<boolean>) => {
  provide(symShowTrafficUsageMessage, bool);
};

export const injectShowTrafficUsageMessage = () =>
  inject(symShowTrafficUsageMessage, ref(false));
