import { useCommonGeneralStore, useRouteQuery } from "@cna/common";
import { watch } from "vue";
import { useRouter } from "vue-router";

export const useWatchLangQuery = () => {
  const common = useCommonGeneralStore();
  const langValue = useRouteQuery("lang", { type: String });
  const router = useRouter();

  watch(
    () => common.currentLanguage,
    (lang) => {
      langValue.value = lang;
      router.push({
        path: router.currentRoute.value.path,
        query: { ...router.currentRoute.value.query, lang },
      });
    }
  );

  watch(
    langValue,
    (lang) => {
      if (lang) {
        common.currentLanguage = lang;
        return;
      }
    },
    { immediate: true }
  );
};
