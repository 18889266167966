import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const nowConnectedPageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".now-connected-page",
      pageName: PageNames.NOW_CONNECTED,
      properties: [
        {
          selector: ".page__text-block",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "now_connected_text",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".secondary-content",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "now_connected_page_secondary_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit Text",
        },
        {
          selector: ".traffic-usage-message .info",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "header",
          fieldName: "traffic_usage_message_info",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit Text",
        },
        {
          selector: ".button-wrapper",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "venue_redirect_button_label",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".footer__rights-text",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "footer_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit copyright text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".now-connected-page",
      pageName: PageNames.NOW_CONNECTED,
      properties: [
        {
          selector: ".header",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "header",
          fieldName: "header",
          modifiers: {
            center: true,
            offsetX: 250,
          },
        },
        {
          selector: ".header__logo",
          iconType: "image",
          modalType: "ImagesEditingModal",
          templateName: "header",
          fieldName: "logo",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".page__main-block",
          iconType: "brush",
          modalType: "PageBackgroundEditingModal",
          templateName: "body",
          fieldName: "background",
          modifiers: {
            outer: true,
            offsetX: 120,
          },
        },
        {
          selector: ".page__text-block",
          iconType: "brush",
          modalType: "PageMainBlockEditingModal",
          templateName: "body",
          fieldName: "main",
          modifiers: {
            center: true,
          },
        },
        {
          selector: ".page__btn",
          iconType: "brush",
          modalType: "ButtonStylesEditingModal",
          templateName: "body",
          fieldName: "button",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".footer",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "footer",
          fieldName: "footer",
          modifiers: {
            center: true,
          },
        },
        {
          selector: ".traffic-usage-message",
          iconType: "brush",
          modalType: "TrafficUsageMessageEditingModal",
          templateName: "header",
          fieldName: "traffic-usage-message-theme-icon",
          modifiers: {
            center: true,
          },
        },
      ],
    },
  ],
]);
