import type { Ref } from "vue";

export type InjectedHooks = {
  useLanguageDropdown: () => {
    currentLanguage: Ref<{
      key: string;
      value: string;
    }>;
    currentLanguageKey: Ref<string>;
    dropdownOptions: Ref<
      {
        key: string;
        value: string;
      }[]
    >;
    onSelectLanguage: (lang: string) => void;
  };
};
export const injectedHooks: Partial<InjectedHooks> = {};
export const validateHooks = (
  hooks: Partial<InjectedHooks>
): hooks is InjectedHooks => !!hooks.useLanguageDropdown;

export const getInjectedHooks = (): InjectedHooks => {
  const h = injectedHooks;
  const valid = validateHooks(h);
  if (!valid) {
    throw new Error("useLanguageDropdown is not set!");
  }
  return h;
};
