import { defineAsyncComponent, type Component } from "vue";
import * as props from "./components";
import { type default as ButtonStylesEditingModalType } from "./components/ButtonStylesEditingModal.vue";
import { type default as CheckboxThemeModalType } from "./components/CheckboxThemeModal.vue";
import { type default as ConfirmationModalType } from "./components/ConfirmationModal.vue";
import { type default as EmailRegistrationInputSettingsModalType } from "./components/EmailRegistrationInputSettingsModal.vue";
import { type default as EmailRegistrationPayloadsEditingModalType } from "./components/EmailRegistrationPayloadsEditingModal.vue";
import { type default as FormsEditingModalType } from "./components/FormsEditingModal.vue";
import { type default as ImagesEditingModalType } from "./components/ImagesEditingModal.vue";
import { type default as LocalPublishVersionModalType } from "./components/LocalPublishVersionModal.vue";
import { type default as MarkdownEditingModalType } from "./components/MarkdownEditingModal.vue";
import { type default as OneTextFieldEditingModalType } from "./components/OneTextFieldEditingModal.vue";
import { type default as PageBackgroundEditingModalType } from "./components/PageBackgroundEditingModal.vue";
import { type default as PageMainBlockEditingModalType } from "./components/PageMainBlockEditingModal.vue";
import { type default as PlainTextModalType } from "./components/PlainTextModal.vue";
import { type default as RadioButtonThemeModalType } from "./components/RadioButtonThemeModal.vue";
import { type default as TextAndBackgroundColorEditingModalType } from "./components/TextAndBackgroundColorEditingModal.vue";
import { type default as TrafficUsageMessageEditingModalType } from "./components/TrafficUsageMessageEditingModal.vue";
import { type default as VehiclesMonitoringModalType } from "./components/VehiclesMonitoringModalType.vue";

const asyncModal = <T extends Component>(name: string) =>
  defineAsyncComponent<T>(() => import(`./components/${name}.vue`));

export const ButtonStylesEditingModal = asyncModal<
  typeof ButtonStylesEditingModalType
>("ButtonStylesEditingModal");

export const ConfirmationModal =
  asyncModal<typeof ConfirmationModalType>("ConfirmationModal");

export const EmailRegistrationPayloadsEditingModal = asyncModal<
  typeof EmailRegistrationPayloadsEditingModalType
>("EmailRegistrationPayloadsEditingModal");

export const EmailRegistrationInputSettingsModal = asyncModal<
  typeof EmailRegistrationInputSettingsModalType
>("EmailRegistrationInputSettingsModal");

export const FormsEditingModal =
  asyncModal<typeof FormsEditingModalType>("FormsEditingModal");

export const ImagesEditingModal =
  asyncModal<typeof ImagesEditingModalType>("ImagesEditingModal");

export const LocalPublishVersionModal = asyncModal<
  typeof LocalPublishVersionModalType
>("LocalPublishVersionModal");

export const MarkdownEditingModal = asyncModal<typeof MarkdownEditingModalType>(
  "MarkdownEditingModal"
);

export const OneTextFieldEditingModal = asyncModal<
  typeof OneTextFieldEditingModalType
>("OneTextFieldEditingModal");

export const PageBackgroundEditingModal = asyncModal<
  typeof PageBackgroundEditingModalType
>("PageBackgroundEditingModal");

export const PageMainBlockEditingModal = asyncModal<
  typeof PageMainBlockEditingModalType
>("PageMainBlockEditingModal");

export const PlainTextModal =
  asyncModal<typeof PlainTextModalType>("PlainTextModal");

export const TextAndBackgroundColorEditingModal = asyncModal<
  typeof TextAndBackgroundColorEditingModalType
>("TextAndBackgroundColorEditingModal");

export const VehiclesMonitoringModal = asyncModal<
  typeof VehiclesMonitoringModalType
>("VehiclesMonitoringModal");

export const CheckboxThemeModal =
  asyncModal<typeof CheckboxThemeModalType>("CheckboxThemeModal");

export const RadioButtonThemeModal = asyncModal<
  typeof RadioButtonThemeModalType
>("RadioButtonThemeModal");

export const TrafficUsageMessageEditingModal = asyncModal<
  typeof TrafficUsageMessageEditingModalType
>("TrafficUsageMessageEditingModal");

export const modalMap = {
  ButtonStylesEditingModal: [
    ButtonStylesEditingModal,
    props.buttonStylesEditingModalProps,
  ],
  ConfirmationModal: [ConfirmationModal, props.confirmationModalProps],
  EmailRegistrationPayloadsEditingModal: [
    EmailRegistrationPayloadsEditingModal,
    props.emailRegistrationPayloadsEditingModalProps,
  ],
  EmailRegistrationInputSettingsModal: [
    EmailRegistrationInputSettingsModal,
    props.emailRegistrationInputSettingsModalProps,
  ],
  FormsEditingModal: [FormsEditingModal, props.formsEditingModalProps],
  ImagesEditingModal: [ImagesEditingModal, props.imagesEditingModalProps],
  LocalPublishVersionModal: [
    LocalPublishVersionModal,
    props.localPublishVersionModalProps,
  ],
  MarkdownEditingModal: [MarkdownEditingModal, props.markdownEditingModalProps],
  OneTextFieldEditingModal: [
    OneTextFieldEditingModal,
    props.oneTextFieldEditingModalProps,
  ],
  PageBackgroundEditingModal: [
    PageBackgroundEditingModal,
    props.pageBackgroundEditingModalProps,
  ],
  PageMainBlockEditingModal: [
    PageMainBlockEditingModal,
    props.pageMainBlockEditingModalProps,
  ],
  PlainTextModal: [PlainTextModal, props.plainTextModalProps],
  TextAndBackgroundColorEditingModal: [
    TextAndBackgroundColorEditingModal,
    props.textAndBackgroundColorEditingModalProps,
  ],
  VehiclesMonitoringModal: [
    VehiclesMonitoringModal,
    props.vehiclesMonitoringModalProps,
  ],
  CheckboxThemeModal: [CheckboxThemeModal, props.checkboxThemeModalProps],
  RadioButtonThemeModal: [
    RadioButtonThemeModal,
    props.radioButtonThemeModalProps,
  ],
  TrafficUsageMessageEditingModal: [
    TrafficUsageMessageEditingModal,
    props.trafficUsageMessageEditingModalProps,
  ],
} as const;

export const fieldEditingModalMap = {
  ButtonStylesEditingModal,
  EmailRegistrationPayloadsEditingModal,
  CheckboxThemeModal,
  EmailRegistrationInputSettingsModal,
  ImagesEditingModal,
  MarkdownEditingModal,
  OneTextFieldEditingModal,
  PageBackgroundEditingModal,
  PageMainBlockEditingModal,
  TextAndBackgroundColorEditingModal,
  RadioButtonThemeModal,
  TrafficUsageMessageEditingModal,
};

export type ModalName = keyof typeof modalMap;
export type FieldEditingModalName = keyof typeof fieldEditingModalMap;
