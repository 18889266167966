import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import { type PathBuilder } from "../../utils";
import { client } from "./axios";

export type GetLanguageTagsResult = Result<
  Record<
    string,
    {
      longname: string;
      isoname: string;
    }
  >
>;

export const languageTags = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<GetLanguageTagsResult>(path.value, config);

  return {
    get,
  };
};
