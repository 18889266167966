<template>
  <div id="db_faq" class="page db_faq-page">
    <div class="db-secondary-page">
      <HeaderLayout />

      <DBSecondaryPageBody
        :markdown-data="footerTexts.faq_body"
        :logo-url="headerLogo.link"
        :button-config="backToLoginButtonProps"
      >
        <template #default>
          <img
            class="db-secondary-page__faq-image"
            :src="FAQTopImage.link"
            alt=""
          />
        </template>
      </DBSecondaryPageBody>

      <FooterLayout />
    </div>
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { dbFAQPageConfig } from "@/mixins/editIcon/configs/dbFAQPage";
import {
  DBSecondaryPageBody,
  FooterLayout,
  HeaderLayout,
  useCommonGeneralStore,
  useCommonImagesStore,
  type DBFooterTexts,
} from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "DBFAQPage",
  components: {
    HeaderLayout,
    FooterLayout,
    DBSecondaryPageBody,
  },
  mixins: [editIconMixin(dbFAQPageConfig)],
  setup() {
    const commonImages = useCommonImagesStore();
    const commonGeneral = useCommonGeneralStore();
    const FAQTopImage = computed(() => commonImages.FAQTopImage);
    const footerTexts = computed(
      () => commonGeneral.footerTexts as DBFooterTexts
    );
    const backToLoginButtonProps = computed(() => ({
      title: commonGeneral.bodyTexts.back_button_label,
    }));
    const headerLogo = computed(() => commonImages.headerLogo);

    return {
      FAQTopImage,
      footerTexts,
      headerLogo,
      backToLoginButtonProps,
    };
  },
});
</script>
