<template>
  <main class="db-page now-connected">
    <NowConnectedBody />
    <DBBelowMainBlockContent />
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DBBelowMainBlockContent from "../belowMainBlockContent/DBBelowMainBlockContent.vue";
import NowConnectedBody from "./components/NowConnectedBody.vue";

export default defineComponent({
  name: "DBNowConnectedMainBlock",
  components: {
    NowConnectedBody,
    DBBelowMainBlockContent,
  },
});
</script>
