<template>
  <label class="editor-input">
    <span v-if="label" class="editor-input__label-text">{{ label }}</span>

    <span class="editor-input__icon" :class="prependIconClassName">{{
      prependIconSymbol
    }}</span>
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "EditorInput",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    prependIconClassName: {
      type: String,
      required: false,
      default: "",
    },
    prependIconSymbol: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.editor-input {
  position: relative;
  display: inline-block;
}

.editor-input__label-text {
  position: absolute;
  top: -12px;
  left: 5px;
  margin-bottom: 4px;
  font-size: 10px;
  line-height: 1.2;
  color: $middle-blue;
}

.editor-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  font-size: 14px;
  color: $grey5;
}
</style>
