import { computed } from "vue";
import type { LogoConfig } from "../../components";
import { useCommonGeneralStore } from "../../stores/general";
import { useCommonImagesStore } from "../../stores/images";

export default function useHeaderTemplatesHandler() {
  const commonGeneral = useCommonGeneralStore();
  const commonImages = useCommonImagesStore();

  const logoConfig = computed(
    () =>
      ({
        imageUrl: commonImages.headerLogo.link,
        type: commonGeneral.typesOfLinksBlocks.headerLogoLink,
      } as LogoConfig)
  );

  return {
    logoConfig,
  };
}
