import { computed } from "vue";
import { useCommonGeneralStore } from "../stores/general";

const CAPTIVE_REFERRER = "captive";
const LOGIN_REFERRER = "login";
const IDENTIFIER_STRING = "{referrer}";

export function useVenueRedirectHelper() {
  const commonGeneral = useCommonGeneralStore();
  const venueRedirectLink = computed(() => commonGeneral.venueRedirectLink);
  const linkWithReferrer = (referrer: string) =>
    venueRedirectLink.value.replace(IDENTIFIER_STRING, referrer);
  const buttonLink = computed(() =>
    commonGeneral.isPortal ? linkWithReferrer(CAPTIVE_REFERRER) : undefined
  );
  const autoRedirectLink = computed(() => linkWithReferrer(LOGIN_REFERRER));

  return {
    buttonLink,
    autoRedirectLink,
  };
}
