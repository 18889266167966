import { type ExtractPropTypes, type PropType } from "vue";
import type { LanguageItem } from "../../dropdown";

let renderCount = 0;

export const props = {
  currentLanguage: {
    type: Object as PropType<LanguageItem>,
    required: true as const,
  },
  currentLanguageKey: {
    type: String,
    required: true as const,
  },
  dropdownOptions: {
    type: Array as PropType<LanguageItem[]>,
    required: true as const,
  },
  onSelectLanguage: {
    type: Function as PropType<(lang: string) => void>,
    required: true as const,
  },
  listboxId: {
    type: String,
    default: () => `language-dropdown-listbox-${renderCount++}`,
  },
};

export type LanguageDropdownProps = ExtractPropTypes<typeof props>;
