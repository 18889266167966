<template>
  <div class="simple-input">
    <input
      :id="config.id"
      ref="root"
      v-maska="mask"
      class="simple-input__input"
      :style="config.styles"
      :type="type"
      v-model="valueCopy"
      :name="config.name"
      :autocomplete="config.autocomplete"
      @keydown.shift.tab="config.onKeydownShiftTab"
      :required="config.required"
    />
    <ErrorMessageInput :message="config.error" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from "vue";
import { type InputConfig } from "../types";
import ErrorMessageInput from "../errorMessageInput/ErrorMessageInput.vue";
import { maska } from "maska";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "CommonInput",
  directives: { maska },
  components: { ErrorMessageInput },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<InputConfig>,
      required: true,
    },
    mask: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const valueCopy = useVModel(props, "modelValue", ctx.emit);

    const root = ref();

    return { root, valueCopy };
  },
});
</script>

<style lang="scss">
.simple-input {
  position: relative;
}

.simple-input__input {
  display: table-cell;
  padding: 0 20px;
  @include input-styles;
}
</style>
