import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { type ListProjectData } from "../../../../api/src/editor";
import { metaKeyOrTextEditor } from "../../routes/router";
import { useProjectsStore } from "../../stores/projects";

export const usePagesDropdownHelper = () => {
  const router = useRouter();
  const route = useRoute();
  const selectedProjectId = ref(0);
  const searchValue = ref("");
  const projectsStore = useProjectsStore();
  const { projects, currentProject } = storeToRefs(projectsStore);

  const areProjectsLoaded = computed(() => projects.value.length > 0);

  const matchProjectAttributes = (projectAttr: string | number | undefined) => {
    if (!projectAttr) return;

    return String(projectAttr)
      .toLowerCase()
      .includes(searchValue.value.toLowerCase(), 0);
  };

  const projectsDropdownOptions = computed(() => {
    const filteredItems = [...projects.value].filter(
      (project: ListProjectData) => {
        const includeName = project.name
          .toLowerCase()
          .includes(searchValue.value.toLowerCase());

        if (
          includeName ||
          matchProjectAttributes(project.icomera_customer_id) ||
          matchProjectAttributes(project.icomera_account_id) ||
          matchProjectAttributes(project.local_attributes?.[0].portal_key)
        ) {
          return project;
        }
      }
    );

    return filteredItems.map((project) => ({
      key: project.id,
      value: project.name,
    }));
  });

  const onSelectProject = (projectId: number) => {
    selectedProjectId.value = projectId;
    searchValue.value = "";
  };

  const selectedProject = computed(() => {
    searchValue.value = "";
    const index = [...projects.value].findIndex(
      (project) => project.id === selectedProjectId.value
    );
    return projects.value[index];
  });
  const redirectToProject = async (value: string | number) => {
    searchValue.value = "";
    await router.push({
      path: `/${value}/${metaKeyOrTextEditor(route)}`,
      query: { ...route.query },
    });
  };

  const onChangeSearch = (value: string | number) => {
    searchValue.value = String(value);
  };

  return {
    searchValue,
    projectsDropdownOptions,
    currentProject,
    selectedProject,
    selectedProjectId,
    areProjectsLoaded,
    onSelectProject,
    onChangeSearch,
    redirectToProject,
  };
};
