<template>
  <div class="dropdown">
    <select
      class="dropdown__select"
      aria-label="Dropdown"
      @change="onChange(($event.target as HTMLInputElement).value)"
    >
      <option v-if="placeholder" value="" disabled selected>
        {{ placeholder }}
      </option>
      <option
        v-for="item in items"
        :key="item.key"
        class="dropdown__list-item"
        :value="item.key"
        :selected="item.key == currentOptionKey"
      >
        {{ item.value }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { type DropdownItems } from "@cna/common";

export default defineComponent({
  name: "EditorDropdown",
  props: {
    items: {
      required: true,
      type: Array as PropType<DropdownItems>,
    },
    onChange: {
      required: true,
      type: Function as PropType<(value: any) => void>,
    },
    placeholder: {
      required: false,
      type: String,
      default: "",
    },
    currentOptionKey: {
      type: String || Number,
      required: false,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown__select {
  appearance: none;
  background: $white-transparent
    url("../../../../common/src/assets/images/icons/chevron-down-blue.svg")
    right 8px center/8px 10px no-repeat;
  padding: 8px 20px 8px 16px;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue;
  border: solid 1px $grey3;
}

.dropdown__list-item {
  padding: 12px;
}
</style>
