import router from "@/routes/router";
import emailRegistrationService from "@/services/emailRegistration.service";
import imagesService from "@/services/images.service";
import languagesService from "@/services/languages.service";
import stylesService from "@/services/styles.service";
import textsService from "@/services/texts.service";
import versionsHistoryService from "@/services/versionsHistory.service";
import { useProjectsStore } from "@/stores/projects";
import { handleFeatures } from "@/utils/featuresUtils";
import {
  getLocalstorageItem,
  removeLocalstorageItem,
  setLocalstorageItem,
  useCommonGeneralStore,
} from "@cna/common";
import { AxiosError } from "axios";
import { api } from "./api";
import { isNotAuthenticated } from "./api/helpers";

const fetchProjects = async () => {
  const projects = useProjectsStore();
  const data = (await api.v1.projects.get()).data.result;
  projects.projects = [...data];
};

const fetchCurrentProjectId = () => {
  const projects = useProjectsStore();
  const projectId = getLocalstorageItem("projectId");
  if (projectId != null) {
    projects.currentProjectId = Number(projectId);
  }
};

const updateCurrentProjectId = (projectId: number) => {
  setLocalstorageItem("projectId", projectId);
  fetchCurrentProjectId();
};

const removeCurrentProjectId = () => {
  const projects = useProjectsStore();

  removeLocalstorageItem("projectId");
  projects.currentProjectId = null;
};

const fetchCurrentProject = async () => {
  const projects = useProjectsStore();
  const commonGeneral = useCommonGeneralStore();
  await api.v1.projects
    .project(projects.currentProjectId!)
    .get()
    .then((response) => {
      projects.currentProject = response.data.result;
      const { templates, features } = projects.currentProject;
      commonGeneral.templates = templates;
      commonGeneral.features = features;
    })
    .catch((error) => {
      if (error instanceof AxiosError && isNotAuthenticated(error.status)) {
        router.push({ name: "login" });
      } else {
        removeCurrentProjectId();
        router.push({ name: "home" });
      }

      throw error;
    })
    .then(emailRegistrationService.fetchAllEmailRegistrationFields);
};

const initProjectsData = async () => {
  const projects = useProjectsStore();
  projects.isProjectDataLoaded = false;
  projects.areProjectsInited = false;
  projectsService.fetchCurrentProjectId();
  await projectsService.fetchProjects();
  projects.areProjectsInited = true;
};

const initProjectData = async () => {
  const projects = useProjectsStore();
  projects.isProjectDataLoaded = false;

  projectsService
    .fetchCurrentProject()
    .then(() => {
      return Promise.all([
        languagesService.initLanguagesSettings(),
        stylesService.fetchStyles(),
        imagesService.fetchImagesData(),
        versionsHistoryService.fetchPublishedDomains(),
        emailRegistrationService.fetchAllProjectFields(),
      ]);
    })
    .then(async ([lang]) => {
      await textsService.fetchTexts(lang);
      handleFeatures(projects.currentProject.features);
    })
    .then(() => (projects.isProjectDataLoaded = true));
};

const projectsService = {
  fetchCurrentProject,
  fetchCurrentProjectId,
  fetchProjects,
  updateCurrentProjectId,
  removeCurrentProjectId,
  initProjectsData,
  initProjectData,
};

export default projectsService;
