import type { AxiosInstance } from "axios";
import { v1 } from "./v1";
import { client } from "./v1/axios";

export const createClient = (configure?: (a: AxiosInstance) => void) => {
  if (configure) {
    configure(client);
  }
  return {
    v1: v1(),
  };
};

export * from "./v1";
