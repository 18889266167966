import { type TableRowConfig } from "@/components/table/types";
import { openModal, type ConfirmationModalProps } from "@/modals";

import { api } from "@/services/api";
import projectsService from "@/services/projects.service";
import versionsHistoryService from "@/services/versionsHistory.service";
import { useProjectsStore } from "@/stores/projects";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default function useTableButtonsConfigs(row: TableRowConfig) {
  const router = useRouter();
  const projects = useProjectsStore();
  const versionsHistory = useVersionsHistoryStore();
  const { domains } = storeToRefs(versionsHistory);
  const isLocalPortalEnv = versionsHistory.isLocalPortalEnv;

  const canPublish = domains.value?.production || isLocalPortalEnv;

  const getInfoText = (domain: string) =>
    canPublish
      ? ""
      : `<div style="line-height: 1.43">You need to create a domain in <a class="editor__link" href="/settings">Settings</a> before publishing to ${domain}</div>`;

  const previewButtonConfig = {
    title: "Publish to Staging",
    infoText: getInfoText("Staging"),
    disabled: !canPublish,
    onClick: () => {
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await versionsHistoryService.publishPreviewVersion(row.id);
        },
        modalTitle: "Publish to Staging",
        modalText:
          "You are about to publish this version to the Staging domain. You will then be able to preview it in your browser.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Publish",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  const restoreButtonConfig = {
    title: "Restore version in Editor",
    onClick: () => {
      versionsHistory.restoringVersionId = row.id;
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await versionsHistoryService.restoreVersion(
            versionsHistory.restoringVersionId
          );
          await versionsHistoryService.createVersion(
            `Reverted to version ${row.id}`
          );
          await projectsService.initProjectData();
        },
        modalTitle: "Restore version",
        modalText:
          "The version will be restored in the Editor. You will then be able to edit it and save a new version.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Restore",
            buttonIcon: "icon-restore",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  const publishButtonConfig = {
    title: "Publish to Production",
    infoText: getInfoText("Production"),
    disabled: !canPublish,
    onClick: async () => {
      if (versionsHistory.isLocalPortalEnv) {
        router.replace({
          query: {
            ...router.currentRoute.value.query,
            localPublishVersion: row.id,
          },
        });
      } else if (!row.published) {
        const confirmationModalConfig: ConfirmationModalProps["config"] = {
          confirmAction: async () => {
            await versionsHistoryService.publishVersion(row.id);
            await versionsHistoryService.fetchVersions();
          },
          modalTitle: "Publish to Production",
          modalText:
            "You are about to publish this version to the Production domain. This version will be pushed to vehicles and visible to passengers.",
          buttonsConfigs: {
            cancelButton: {
              buttonTitle: "Cancel",
            },
            confirmButton: {
              buttonTitle: "Publish",
            },
          },
        };

        openModal("ConfirmationModal", { config: confirmationModalConfig });
      }
    },
  };

  const setDefaultVersionConfig = {
    title: "Set as default version",
    disabled: !versionsHistory.versionIsPublishedToVehicles(row.id),
    infoText: versionsHistory.versionIsPublishedToVehicles(row.id)
      ? ""
      : "A version must be published to at least one vehicle before it can be set as the default version",
    onClick: () => {
      versionsHistory.restoringVersionId = row.id;
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await api.v1.projects.project(projects.currentProjectId!).put({
            default_version: row.id,
          });

          projects.currentProject.default_version = row.id;
        },
        modalTitle: `Set ${row.id} as the default version`,
        modalText:
          "The default version is deployed to all new trains that have not yet received a portal.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Confirm",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  return {
    previewButtonConfig,
    restoreButtonConfig,
    publishButtonConfig,
    setDefaultVersionConfig,
  };
}
