import "froala-editor/css/froala_style.min.css";

import "@/assets/styles/main.scss";
import Tooltip from "@/directives/tooltip";
import $toastify from "@/plugins/toastify";
import ContextMenu from "@imengyu/vue3-context-menu";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import { createApp } from "vue";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import App from "./App.vue";
import router from "./routes/router";
import { pinia } from "./stores";

import { applyDefaultStyles } from "@cna/common";

createApp(App)
  .use(pinia)
  .use(router)
  .use($toastify)
  .use(Tooltip)
  .use(ContextMenu)
  .use(VueAwesomePaginate)
  .mount("#app");

applyDefaultStyles();
