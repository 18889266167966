/*
this provider allows a parent component to inject the function used to get the live user count
*/

import { inject, provide, type InjectionKey } from "vue";
import type { Maybe } from "../../types";

type GetLiveUserCount = Maybe<() => Promise<string>>;

const getLiveUserCountKey: InjectionKey<GetLiveUserCount> =
  Symbol("getLiveUserCount");

export const provideGetLiveUserCount = (getLiveUserCount: GetLiveUserCount) => {
  provide(getLiveUserCountKey, getLiveUserCount);
};

export const injectGetLiveUserCount = () => {
  const getLiveUserCount = inject(getLiveUserCountKey);

  return {
    getLiveUserCount,
  };
};
