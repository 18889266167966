import { type ExtractPropTypes } from "vue";
import { modalProps } from "../elements";

export const buttonStylesEditingModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Button style",
  },
  ...modalProps,
} as const;

export type ButtonStylesEditingModalProps = ExtractPropTypes<
  typeof buttonStylesEditingModalProps
>;
