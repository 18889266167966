export enum PageNames {
  WELCOME = "welcome",
  CONTACT_US = "contact_us",
  NOW_CONNECTED = "now_connected",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  PRIVACY_POLICY = "privacy_policy",
  DB_WELCOME = "db_welcome",
  DB_CONTACT_US = "db_contact_us",
  DB_NOW_CONNECTED = "db_now_connected",
  DB_TERMS_AND_CONDITIONS = "db_terms_and_conditions",
  DB_PRIVACY_POLICY = "db_privacy_policy",
  DB_FAQ = "db_faq",
  RETURNING_PAGE = "returning",
  OTP_VERIFICATION = "otp_verification",
  REGISTRATION = "email_registration",
  EMAIL_VERIFICATION = "email_verification",
}

export type Pages =
  | "welcome"
  | "now-connected"
  | "contact-us"
  | "terms-and-conditions"
  | "privacy-policy"
  | "returning-page"
  | "db_welcome"
  | "db_now-connected"
  | "db_contact-us"
  | "db_terms-and-conditions"
  | "db_privacy-policy"
  | "db_faq"
  | "otp-verification"
  | "email-registration-page"
  | "email-verification-page";

export interface PageConfig {
  componentName: string;
  title: string;
}
