import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";
import { useCommonGeneralStore } from "./general";
import { INITIAL_SELECTED_PHONE_NUMBER_WITH_COUNTRY_CODE } from "./INITIAL_VALUES";
import { type CustomError } from "./types";

export const useCommonOTPVerificationStore = defineStore(
  "commonOTPVerification",
  () => {
    const selectedPhoneNumberWithCountryCode = ref(
      INITIAL_SELECTED_PHONE_NUMBER_WITH_COUNTRY_CODE
    );
    const error: Ref<CustomError | string> = ref({});
    const buttonStatuses = ref({
      isSendingCode: false,
      isVerifying: false,
    });

    const commonGeneral = useCommonGeneralStore();

    const withPhoneInputData = computed(() => {
      if (commonGeneral.features?.with_phone_input) {
        return commonGeneral.features.with_phone_input.countries;
      }
      return [];
    });

    return {
      selectedPhoneNumberWithCountryCode,
      error,
      buttonStatuses,
      withPhoneInputData,
    };
  }
);
