<template>
  <TwoColumnLayout>
    <template #first>
      <NowConnectedPageContent />
    </template>
    <template #second>
      <SecondaryContent>
        <Markdown :data="nowConnectedPageSecondaryText" />
      </SecondaryContent>
    </template>
  </TwoColumnLayout>
</template>

<script lang="ts">
import { useEmailRegistrationSecondaryText } from "@cna/common/hooks/useEmailRegistrationSecondaryText";
import { defineComponent } from "vue";
import SecondaryContent from "../../columns/SecondaryContent.vue";
import TwoColumnLayout from "../../columns/TwoColumnLayout.vue";
import Markdown from "../../markdown/Markdown.vue";
import NowConnectedPageContent from "./NowConnectedPageContent.vue";

export default defineComponent({
  name: "NowConnectedPageBody",
  components: {
    TwoColumnLayout,
    NowConnectedPageContent,
    SecondaryContent,
    Markdown,
  },
  setup() {
    const nowConnectedPageSecondaryText = useEmailRegistrationSecondaryText(
      "now_connected_page_secondary_text"
    );
    return {
      nowConnectedPageSecondaryText,
    };
  },
});
</script>
