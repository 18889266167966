import type { EmailRegistrationField } from "@cna/api/editor";
import { computed, type Ref } from "vue";
import { useCommonGeneralStore } from "../../../stores/general";
import { addRequiredFieldToHTML } from "../../../utils/requiredFields";

export const useLabel = (config: Ref<EmailRegistrationField>) => {
  const commonGeneral = useCommonGeneralStore();

  const currentLanguage = computed(() => commonGeneral.currentLanguage);
  const fieldTitle = computed(
    () => config.value.payload.texts?.[currentLanguage.value]?.label ?? ""
  );
  const label = computed(() =>
    config.value.required
      ? addRequiredFieldToHTML(fieldTitle.value)
      : fieldTitle.value
  );

  return {
    label,
  };
};

export default useLabel;
