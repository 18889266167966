<template>
  <div class="embedded-input">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.embedded-input {
  position: relative;
}

.embedded-input :deep(.floating-label) {
  color: var(--form_element_input_style_label_color);
}
</style>
