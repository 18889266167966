<template>
  <img
    v-if="logoUrl"
    class="db-header__logo"
    :src="logoUrl"
    :alt="logoAltText"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useCommonGeneralStore, type DBBodyTexts } from "../../../stores";

export default defineComponent({
  name: "DBPageHeader",
  props: {
    logoUrl: {
      type: String,
    },
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();

    const logoAltText = computed(
      () => (commonGeneral.bodyTexts as DBBodyTexts).logo_alt_text ?? "DB Logo"
    );

    return {
      logoAltText,
    };
  },
});
</script>

<style lang="scss" scoped>
.db-header__logo {
  width: 56px;
  height: 40px;
}
</style>
