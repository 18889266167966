<template>
  <component v-if="templateName !== ''" :is="templateName" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import { useCommonGeneralStore } from "../stores/general";
import { WithCenteredLogo, WithLeftAlignedLogo } from "../templates";

export default defineComponent({
  name: "HeaderLayout",
  components: {
    WithLeftAlignedLogo,
    WithCenteredLogo,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const { templatesKeys } = commonGeneral;
    const { templateName } = useTemplateNameForLayout("header", templatesKeys);

    return {
      templateName,
    };
  },
});
</script>
