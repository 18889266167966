<template>
  <div ref="editorNavigation" class="editor-navigation flex-row-center">
    <PagesDropdown
      class="pages-tabs-select"
      :pages="pages"
      :on-select="selectPage"
    />
    <EditorDropdown
      class="editor-navigation__language-dropdown"
      :items="dropdownOptions"
      :on-change="onSelectLanguage"
      :current-option-key="currentLanguageKey"
    />
    <router-link
      :to="toPreview"
      target="_blank"
      class="editor-navigation__preview-button"
    >
      <span class="icon-open-tab" />
      Preview
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  headerBounds,
  headerHeight,
} from "@/components/header/EditorHeader.vue";
import { getInjectedHooks } from "@cna/common";
import { useElementBounding } from "@vueuse/core";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import EditorDropdown from "../../components/dropdown/EditorDropdown.vue";
import PagesDropdown from "../../components/pagesDropdown/PagesDropdown.vue";
import { type Pages } from "../../pages/types";
import { useProjectsStore } from "../../stores/projects";

const editorNavigation = ref();
const editorNavigationBounds = useElementBounding(editorNavigation);
export const editorNavigationHeight = computed(
  () => editorNavigationBounds.height.value + "px"
);

export default defineComponent({
  name: "EditorNavigation",
  components: { PagesDropdown, EditorDropdown },
  setup() {
    const languageDropdownProps = getInjectedHooks().useLanguageDropdown();
    const projects = useProjectsStore();
    const pages = computed(() => projects.projectPages);

    const selectPage = (pageName: Pages) => {
      const page = document.getElementById(pageName);
      let positionToScroll: number;
      if (pageName === "welcome" || pageName === "db_welcome") {
        positionToScroll = 0;
      } else {
        positionToScroll =
          page!.getBoundingClientRect().top +
          window.scrollY -
          headerBounds.height.value -
          editorNavigationBounds.height.value;
      }

      window.scrollTo({ top: positionToScroll, behavior: "smooth" });
    };

    const route = useRoute();

    const toPreview = computed(() => ({
      path: "preview",
      query: route.query,
    }));

    return {
      editorNavigation,
      headerHeight,
      ...languageDropdownProps,
      pages,
      selectPage,
      toPreview,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-navigation {
  position: fixed;
  top: v-bind(headerHeight);
  justify-content: space-between;
  padding: 20px;
  font-family: $nunito;
  font-weight: bold;
  width: calc(100vw);
  background-color: $grey2;
  z-index: 20;
  left: 0;
  right: 0;
}

.editor-navigation__language-dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.editor-navigation:deep(.dropdown__select) {
  font-family: $nunito;
  font-size: 14px;
  font-weight: bold;
}

.editor-navigation__preview-button {
  display: flex;
  align-items: center;
  font-family: $nunito;
  color: $dark-blue;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  span {
    margin-right: 5px;
    font-size: 18px;
  }
}
</style>
