<template>
  <button class="text-button" @click="onClick">
    <slot>{{ title }}</slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "TextButton",
  props: {
    title: {
      type: String,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.text-button {
  cursor: pointer;
  padding: 20px 0;
  font-size: 16px;
  line-height: 20px;
}
</style>
