<template>
  <div class="buttons-group flex-row-center">
    <component
      :is="componentName(item.withAcknowledge)"
      v-for="(item, index) in buttonsParams"
      :key="index"
      :title="item.title ?? defaultTitle(index)"
      :on-click="item.onClick"
      :button-style="item.buttonStyle ?? defaultStyle(index)"
      :button-icon="item.buttonIcon"
      :disabled="item.disabled"
      :acknowledge-text="item.acknowledgeText"
    />
  </div>
</template>

<script lang="ts">
import { ButtonStyle, type ButtonProps } from "@/components/button/types";
import { defineComponent, type PropType } from "vue";
import EditorButton from "./EditorButton.vue";
import ButtonWithAcknowledge from "./ButtonWithAcknowledge.vue";

export default defineComponent({
  name: "ButtonsGroup",
  components: { EditorButton, ButtonWithAcknowledge },
  props: {
    buttonsParams: {
      type: Array as PropType<Array<ButtonProps>>,
      required: true,
    },
  },
  setup() {
    const defaultStyle = (index: number) =>
      index === 0 ? ButtonStyle.LIGHT : ButtonStyle.DARK;

    const componentName = (withAcknowledge: boolean | undefined) =>
      withAcknowledge ? "ButtonWithAcknowledge" : "EditorButton";

    const defaultTitle = (index: number) => {
      if (index === 0) {
        return "Close";
      }
      if (index === 1) {
        return "OK";
      }
      return "";
    };

    return {
      defaultStyle,
      defaultTitle,
      componentName,
    };
  },
});
</script>

<style lang="scss" scoped>
.buttons-group button {
  margin-right: 5px;
  margin-left: 5px;
}
.buttons-group button:first-child {
  margin-right: 5px;
  margin-left: 0px;
}
.buttons-group button:last-child {
  margin-left: 5px;
  margin-right: 0px;
}
</style>
