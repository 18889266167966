import { type Template, type TemplatesKeys } from "@cna/api/editor";

// the caller should import all possible components that can be set up here for his layout
export function useTemplateNameForLayout(
  layout: Template,
  templatesKeys: TemplatesKeys
) {
  let templateName = "";

  if (layout === "header") {
    switch (templatesKeys.header) {
      case "with_left_aligned_logo":
        templateName = "WithLeftAlignedLogo";
        break;
      case "with_centered_logo":
        templateName = "WithCenteredLogo";
        break;
      case "no_header":
        templateName = "";
        break;
    }
  }

  if (layout === "body") {
    switch (templatesKeys.body) {
      case "connect_with_checkbox":
        templateName = "ConnectWithCheckbox";
        break;
      case "connect_with_checkbox_logo_leftAligned":
        templateName = "ConnectWithCheckboxLogoLeftAligned";
        break;
      case "db_fv":
        templateName = "DeutscheBahnFernverkehr";
        break;
      case "connect_with_email_registration":
        templateName = "ConnectWithEmailRegistration";
        break;
      case "email_registration_two_column":
        templateName = "EmailRegistrationTwoColumn";
        break;
    }
  }

  if (layout === "footer") {
    switch (templatesKeys.footer) {
      case "with_contact_terms_privacy":
        templateName = "WithContactTermsPrivacy";
        break;
      case "with_contact_terms_privacy_faq_customSelect":
        templateName = "WithContactTermsPrivacyFAQCustomSelect";
        break;
    }
  }

  return { templateName };
}
