import { isArray, isPlainObject } from "./inspect";

export const keys = <T extends Record<keyof any, any>>(obj: T) =>
  Object.keys(obj) as (keyof T)[];

export const cloneDeep = <T>(obj: T, defaultValue = obj): T => {
  if (isArray(obj)) {
    return obj.reduce((result, val) => [...result, cloneDeep(val, val)], []);
  }

  if (isPlainObject(obj)) {
    return keys(obj).reduce(
      (result: any, key) => ({
        ...result,
        [key]: cloneDeep(obj[key], obj[key]),
      }),
      {}
    );
  }
  return defaultValue;
};
