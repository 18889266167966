import { INITIAL_IMAGES } from "./INITIAL_VALUES";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCommonImagesStore = defineStore("commonImages", () => {
  const imagesData = ref(INITIAL_IMAGES);

  const headerLogo = computed(() => imagesData.value.header.logo);
  const FAQTopImage = computed(() => imagesData.value.footer.faq_top_image);
  const bodyBackgroundStyles = computed(() => imagesData.value.body);

  return {
    imagesData,
    headerLogo,
    FAQTopImage,
    bodyBackgroundStyles,
  };
});
