import type { EmailRegistrationField } from "@cna/api/editor";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

type SortedEmailRegistrationFields = {
  form_fields: EmailRegistrationField[];
  consent_checkboxes: EmailRegistrationField[];
  other: EmailRegistrationField[];
};

export const useEmailRegistrationStore = defineStore(
  "emailRegistration",
  () => {
    const allEmailRegistrationFields: Ref<EmailRegistrationField[]> = ref([]);

    const sortedEmailRegistrationFields = computed(() => {
      const sortedFields: SortedEmailRegistrationFields = {
        form_fields: [],
        consent_checkboxes: [],
        other: [],
      };
      const formFieldsTypes = ["email", "text", "phone", "dropdown"];
      const consentsCheckboxesTypes = ["checkbox"];
      const otherFieldsTypes = ["other_text"];

      allEmailRegistrationFields.value.forEach((field) => {
        if (formFieldsTypes.includes(field.kind)) {
          sortedFields.form_fields.push(field);
        }
        if (consentsCheckboxesTypes.includes(field.kind)) {
          sortedFields.consent_checkboxes.push(field);
        }
        if (otherFieldsTypes.includes(field.kind)) {
          sortedFields.other.push(field);
        }
      });

      return sortedFields;
    });

    return {
      allEmailRegistrationFields,
      sortedEmailRegistrationFields,
    };
  }
);
