<template>
  <div class="search-input" :class="{ 'no-results': noResult }">
    <input
      ref="root"
      :value="inputValue"
      class="search-input__input"
      type="text"
      aria-label="Search"
      @click="config.onClick"
      @keydown.esc="config.onKeydownEsc"
      @keydown.shift.tab="config.onKeydownShiftTab"
      @keydown.tab.exact="config.onKeydownTabExact"
      @keydown.down="config.onKeydownDown"
      @keydown.up="config.onKeydownUp"
      @input="onChange"
    />
    <span class="search-input__icon-search" :class="setIcon" />
    <span
      v-if="inputValue"
      class="search-input__icon-close icon-close"
      aria-label="Clear search"
      @click="clearSearchInput"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  type PropType,
  ref,
} from "vue";
import { type InputConfig } from "../types";

export default defineComponent({
  name: "SearchInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<InputConfig>,
      required: true,
    },
    noResult: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultSearchIcon: {
      type: String,
      required: false,
      default: "icon-search-default",
    },
    noResultsSearchIcon: {
      type: String,
      required: false,
      default: "icon-search-no-results",
    },
  },
  emits: ["update:searchInput"],
  setup(props, { emit }) {
    const root = ref();
    const inputValue = ref();

    onBeforeMount(() => {
      inputValue.value = props.value;
    });

    const clearSearchInput = () => {
      inputValue.value = "";
      emit("update:searchInput", inputValue.value);
    };

    const onChange = (event: any) => {
      inputValue.value = event.target.value;
      emit("update:searchInput", inputValue.value);
    };

    const setIcon = computed(() =>
      props.noResult ? props.noResultsSearchIcon : props.defaultSearchIcon
    );

    return { root, clearSearchInput, inputValue, onChange, setIcon };
  },
});
</script>

<style lang="scss" scoped>
.search-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.search-input .search-input__input {
  padding: 10px 10px 10px 36px;
  width: 100%;
  border-bottom: 2px solid $black;

  &::placeholder {
    color: $grey10;
  }

  &:focus-visible {
    outline: none;
    border-bottom-color: $blue;
  }
}

.no-results .search-input__input {
  border-bottom: 2px solid red;
}

.search-input__icon-search {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 27px;
  transform: translateY(-50%);
}

.search-input__input:focus-visible + .search-input__icon-search {
  color: $blue;
}

.no-results .search-input__input + .search-input__icon-search {
  color: red;
}

.search-input__icon-close {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 14px;
  color: inherit;
}
</style>
