import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type UserAction = {
  id: number;
  email: string;
  action_name: string;
  created: string;
  relation_id: string;
};

export type UserActionsResult = Result<{
  actions: UserAction[];
}>;

export const userActions = (path: PathBuilder) => {
  const get = (page: number = 1, config?: AxiosRequestConfig<undefined>) =>
    client.get<UserActionsResult>(`${path.value}?page=${page}`, config);

  return {
    get,
  };
};
