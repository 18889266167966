import { type ExtractPropTypes } from "vue";
import { modalProps } from "../elements";

export const emailRegistrationInputSettingsModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Edit Input Field Theme",
  },
  ...modalProps,
} as const;

export type EmailRegistrationInputSettingsModalProps = ExtractPropTypes<
  typeof emailRegistrationInputSettingsModalProps
>;
