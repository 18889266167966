<template>
  <div id="db_welcome" class="page db_welcome-page">
    <HeaderLayout />
    <PageBody />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { dbWelcomePageConfig } from "@/mixins/editIcon/configs/dbWelcomePage";
import {
  FooterLayout,
  HeaderLayout,
  BodyLayout as PageBody,
} from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DBWelcomePage",
  components: { FooterLayout, HeaderLayout, PageBody },
  mixins: [editIconMixin(dbWelcomePageConfig)],
});
</script>
