import type { EmailRegistrationField } from "@cna/api/editor";
import { useCommonGeneralStore } from "@cna/common/stores";
import type { Maybe } from "@cna/common/types";
import { entries } from "@cna/common/utils/general";
import { storeToRefs } from "pinia";
import { computed, watch, type Ref } from "vue";

type Items = Record<string, Record<string, any>>;

export const useOptionsHelper = (
  config: Ref<EmailRegistrationField>,
  valueCopy: Ref<string | undefined>
) => {
  const commonGeneral = useCommonGeneralStore();
  const { currentLanguage } = storeToRefs(commonGeneral);

  const options = computed(() => {
    const items: Maybe<Items> = config.value.payload.items;
    if (!items) {
      return [];
    }

    const opts: { value: any; label: string; $order: number }[] = [];

    entries(items).forEach(([k, v]) => {
      opts.push({
        value: k,
        label: v[currentLanguage.value],
        $order: v.$order,
      });
    });

    opts.sort((a, b) => a.$order - b.$order);

    return opts;
  });

  watch(
    [options, valueCopy],
    ([opts, val]) => {
      if (val === undefined) {
        return;
      }

      if (!opts.find((x) => x.value == val)) {
        valueCopy.value = "";
      }
    },
    {
      immediate: true,
    }
  );

  return {
    options,
  };
};
