<template>
  <OneColumnLayout>
    <NowConnectedPageContent />
  </OneColumnLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OneColumnLayout from "../../columns/OneColumnLayout.vue";
import NowConnectedPageContent from "./NowConnectedPageContent.vue";

export default defineComponent({
  name: "NowConnectedPageBody",
  components: {
    OneColumnLayout,
    NowConnectedPageContent,
  },
});
</script>
