<template>
  <div id="returning-page" class="page returning-page">
    <HeaderLayout />
    <ReturningPageBody />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { returningPageConfig } from "@/mixins/editIcon/configs/returningPage";
import { FooterLayout, HeaderLayout, ReturningPageBody } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReturningPage",
  components: { HeaderLayout, FooterLayout, ReturningPageBody },
  mixins: [editIconMixin(returningPageConfig)],
});
</script>
