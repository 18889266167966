import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const privacyPolicyPageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".privacy-policy-page",
      pageName: PageNames.PRIVACY_POLICY,
      properties: [
        {
          selector: ".page__main-block",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "footer",
          fieldName: "privacy_policy_body",
          modifiers: {
            left: true,
            top: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".page__btn",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "back_button_label",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".footer__rights-text",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "footer_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit copyright text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".privacy-policy-page",
      pageName: PageNames.TERMS_AND_CONDITIONS,
      properties: [
        {
          selector: ".header",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "header",
          fieldName: "header",
          modifiers: {
            center: true,
            offsetX: 250,
          },
        },
        {
          selector: ".header__logo",
          iconType: "image",
          modalType: "ImagesEditingModal",
          templateName: "header",
          fieldName: "logo",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".page__markdown",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "body",
          fieldName: "main",
          modifiers: {
            center: true,
          },
        },
        {
          selector: ".page__main-block",
          iconType: "brush",
          modalType: "PageBackgroundEditingModal",
          templateName: "body",
          fieldName: "background",
          modifiers: {
            outer: true,
            offsetX: 120,
          },
        },
        {
          selector: ".page__btn",
          iconType: "brush",
          modalType: "ButtonStylesEditingModal",
          templateName: "body",
          fieldName: "button",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".footer",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "footer",
          fieldName: "footer",
          modifiers: {
            center: true,
          },
        },
      ],
    },
  ],
]);
