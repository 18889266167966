import { ButtonStyle } from "@/components/button/types";
import { type PropType } from "vue";

export const buttonsSharedProps = {
  title: {
    type: String,
  },
  buttonStyle: {
    type: String as PropType<ButtonStyle>,
    default: ButtonStyle.DARK,
  },
  onClick: {
    type: [Function, Array] as PropType<() => any | (() => any)[]>,
  },
  buttonIcon: {
    type: String,
    required: false,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
};
