<template>
  <div id="terms-and-conditions" class="page terms-and-conditions-page">
    <HeaderLayout />
    <SecondaryPageBody
      :markdown-data="footerTexts.terms_and_conditions_body"
      :is-scrolled="true"
    />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { termsAndConditionsPageConfig } from "@/mixins/editIcon/configs/termsAndConditionsPage";
import {
  FooterLayout,
  HeaderLayout,
  SecondaryPageBody,
  useCommonGeneralStore,
} from "@cna/common";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "TermsAndConditionsPage",
  components: {
    HeaderLayout,
    FooterLayout,
    SecondaryPageBody,
  },
  mixins: [editIconMixin(termsAndConditionsPageConfig)],
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const footerTexts = computed(() => commonGeneral.footerTexts);

    return {
      footerTexts,
    };
  },
});
</script>
