<template>
  <div class="table-header flex-row-center-space-between">
    <component
      :is="componentName(column)"
      v-for="column in columns"
      :key="column.heading"
      class="table-header__cell"
      :style="columnStyles(column)"
      :title="column.heading"
      :text="column.heading"
      :on-click="column.headerColumnConfig?.onClick"
    >
      {{ column.heading }}
    </component>
  </div>
</template>

<script lang="ts">
import CheckboxColumn from "@/components/table/columns/CheckboxColumn.vue";
import TextColumn from "@/components/table/columns/TextColumn.vue";
import { type TableColumnConfig } from "@/components/table/types";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "TableHeader",
  components: {
    TextColumn,
    CheckboxColumn,
  },
  props: {
    columns: {
      type: Array as PropType<Array<TableColumnConfig>>,
      required: true,
    },
  },
  setup(_) {
    const columnStyles = (column: TableColumnConfig) => ({
      width: column.width,
      maxWidth: column.width,
    });

    const componentName = (column: TableColumnConfig) =>
      column.headerColumnConfig?.type ?? "TextColumn";

    return { columnStyles, componentName };
  },
});
</script>

<style lang="scss" scoped>
.table-header {
  background-color: $grey0;
  color: $grey-dark;
  border-bottom: 1px solid $grey3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.table-header__cell {
  padding: 15px 22px 11px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.table-header:deep(span) {
  word-break: normal;
}
</style>
