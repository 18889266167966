<template>
  <EmailRegistrationFormElementWrap v-if="config.kind === 'radio_button'">
    <RadioButtonGroup
      :data-field-id="fieldId"
      :config="config"
      v-model:value="(inputValue as string)"
      :label="label"
    />
  </EmailRegistrationFormElementWrap>
  <EmailRegistrationFormElementWrap v-else-if="config.kind === 'checkbox'">
    <CommonCheckbox
      :data-field-id="fieldId"
      :label="label"
      :required="config.required"
      @update:checked="onChangeCheckbox"
    >
      <Markdown :data="label" />
    </CommonCheckbox>
  </EmailRegistrationFormElementWrap>
  <EmailRegistrationFormElementWrap v-else-if="config.kind === 'other_text'">
    <Markdown :data-field-id="fieldId" :data="label" />
  </EmailRegistrationFormElementWrap>
  <EmailRegistrationFormElementWrap v-else>
    <LabelRender
      v-if="styles.label_type !== 'embedded'"
      :for="fieldId"
      :label="label"
    />
    <EmbeddedInput
      v-if="
        styles.label_type === 'embedded' &&
        ['text', 'email'].includes(config.kind) &&
        typeof inputValue === 'string'
      "
      :data-field-id="fieldId"
      v-model:model-value="inputValue"
      :config="formElementConfig"
      :type="config.kind"
      :label="label"
    />
    <CommonInput
      v-else-if="
        ['text', 'email'].includes(config.kind) &&
        typeof inputValue === 'string'
      "
      v-model:model-value="inputValue"
      :data-field-id="fieldId"
      :config="formElementConfig"
      :type="config.kind"
    />
    <EmbeddedFormDropdown
      v-else-if="styles.label_type === 'embedded' && config.kind === 'dropdown'"
      :data-field-id="fieldId"
      :config="config"
      v-model:value="(inputValue as string)"
      :label="label"
    />
    <FormDropdown
      v-else-if="styles.label_type !== 'embedded' && config.kind === 'dropdown'"
      :data-field-id="fieldId"
      :config="config"
      v-model:value="(inputValue as string)"
      :label="label"
    />
    <PhoneInputWithCountryCodeDropdown
      v-else-if="config.kind === 'phone'"
      :data-field-id="fieldId"
      :name="fieldId"
      :label="label"
      :preferred-countries-iso="[]"
      @update:option="onChangeMobilePhone"
    />
  </EmailRegistrationFormElementWrap>
</template>

<script lang="ts">
import type { EmailRegistrationField } from "@cna/api/editor";
import { storeToRefs } from "pinia";
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch,
  type PropType,
  type Ref,
} from "vue";
import { useCommonGeneralStore } from "../../stores/general";
import { useCommonStylesStore } from "../../stores/styles";
import {
  type BodyStyles,
  type PhoneNumberWithCountryData,
} from "../../stores/types";
import type { Maybe } from "../../types";
import CommonCheckbox from "../checkbox/CommonCheckbox.vue";
import Markdown from "../markdown/Markdown.vue";
import PhoneInputWithCountryCodeDropdown from "../phoneInputWithCountryCodeDropdown/PhoneInputWithCountryCodeDropdown.vue";
import CommonInput from "../input/CommonInput.vue";
import EmailRegistrationFormElementWrap from "./components/EmailRegistrationFormElementWrap.vue";
import EmbeddedFormDropdown from "./components/EmbeddedFormDropdown.vue";
import EmbeddedInput from "./components/EmbeddedInput.vue";
import FormDropdown from "./components/FormDropdown.vue";
import LabelRender from "./components/LabelRender.vue";
import RadioButtonGroup from "./components/RadioButtonGroup.vue";
import { useLabel } from "./hooks";

export default defineComponent({
  name: "EmailRegistrationFormElement",
  components: {
    EmbeddedFormDropdown,
    CommonInput,
    EmbeddedInput,
    PhoneInputWithCountryCodeDropdown,
    FormDropdown,
    CommonCheckbox,
    Markdown,
    RadioButtonGroup,
    LabelRender,
    EmailRegistrationFormElementWrap,
  },
  props: {
    config: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: "",
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:input"],
  setup(props, ctx) {
    const { config } = toRefs(props);

    const commonStyles = storeToRefs(useCommonStylesStore());
    const styles = commonStyles.mainBlockStyles as Ref<BodyStyles>;

    const commonGeneral = useCommonGeneralStore();
    const currentLanguage = computed(() => commonGeneral.currentLanguage);

    const inputValue = ref(props.value);
    watch(
      () => inputValue.value,
      (value) => {
        ctx.emit("update:input", value);
      }
    );
    const fieldId = computed(() => "field-" + props.config.key);

    const formElementConfig = computed(() => ({
      error: props.error,
      name: fieldId.value,
      id: fieldId.value,
      required: config.value.required,
    }));

    const { label } = useLabel(config);

    const onChangeMobilePhone = (value: Maybe<PhoneNumberWithCountryData>) =>
      value?.phoneNumber
        ? ctx.emit("update:input", `${value.code}${value.phoneNumber}`)
        : ctx.emit("update:input", "");

    const onChangeCheckbox = (value: boolean) =>
      ctx.emit("update:input", value);

    return {
      label,
      styles,
      formElementConfig,
      inputValue,
      currentLanguage,
      onChangeMobilePhone,
      onChangeCheckbox,
      fieldId,
    };
  },
});
</script>
