import { createClient } from "@cna/api/editor";
import { asyncMemoize } from "@cna/common/utils";
import type { AxiosInstance } from "axios";
import { catcher } from "./helpers";

const setupAxios = (a: AxiosInstance) => {
  a.interceptors.response.use(
    (res) => res,
    (err) => {
      catcher(err);
      return Promise.reject(err);
    }
  );
};

export const api = createClient(setupAxios);

//@ts-ignore
window.$$api = api;

const _memoFlags = asyncMemoize(api.v1.flags.get, false, 60000);
// adds constant cache key
export const memoFlags = () => _memoFlags("flags");
