<script lang="ts">
import TableColumn from "@/components/table/TableColumn.vue";
import { type TableColumnConfig } from "@/components/table/types";
import { computed, defineComponent, h, ref, type PropType } from "vue";

export default defineComponent({
  name: "TableRow",
  components: { TableColumn },
  props: {
    row: {
      type: Object,
      required: true,
    },
    rowKey: {
      type: String,
      required: true,
    },
    // array of keys by which need to access value from row object
    columns: {
      type: Array as PropType<Array<TableColumnConfig>>,
      required: true,
    },
  },
  setup(props) {
    const expanded = ref(false);

    const getDetailsColumn = () =>
      props.columns.find((c) => c.type === "Details");

    const hasRowDetails = computed(() => {
      const details = getDetailsColumn();

      if (!details || details.type !== "Details") {
        return false;
      }

      return details.show(props.row);
    });

    const toggleExpanded = () => {
      expanded.value = !expanded.value;
    };

    return () => {
      const details = getDetailsColumn();

      const rowClass = "table-row flex-row-center-space-between";

      return [
        h(
          "div",
          { key: props.row[props.rowKey], class: rowClass },
          props.columns.map((column) =>
            h(TableColumn, {
              key: column.heading,
              row: props.row,
              column,
              hasRowDetails: hasRowDetails.value,
              onToggleDetails: toggleExpanded,
            })
          )
        ),
        expanded.value &&
          hasRowDetails.value &&
          h(
            "div",
            {
              key: props.row[props.rowKey] + "-details",
              class: rowClass,
            },
            details && details.type === "Details" && details.render(props.row)
          ),
      ];
    };
  },
});
</script>

<style lang="scss" scoped>
.single-list-item {
  & .table-row {
    border: 1px solid $grey3;
    border-radius: 4px;
    background-color: $white;
    margin-bottom: 15px;
  }
}

.double-colored {
  & .table-row:nth-child(odd) {
    background-color: $grey0;
  }
}
</style>
