<template>
  <div class="settings-section__row">
    {{ title }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsEmptyRow",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>
