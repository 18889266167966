import { type ExtractPropTypes } from "vue";
import { modalProps } from "../elements";

export const oneTextFieldEditingModalProps = {
  fieldName: {
    type: String,
    required: true,
  },
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: "Edit text",
  },
  ...modalProps,
} as const;

export type OneTextFieldEditingModalProps = ExtractPropTypes<
  typeof oneTextFieldEditingModalProps
>;
