import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const dbPrivacyPolicyPageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".db_privacy-policy-page",
      pageName: PageNames.DB_PRIVACY_POLICY,
      properties: [
        {
          selector: ".db-back-to-login-button__button",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "back_button_label",
          modifiers: {
            outer: true,
            offsetX: 15,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".db-secondary-page__text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "footer",
          fieldName: "privacy_policy_body",
          modifiers: {
            left: true,
            top: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-page__below-main-block-content-text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "below_main_block_content",
          modifiers: {
            top: true,
            left: true,
            offsetX: 160,
          },
          modalTitle: "Edit text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".db_privacy-policy-page",
      pageName: PageNames.DB_PRIVACY_POLICY,
      properties: [],
    },
  ],
]);
