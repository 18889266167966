import { PAGES_DEPENDS_ON_FOOTER_TYPE } from "@/constants";
import { useProjectsStore } from "@/stores/projects";
import { type Features } from "@cna/common";
import { computed } from "vue";

export const handleFeatures = (features: Features) => {
  handleIsReturningPageFeature(features);
  handleOTPVerificationPage(features);
  handleIsEmailVerificationFeatureEnabled(features);
};

export const handleIsReturningPageFeature = (features: Features) => {
  const projects = useProjectsStore();
  const { returning_page } = features;
  const footerTemplate = computed(
    () => projects.currentProject.templates.footer
  );
  const pages = PAGES_DEPENDS_ON_FOOTER_TYPE.get(footerTemplate.value)!;
  const hasReturningPageInEditor = PAGES_DEPENDS_ON_FOOTER_TYPE.get(
    footerTemplate.value
  )!.includes("returning-page");

  if (returning_page && !hasReturningPageInEditor) {
    pages.splice(1, 0, "returning-page");

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, [...pages]);
  } else if (!returning_page && hasReturningPageInEditor) {
    const index = pages.indexOf("returning-page");
    pages.splice(index, 1);

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, pages);
  }
};

export const handleOTPVerificationPage = (features: Features) => {
  const projects = useProjectsStore();
  const { with_phone_input } = features;
  const footerTemplate = computed(
    () => projects.currentProject.templates.footer
  );
  const pages = PAGES_DEPENDS_ON_FOOTER_TYPE.get(footerTemplate.value)!;
  const hasOTPVerificationPageInEditor = PAGES_DEPENDS_ON_FOOTER_TYPE.get(
    footerTemplate.value
  )!.includes("otp-verification");

  if (with_phone_input && !hasOTPVerificationPageInEditor) {
    const index = pages.includes("returning-page") ? 2 : 1;
    pages.splice(index, 0, "otp-verification");

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, pages);
  } else if (!with_phone_input && hasOTPVerificationPageInEditor) {
    const index = pages.indexOf("otp-verification");
    pages.splice(index, 1);

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, [...pages]);
  }
};

export const handleIsEmailVerificationFeatureEnabled = (features: Features) => {
  const projects = useProjectsStore();
  const { email_verification_enabled } = features;
  const footerTemplate = computed(
    () => projects.currentProject.templates.footer
  );
  const pages = PAGES_DEPENDS_ON_FOOTER_TYPE.get(footerTemplate.value)!;

  const hasVerificationPageInEditor = PAGES_DEPENDS_ON_FOOTER_TYPE.get(
    footerTemplate.value
  )!.includes("email-verification-page");
  if (email_verification_enabled && !hasVerificationPageInEditor) {
    pages.splice(1, 0, "email-verification-page");

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, [...pages]);
  } else if (!email_verification_enabled && hasVerificationPageInEditor) {
    const index = pages.indexOf("email-verification-page");
    pages.splice(index, 1);

    PAGES_DEPENDS_ON_FOOTER_TYPE.set(footerTemplate.value, pages);
  }
};
