import languagesService from "@/services/languages.service";
import { type Language } from "@cna/api/editor";
import { computed, type Ref } from "vue";

export default function useLanguagesOrderHandler(
  sortedLanguages: Ref<Array<Language>>
) {
  const orderedList = computed(() => {
    return sortedLanguages.value.map((el: Language, index: number) => ({
      lang_tag: el.lang_tag,
      order: index,
    }));
  });

  const changeOrder = () => {
    languagesService
      .reorderLanguages(orderedList.value)
      .then(() => languagesService.fetchAllLanguages());
  };

  return {
    orderedList,
    changeOrder,
  };
}
