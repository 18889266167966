import { api } from "@/services/api";
import { AxiosError } from "axios";
import type { RouteRecordRaw } from "vue-router";
import type { Permission } from "@cna/api/editor";
import { views } from "./views";

const beforeEnter: RouteRecordRaw["beforeEnter"] = async (_, from, next) => {
  const auth: Permission[] = await api.v1.me
    .getPermissions()
    .then((res) => res.data.result)
    .catch((e) => {
      if (e instanceof AxiosError) {
        return [];
      }

      return [];
    });

  if (auth.includes("admin.access")) {
    return next();
  }

  return next(from);
};

export const adminRoute: RouteRecordRaw = {
  path: "admin",
  component: () => import("@/admin/layout/index.vue"),
  meta: {
    key: "admin",
  },
  children: [
    {
      path: "",
      redirect(_) {
        return {
          name: "admin-attributes",
        };
      },
    },
    ...views.map((v) => ({ ...v, beforeEnter })),
  ],
};
