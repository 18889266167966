import { computed, nextTick, onBeforeUpdate, ref } from "vue";
import type { LanguageItem, LanguageItems } from "../components";

export function useKeyboardEventsHandler(items?: LanguageItems) {
  const areMenuItemsShown = ref(false);
  const menuItemsRefs = ref();
  const comboboxRef = ref();
  const selectedItemRef = ref();
  const maxIndex = computed(() => menuItemsRefs.value.length - 1);
  const minIndex = 0;
  const currentMenuItem = ref(0);
  const LISTBOX_PAGEUP_AND_PAGEDOWN_STEP = 10;

  const comboboxKeyboardHandler = (event: KeyboardEvent) => {
    const allowedKeys =
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "Home" ||
      event.key === "End";

    if (!allowedKeys) {
      return;
    }

    event.preventDefault();
    if (!areMenuItemsShown.value && allowedKeys) {
      areMenuItemsShown.value = true;
      handleComboboxEventsIfMenuClosed(event.key);
    }
    if (areMenuItemsShown.value && allowedKeys) {
      handleComboboxEventsIfMenuOpen(event.key);
    }
  };

  const handleComboboxEventsIfMenuClosed = (keyAction: string) => {
    nextTick(() => {
      switch (keyAction) {
        case "Home":
          focusListItem.first();
          break;
        case "End":
          focusListItem.last();
          break;
      }
    });
  };

  const handleComboboxEventsIfMenuOpen = (keyAction: string) => {
    switch (keyAction) {
      case "Home":
        focusListItem.first();
        break;
      case "End":
        focusListItem.last();
        break;
      case "ArrowUp":
        focusListItem.last();
        break;
      case "ArrowDown":
        focusListItem.first();
        break;
    }
  };

  const focusListItem = {
    first() {
      currentMenuItem.value = 0;
      menuItemsRefs.value[currentMenuItem.value].focus();
    },
    last() {
      currentMenuItem.value = maxIndex.value;
      menuItemsRefs.value[maxIndex.value].focus();
    },
    exact(index: number) {
      currentMenuItem.value = index;
      menuItemsRefs.value[currentMenuItem.value].focus();
    },
    pageUp() {
      if (currentMenuItem.value >= LISTBOX_PAGEUP_AND_PAGEDOWN_STEP) {
        currentMenuItem.value =
          currentMenuItem.value - LISTBOX_PAGEUP_AND_PAGEDOWN_STEP;
        menuItemsRefs.value[currentMenuItem.value].focus();
      } else {
        this.first();
      }
    },
    pageDown() {
      if (
        maxIndex.value >=
        currentMenuItem.value + LISTBOX_PAGEUP_AND_PAGEDOWN_STEP
      ) {
        currentMenuItem.value =
          currentMenuItem.value + LISTBOX_PAGEUP_AND_PAGEDOWN_STEP;
        menuItemsRefs.value[currentMenuItem.value].focus();
      } else {
        this.last();
      }
    },
  };

  const listBoxKeyboardHandler = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Home":
        focusListItem.first();
        break;
      case "End":
        focusListItem.last();
        break;
      case "PageUp":
        focusListItem.pageUp();
        break;
      case "PageDown":
        focusListItem.pageDown();
        break;
    }
  };

  const movePrev = (event: any) => {
    event.preventDefault();

    if (currentMenuItem.value > minIndex) {
      event.target.previousElementSibling?.focus();
      currentMenuItem.value--;
    }
  };

  const moveNext = (event: any) => {
    event.preventDefault();

    if (currentMenuItem.value < maxIndex.value) {
      event.target.nextElementSibling.focus();
      currentMenuItem.value++;
    }
  };

  const closeMenu = () => {
    if (areMenuItemsShown.value) {
      areMenuItemsShown.value = false;
      currentMenuItem.value = 0;
      comboboxRef.value?.focus();
    }
  };

  const openMenu = () => {
    if (!areMenuItemsShown.value) {
      areMenuItemsShown.value = true;
      currentMenuItem.value = 0;
    }
  };

  const toggle = (event: KeyboardEvent | MouseEvent) => {
    event.preventDefault();
    areMenuItemsShown.value = !areMenuItemsShown.value;
  };

  const searchValue = ref("");

  const searchByCharacterHandler = (event: KeyboardEvent) => {
    if (event.key.length === 1 && /[a-zA-Z]/.exec(event.key)) {
      searchValue.value = searchValue.value + event.key;
      areMenuItemsShown.value = true;
    }
  };

  const filterOptions = (items: LanguageItems, value: string) =>
    items.filter(
      (item: LanguageItem) =>
        item.value.toLowerCase().indexOf(value.toLowerCase()) === 0
    );

  const focusItemBySearch = () => {
    let itemIndex = -1;

    if (!searchValue.value) {
      return;
    }
    const orderedOptions = [
      ...items!.slice(currentMenuItem.value + 1),
      ...items!.slice(0, currentMenuItem.value),
    ];
    const firstMatch = filterOptions(orderedOptions, searchValue.value)[0];
    const allSameLetter = (array: string[]) =>
      array.every((letter: string) => letter === array[0]);

    if (firstMatch) {
      itemIndex = items!.indexOf(firstMatch);
    }

    if (allSameLetter(searchValue.value.split(""))) {
      const matches = filterOptions(
        orderedOptions,
        searchValue.value.split("")[0]
      );
      itemIndex = items!.indexOf(matches[0]);
    }

    if (itemIndex > -1) {
      focusListItem.exact(itemIndex);
    }

    searchValue.value = "";
  };

  onBeforeUpdate(() => {
    menuItemsRefs.value = [];
  });

  return {
    areMenuItemsShown,
    menuItemsRefs,
    selectedItemRef,
    comboboxKeyboardHandler,
    movePrev,
    moveNext,
    closeMenu,
    searchByCharacterHandler,
    searchValue,
    toggle,
    currentMenuItem,
    comboboxRef,
    openMenu,
    listBoxKeyboardHandler,
    focusItemBySearch,
  };
}
