<template>
  <SettingsCreateRow :config="addNewLanguageRowConfig">
    <template #default>
      <div class="languages-section__select-new-language flex-row-center">
        <div class="languages-section__column">
          <span class="languages-section__language-title"> New language </span>
        </div>
        <EditorDropdown
          class="languages-section__language-dropdown"
          :items="availableForSelectionLanguageTags"
          :on-change="onSelectLanguage"
          :current-option-key="availableForSelectionLanguageTags[0].key"
        />
        <ButtonsGroup :buttons-params="buttonsParams" />
      </div>
    </template>
  </SettingsCreateRow>
</template>

<script lang="ts">
import ButtonsGroup from "@/components/button/ButtonsGroup.vue";
import { type ButtonProps } from "@/components/button/types";
import useAvailableFoSelectionLanguageTags from "@/routes/settings/languageSection/useAvailableFoSelectionLanguageTags";
import SettingsCreateRow from "@/routes/settings/sectionElements/SettingsCreateRow.vue";
import languagesService from "@/services/languages.service";
import { useLanguagesStore } from "@/stores/languages";
import { computed, defineComponent, ref } from "vue";
import EditorDropdown from "../../../components/dropdown/EditorDropdown.vue";

export default defineComponent({
  name: "AddNewLanguageRow",
  components: {
    EditorDropdown,
    ButtonsGroup,
    SettingsCreateRow,
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const showNewLanguageRow = ref(false);
    const languages = useLanguagesStore();
    const allLanguageTags = computed(() => languages.allLanguageTags);
    const selectedLanguages = computed(() => languages.sortedLanguages);
    const newLanguageTag = ref();
    const { availableForSelectionLanguageTags } =
      useAvailableFoSelectionLanguageTags(selectedLanguages);

    const buttonsParams: ButtonProps[] = [
      {
        title: "Cancel",
        onClick: () => (showNewLanguageRow.value = false),
      },
      {
        title: "Add",
        onClick: () => {
          newLanguageTag.value
            ? emit("update:option", newLanguageTag.value)
            : emit(
                "update:option",
                availableForSelectionLanguageTags.value[0].key
              );
          showNewLanguageRow.value = false;
        },
      },
    ];

    const rowsQuantity = computed(() => selectedLanguages.value.length);
    const addNewLanguageRowConfig = computed(() => ({
      onClick: () => (showNewLanguageRow.value = true),
      title: "+ Add Language",
      rowsQuantity: rowsQuantity.value,
      isEdited: showNewLanguageRow.value,
    }));

    const onSelectLanguage = (value: string) => {
      newLanguageTag.value = value;
      languagesService.fetchAllLanguages();
    };

    return {
      availableForSelectionLanguageTags,
      selectedLanguages,
      onSelectLanguage,
      showNewLanguageRow,
      buttonsParams,
      allLanguageTags,
      addNewLanguageRowConfig,
    };
  },
});
</script>
