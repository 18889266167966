<template>
  <button
    class="db-primary-button"
    :disabled="disabled"
    :class="{ disabled }"
    @click="config.onClick"
  >
    <span>
      {{ config.title }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { type ButtonProps } from "./types";

export default defineComponent({
  name: "DBButton",
  props: {
    config: {
      type: Object as PropType<ButtonProps>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.db-primary-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none solid rgb(103, 108, 119);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  cursor: pointer;
  border-width: var(--connect_button_props_border_width);
  border-color: var(--connect_button_props_border_color);
  border-style: solid;
  // Needs to convert connect_button_props_corners_radius variable into string with px suffix
  border-radius: calc(var(--connect_button_props_corners_radius) * 1px);
  background-color: var(--connect_button_props_enabled_background_color);
  color: var(--connect_button_props_enabled_text_color);
  font-family: inherit;

  &:hover {
    background-color: var(--connect_button_props_hover_background_color);
  }
}

.disabled {
  color: var(--connect_button_props_disabled_text_color);
  background-color: var(--connect_button_props_disabled_background_color);
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    background-color: var(--connect_button_props_disabled_background_color);
  }
}
</style>
