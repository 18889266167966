<template>
  <EditorNavigation />
  <GeneralPage :key="pageKey" />
</template>

<script lang="ts">
import GeneralPage from "@/pages/GeneralPage.vue";
import { defineComponent } from "vue";
import EditorNavigation from "./editorNavigation/EditorNavigation.vue";

export default defineComponent({
  name: "Editor",
  props: {
    pageKey: String,
  },
  components: { GeneralPage, EditorNavigation },
});
</script>
