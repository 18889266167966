const asterisk = "<span class='required-field'>*</span>";
export const addRequiredFieldToHTML = (html: string) => {
  const el = document.createElement("body");
  el.innerHTML = html;

  const numberOfChildren = el.children.length;

  if (numberOfChildren === 0 && html !== "") {
    return `${html} ${asterisk}`;
  }

  const lastChild = el.children[numberOfChildren - 1];
  lastChild.innerHTML = `${lastChild.innerHTML} ${asterisk}`;

  return el.innerHTML;
};
