import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type GetTextsLangResult = Result<Record<string, Record<string, string>>>;

export type GetFieldsResult = Result<{
  field_key: string;
  values: Record<string, string>;
}>;

export type PostFieldBody = {
  field_key: string;
  values: Record<string, string>;
};
export type PostFieldResult = Result<Record<string, number>>;

export const texts = (path: PathBuilder) => {
  const getLang = (langTag: string, config?: AxiosRequestConfig) =>
    client.get<GetTextsLangResult>(path.slash(langTag).value, config);

  const getField = (
    templateType: string,
    fieldName: string,
    config?: AxiosRequestConfig
  ) =>
    client.get<GetFieldsResult>(
      path.slash(templateType).slash("fields").slash(fieldName).value,
      config
    );

  const postField = (
    templateType: string,
    body: PostFieldBody,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostFieldResult>(
      path.slash(templateType).slash("fields").value,
      body,
      config
    );

  return {
    getLang,
    getField,
    postField,
  };
};
