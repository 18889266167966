const normaliseRoute = (route: string | number) =>
  String(route).startsWith("/") ? String(route) : `/${route}`;

export const pathBuilder = (baseRoute: string) => ({
  slash: (route: string | number) =>
    pathBuilder(baseRoute + normaliseRoute(route)),
  value: baseRoute,
});

export type PathBuilder = ReturnType<typeof pathBuilder>;
