<template>
  <div v-click-outside="closeMenu" class="language-dropdown-with-flags">
    <button
      qa-id="dropdown-btn"
      class="language-dropdown-with-flags__current-item-btn"
      @click="toggle"
      ref="comboboxRef"
      @keydown.exact="comboboxKeyboardHandler"
      v-debounce:200="focusItemBySearch"
      @keyup="searchByCharacterHandler"
      @keydown.alt.down="openMenu"
      @keydown.alt.up="toggle"
      @keydown.enter="toggle"
      @keydown.space="toggle"
      @keydown.esc="closeMenu"
      @keydown.tab="closeMenu"
      :aria-controls="listboxId"
      :aria-expanded="areMenuItemsShown"
      :aria-activedescendant="
        areMenuItemsShown ? dropdownOptions[currentMenuItem].value : undefined
      "
      aria-haspopup="listbox"
      role="combobox"
      :aria-label="languageSwitcherLabel"
    >
      <img
        v-if="getFlagIcon(currentLanguage.key)"
        class="language-dropdown-with-flags__lang-flag"
        :src="getFlagIcon(currentLanguage.key)"
        alt=""
      />
      <span>{{ currentLanguage.value }}</span>
      <img
        class="language-dropdown-with-flags__arrow"
        :src="dropdownIcon"
        role="presentation"
        alt=""
      />
    </button>

    <label :id="`${listboxId}-label`" class="sr-only">{{ listBoxLabel }}</label>

    <ul
      qa-id="dropdown-list"
      :id="listboxId"
      :aria-labelledby="`${listboxId}-label`"
      role="listbox"
      tabindex="-1"
      v-show="areMenuItemsShown"
      class="language-dropdown-with-flags__list"
    >
      <li
        v-for="(item, i) in dropdownOptions"
        tabindex="0"
        :qa-id="`dropdown-list-item-${i}`"
        role="option"
        :key="item.key"
        :aria-selected="item.key === currentLanguage.key"
        ref="menuItemsRefs"
        class="language-dropdown-with-flags__item"
        @keydown.tab="selectItemAndFocusNextElement(item.key)"
        @keydown.exact.prevent="listBoxKeyboardHandler"
        @keydown.space.prevent="onSelectItem(item.key)"
        @keydown.down="moveNext"
        @keydown.up.exact="movePrev"
        @keydown.alt.up="onSelectItem(item.key)"
        @keydown.esc="closeMenu"
        @click="onSelectItem(item.key)"
        @keyup.enter.exact="onSelectItem(item.key)"
        v-debounce:200="focusItemBySearch"
        @keyup.prevent="searchByCharacterHandler"
      >
        <span>
          <img
            v-if="getFlagIcon(item.key)"
            class="language-dropdown-with-flags__lang-flag"
            :src="getFlagIcon(item.key)"
            alt=""
            role="presentation"
          />
          <span>{{ item.value }}</span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import vClickOutside from "click-outside-vue3";
import { computed, defineComponent } from "vue";
import { vue3Debounce } from "vue-debounce";
import arrowDown from "../../../assets/images/DB/languageDropdown/chevron-down.svg";
import arrowUp from "../../../assets/images/DB/languageDropdown/chevron-up.svg";
import { useKeyboardEventsHandler } from "../../../hooks/useKeyboardEventsHandler";
import { getFlagIcon } from "../../../utils/stylesUtils";
import { props } from "./props";

export default defineComponent({
  name: "CustomLanguageDropdown",
  directives: {
    clickOutside: vClickOutside.directive,
    debounce: vue3Debounce({ lock: true }),
  },
  props,
  setup(props) {
    const {
      comboboxKeyboardHandler,
      movePrev,
      moveNext,
      closeMenu,
      areMenuItemsShown,
      menuItemsRefs,
      currentMenuItem,
      toggle,
      openMenu,
      comboboxRef,
      listBoxKeyboardHandler,
      focusItemBySearch,
      searchByCharacterHandler,
    } = useKeyboardEventsHandler(props.dropdownOptions);

    const onSelectItem = (item: string) => {
      props.onSelectLanguage(item);
      closeMenu();
    };

    const selectItemAndFocusNextElement = (item: string) => {
      props.onSelectLanguage(item);
      closeMenu();
      comboboxRef.value.nextElementByTabIndex.focus();
    };

    const dropdownIcon = computed(() => {
      return areMenuItemsShown.value ? arrowUp : arrowDown;
    });

    const languageSwitcherLabel = computed(
      () =>
        ({
          en: "Language Switcher",
          de: "Sprache Wechsler",
          fr: "Sélecteur de Langue",
          nl: "Taalwisselaar",
        }[props.currentLanguage?.key])
    );

    const listBoxLabel = computed(
      () =>
        ({
          en: "Languages",
          de: "Sprachen",
          fr: "Langages",
          nl: "Talen",
        }[props.currentLanguage?.key])
    );

    return {
      areMenuItemsShown,
      currentMenuItem,
      toggle,
      comboboxKeyboardHandler,
      menuItemsRefs,
      movePrev,
      moveNext,
      closeMenu,
      onSelectItem,
      dropdownIcon,
      getFlagIcon,
      openMenu,
      comboboxRef,
      listBoxKeyboardHandler,
      focusItemBySearch,
      searchByCharacterHandler,
      languageSwitcherLabel,
      selectItemAndFocusNextElement,
      listBoxLabel,
    };
  },
});
</script>

<style lang="scss" scoped>
.language-dropdown-with-flags {
  position: relative;
  width: 164px;
  z-index: 2;
}

.language-dropdown-with-flags__current-item-btn {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 10px;
  margin-left: -2px;
  border: 2px solid transparent;
  width: 100%;
  border-radius: 5px;
  line-height: 24px;
  background-color: $white;
  height: 54px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  color: inherit;

  &:hover,
  &:focus:not(:focus-visible) {
    background-color: $mercury;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px #131821, 0px 0px 0px 4px #2c95c4;

    @media (forced-colors: active) {
      outline: 4px solid #2c95c4;
    }
  }
}

.language-dropdown-with-flags__list {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 100%;
  margin: 0.125rem 0 0 -2px;
  width: 100%;
  border-radius: 4px;
  color: #212529;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0;
}

.language-dropdown-with-flags__item {
  list-style-type: none;

  span {
    display: flex;
    align-items: center;
    margin: 4px;
    padding: 4px;
    border-radius: 4px;
    color: $bright-gray;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    transition: all 0.3s ease;
    height: 42px;
    cursor: pointer;
  }
  &:active {
    span {
      background-color: $mercury;
      border: 2px solid $bombay;
    }
  }
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    span {
      background-color: $mercury;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d7dce1;
  }
}

.language-dropdown-with-flags__lang-flag {
  height: 20px;
  width: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
  border-radius: 3px;
}

.language-dropdown-with-flags__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.language-dropdown-with-flags__arrow {
  display: block;
  position: absolute;
  top: 14px;
  right: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
}

.chevron-up::after {
  background-image: url("../../../assets/images/DB/languageDropdown/chevron-up.svg");
}

.chevron-down::after {
  background-image: url("../../../assets/images/DB/languageDropdown/chevron-down.svg");
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (min-width: $db-tablet-lg) {
  .language-dropdown-with-flags {
    width: 190px;
  }

  .language-dropdown-with-flags__lang-flag {
    height: 32px;
    width: 38px;
  }

  .language-dropdown-with-flags__item,
  .language-dropdown-with-flags__current-item-btn {
    font-size: 16px;
  }
}
</style>
