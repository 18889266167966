import { computed } from "vue";
import { useCommonEmailRegistrationStore } from "../../../stores/emailRegistration";
import { isNullOrUndefinedOrEmptyWhenTrimmed } from "../../../utils/general";

export function useEmailRegistrationHandler() {
  const commonEmailRegistration = useCommonEmailRegistrationStore();

  const emailRegistrationFormData = computed(
    () => commonEmailRegistration.emailRegistrationFormData
  );
  const allProjectFields = computed(
    () => commonEmailRegistration.allProjectFields
  );
  const allProjectFieldsKeys = allProjectFields.value?.reduce(
    (acc: Array<string>, currentField) => {
      acc.push(currentField.key);
      return acc;
    },
    []
  );
  const allRequiredFieldsKeys = allProjectFields.value?.reduce(
    (acc: Array<string>, currentField) => {
      if (currentField.required) {
        acc.push(currentField.key);
      }
      return acc;
    },
    []
  );

  const isButtonDisabled = computed(() => {
    let isExistErrors = false;
    // check if required field is not empty
    allRequiredFieldsKeys?.forEach((key: string) => {
      const infoVal = emailRegistrationFormData.value.info[key];
      const consentVal = emailRegistrationFormData.value.consents[key];

      const infoValEmpty = isNullOrUndefinedOrEmptyWhenTrimmed(infoVal);
      const consentValEmpty = !consentVal;

      if (infoValEmpty && consentValEmpty) {
        isExistErrors = true;
      }
    });

    return isExistErrors;
  });

  const setEmailRegistrationFormData = () => {
    allProjectFields.value?.forEach((item) => {
      if (item.kind === "checkbox") {
        commonEmailRegistration.emailRegistrationFormData.consents[item.key] =
          false;
      } else if (item.kind !== "other_text") {
        commonEmailRegistration.emailRegistrationFormData.info[item.key] = "";
      }
    });
  };
  setEmailRegistrationFormData();

  return {
    allProjectFieldsKeys,
    isButtonDisabled,
    allProjectFields,
  };
}
