<template>
  <div class="flex-row-center">
    <EditorCheckbox :title="title" :value="value" @update:option="onClick" />
  </div>
</template>

<script lang="ts">
import EditorCheckbox from "@/components/checkbox/EditorCheckbox.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "CheckboxColumn",
  components: { EditorCheckbox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<(...args: any[]) => any>,
      required: true,
    },
  },
});
</script>
