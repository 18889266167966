import { type Pages } from "@/pages/types";
import type { ListProjectData, Project } from "@cna/api/editor";
import { INITIAL_FEATURES, INITIAL_TEMPLATES_KEYS } from "@cna/common";

import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

const INITIAL_PROJECT: Project = {
  id: 0,
  name: "",
  templates: { ...INITIAL_TEMPLATES_KEYS },
  portal_env: "local",
  features: {
    ...INITIAL_FEATURES,
  },
  api_type: "",
  domains: {},
  icomera_account_id: 0,
  icomera_customer_id: 0,
  portal_class: "",
};

export const useProjectsStore = defineStore("projects", () => {
  const projects: Ref<ListProjectData[]> = ref([]);
  const currentProjectId: Ref<number | null> = ref(null);
  const isProjectDataLoaded = ref(false);
  const areProjectsInited = ref(false);
  const currentScreenSize = ref("desktop");
  const currentProject = ref(INITIAL_PROJECT);
  const activePageName = ref("");
  const projectPages: Ref<Array<Pages>> = ref([]);

  const isDBPortalType = computed(() =>
    ["connect_with_checkbox_logo_leftAligned", "db_fv"].includes(
      currentProject.value.templates.body
    )
  );

  const isTwoColumn = computed(
    () =>
      currentProject.value.templates.body === "email_registration_two_column"
  );

  return {
    projects,
    currentProjectId,
    isProjectDataLoaded,
    areProjectsInited,
    currentScreenSize,
    currentProject,
    activePageName,
    projectPages,
    isDBPortalType,
    isTwoColumn,
  };
});
