import { type App } from "vue";
import { type ToastifyParams } from "@/plugins/types";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export const TOASTIFY_TEXTS = {
  success: "Success",
  copyUrl: "The URL was copied",
};

class ToastifyPlugin {
  show(params: ToastifyParams) {
    createToast(params.messageText, {
      type: params.toastifyType,
      position: "bottom-right",
      hideProgressBar: true,
      transition: "slide",
    });
  }
}

export default {
  install: (app: App): void => {
    app.config.globalProperties.$toastify = new ToastifyPlugin();
  },
};

export const $toastify = new ToastifyPlugin();
