<template>
  <div class="secondary-content">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.secondary-content {
  padding: 20px 0px;
  @media (min-width: calc($icomera-tablet + 1px)) {
    padding: 30px 0px;
  }
}
</style>
