import type { EmailRegistrationField } from "@cna/api/editor";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";
import { getLocalstorageItem, setLocalstorageItem } from "../utils/general";
import { useCommonGeneralStore } from "./general";
import {
  DEFAULT_EMAIL_REGISTRATION_FIELD,
  DEFAULT_EMAIL_REGISTRATION_FORM_DATA,
} from "./INITIAL_VALUES";
import type { CustomError } from "./types";

export const useCommonEmailRegistrationStore = defineStore(
  "commonEmailRegistration",
  () => {
    const allProjectFields: Ref<null | EmailRegistrationField[]> = ref([]);
    const emailRegistrationFormData = ref(DEFAULT_EMAIL_REGISTRATION_FORM_DATA);
    const email = ref(getLocalstorageItem("email") || "");
    const error: Ref<CustomError | string> = ref({});
    const isSendingForm = ref(false);

    const commonGeneral = useCommonGeneralStore();

    const emailInputProps = computed(() => {
      const index = allProjectFields.value?.findIndex(
        (element) => element.key === "email"
      );
      return index !== undefined && index >= 0
        ? allProjectFields.value![index]
        : DEFAULT_EMAIL_REGISTRATION_FIELD;
    });
    const isEmailregPortal = computed(() =>
      [
        "connect_with_email_registration",
        "email_registration_two_column",
      ].includes(commonGeneral.templates.body)
    );

    const rememberEmail = (email: string) => {
      setLocalstorageItem("email", email);
    };

    return {
      allProjectFields,
      emailRegistrationFormData,
      email,
      error,
      isSendingForm,
      emailInputProps,
      isEmailregPortal,
      rememberEmail,
    };
  }
);
