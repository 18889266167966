<template>
  <div
    v-if="isWithBelowMainBlockContent"
    class="db-page__below-main-block-content"
  >
    <div class="db-page__below-main-block-content-text">
      <Markdown :data="markdownData" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useCommonGeneralStore } from "../../../stores/general";
import Markdown from "../../markdown/Markdown.vue";

export default defineComponent({
  name: "DBBelowMainBlockContent",
  components: {
    Markdown,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();

    const isWithBelowMainBlockContent = computed(
      () => commonGeneral.isWithBelowMainBlockContent
    );
    const markdownData = computed(
      () =>
        commonGeneral.texts[commonGeneral.currentLanguage].body
          .below_main_block_content
    );

    return { isWithBelowMainBlockContent, markdownData };
  },
});
</script>
