import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type VehicleEvent = {
  content_version: number;
  gvc_version: string;
  message: string;
  status: string;
  system_id: string;
  timestamp: string;
  published_at?: string;
  published_by?: string;
};

export type VehicleEventsResult = Result<{
  events_list: VehicleEvent[];
}>;

export const vehicleEvents = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<VehicleEventsResult>(path.value, config);

  return {
    get,
  };
};
