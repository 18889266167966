<template>
  <div ref="generalPage" class="general-page fr-view">
    <component
      :is="getPageComponentName(page)"
      v-for="page in pages"
      :key="page"
    />
  </div>
</template>

<script lang="ts">
import { PAGES_CONFIGS } from "@/constants";
import { editorNavigationHeight } from "@/routes/editorNavigation/EditorNavigation.vue";
import { useProjectsStore } from "@/stores/projects";
import { getProjectPagesDependsOnFooterAndBodyTypes } from "@/utils";
import { computed, defineComponent, ref } from "vue";
import ContactUsPage from "./ContactUsPage.vue";
import DBContactUsPage from "./DB/DBContactUsPage.vue";
import DBFAQPage from "./DB/DBFAQPage.vue";
import DBNowConnectedPage from "./DB/DBNowConnectedPage.vue";
import DBPrivacyPolicyPage from "./DB/DBPrivacyPolicyPage.vue";
import DBTermsAndConditionsPage from "./DB/DBTermsAndConditionsPage.vue";
import DBWelcomePage from "./DB/DBWelcomePage.vue";
import EmailRegistrationPage from "./EmailRegistrationPage.vue";
import EmailVerificationPage from "./EmailVerificationPage.vue";
import NowConnectedPage from "./NowConnectedPage.vue";
import OTPVerificationPage from "./OTPVerificationPage.vue";
import PrivacyPolicyPage from "./PrivacyPolicyPage.vue";
import ReturningPage from "./ReturningPage.vue";
import TermsAndConditionsPage from "./TermsAndConditionsPage.vue";
import { type Pages } from "./types";
import usePagesTabSwitcher from "./usePagesTabSwitcher";
import WelcomePage from "./WelcomePage.vue";

export default defineComponent({
  name: "GeneralPage",
  components: {
    ContactUsPage,
    TermsAndConditionsPage,
    WelcomePage,
    NowConnectedPage,
    DBWelcomePage,
    PrivacyPolicyPage,
    DBPrivacyPolicyPage,
    DBTermsAndConditionsPage,
    DBNowConnectedPage,
    DBContactUsPage,
    DBFAQPage,
    ReturningPage,
    OTPVerificationPage,
    EmailRegistrationPage,
    EmailVerificationPage,
  },
  setup() {
    const projects = useProjectsStore();
    const pages = computed(() => projects.projectPages);
    const generalPage = ref();
    const getPageComponentName = (page: Pages) => {
      const { componentName } = PAGES_CONFIGS.get(page)!;
      return componentName;
    };

    usePagesTabSwitcher();
    getProjectPagesDependsOnFooterAndBodyTypes();

    return {
      editorNavigationHeight,
      pages,
      getPageComponentName,
      generalPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.fixed-header-margin {
  margin-top: 60px;
}
.general-page {
  margin-top: v-bind(editorNavigationHeight);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>
