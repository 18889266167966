<template>
  <div
    role="progressbar"
    :aria-valuenow="ratioDone.toFixed(2)"
    aria-valuemin="0"
    aria-valuemax="1"
    :aria-valuetext="ariaValueText ?? undefined"
    :style="{ height, backgroundColor: bgColor, borderRadius: radius }"
    class="progress-bar"
  >
    <div
      :style="{
        height: '100%',
        width: (ratioDone * 100).toFixed(0) + '%',
        backgroundColor: color,
      }"
      class="progress-bar-inner"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ratioDone: {
      type: Number,
      default: 0,
    },
    bgColor: String,
    color: String,
    height: {
      type: String,
    },
    ariaValueText: {
      type: String,
    },
    radius: Number,
  },
});
</script>

<style scoped>
.progress-bar-inner {
  transition: all 120ms ease-in-out;
}
.progress-bar {
  overflow: hidden;
}
</style>
