<template>
  <div id="now-connected" class="page now-connected-page">
    <HeaderLayout />
    <NowConnectedPageBodyTwoColumn v-if="isTwoColumn" />
    <NowConnectedPageBody v-else />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { nowConnectedPageConfig } from "@/mixins/editIcon/configs/nowConnectedPage";
import { useProjectsStore } from "@/stores/projects";
import {
  FooterLayout,
  HeaderLayout,
  NowConnectedPageBody,
  NowConnectedPageBodyTwoColumn,
  provideShowTrafficUsageMessage,
} from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "NowConnectedPage",
  components: {
    FooterLayout,
    HeaderLayout,
    NowConnectedPageBody,
    NowConnectedPageBodyTwoColumn,
  },
  mixins: [editIconMixin(nowConnectedPageConfig)],
  setup() {
    const projects = useProjectsStore();
    const { isTwoColumn } = storeToRefs(projects);

    provideShowTrafficUsageMessage(ref(true));

    return {
      isTwoColumn,
    };
  },
});
</script>
