<template>
  <div :class="setCreateRowClass">
    <slot v-if="config.isEdited" />
    <component
      :is="buttonType"
      v-else
      :on-click="config.onClick"
      :title="config.title"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import TextButton from "@/components/button/TextButton.vue";
import EditorButton from "@/components/button/EditorButton.vue";
import { type SettingsCreateRowConfig } from "@/routes/settings/sectionElements/types";

export default defineComponent({
  name: "SettingsCreateRow",
  components: {
    TextButton,
    EditorButton,
  },
  props: {
    config: {
      type: Object as PropType<SettingsCreateRowConfig>,
      required: true,
    },
    buttonType: {
      type: String,
      required: false,
      default: "TextButton",
    },
  },
  setup(props) {
    const setCreateRowClass = computed(() => {
      return props.config.rowsQuantity % 2 != 0
        ? "settings-section__creating-row"
        : "settings-section__creating-row settings-section__creating-row--white";
    });

    return {
      setCreateRowClass,
    };
  },
});
</script>
