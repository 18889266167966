<template>
  <div class="page__main-block">
    <div class="page__text-block">
      <div class="page__text">
        <div class="page__markdown">
          <Markdown :data="bodyTexts.now_connected_text" />
          <a v-show="isWithVenueRedirectButton" :href="buttonLink">
            <CommonButton
              :config="{ title: bodyTexts.venue_redirect_button_label }"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useVenueRedirectHelper } from "../../../hooks/useVenueRedirectHelper";
import { useCommonGeneralStore } from "../../../stores/general";
import CommonButton from "../../button/CommonButton.vue";
import Markdown from "../../markdown/Markdown.vue";

export default defineComponent({
  name: "NowConnectedPageContent",
  components: {
    Markdown,
    CommonButton,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();

    const bodyTexts = computed(() => commonGeneral.bodyTexts);
    const isWithVenueRedirectButton = computed(
      () => commonGeneral.isWithVenueRedirectButton
    );
    const { buttonLink } = useVenueRedirectHelper();

    return {
      bodyTexts,
      isWithVenueRedirectButton,
      buttonLink,
    };
  },
});
</script>
