<template>
  <n-config-provider abstract :theme-overrides="THEME_OVERRIDES">
    <router-view />
    <global-modal />
  </n-config-provider>
</template>

<script lang="ts">
import { THEME_OVERRIDES } from "@/constants";
import { NConfigProvider } from "naive-ui";
import { defineComponent } from "vue";
import { GlobalModal } from "./modals";

export default defineComponent({
  name: "App",
  components: {
    NConfigProvider,
    GlobalModal,
  },
  setup() {
    return {
      THEME_OVERRIDES,
    };
  },
});
</script>

<style lang="scss">
#app {
  height: 100%;
}

html,
body {
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
