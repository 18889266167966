<template>
  <div id="contact-us" class="page contact-us-page">
    <HeaderLayout />
    <SecondaryPageBody :markdown-data="footerTexts.contact_us_body" />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { contactUsPageConfig } from "@/mixins/editIcon/configs/contactUsPage";
import {
  FooterLayout,
  HeaderLayout,
  SecondaryPageBody,
  useCommonGeneralStore,
} from "@cna/common";
import { computed, defineComponent } from "vue";
import { editIconMixin } from "../mixins/editIcon/EditIconMixin";

export default defineComponent({
  name: "ContactUsPage",
  components: { FooterLayout, HeaderLayout, SecondaryPageBody },
  mixins: [editIconMixin(contactUsPageConfig)],
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const footerTexts = computed(() => commonGeneral.footerTexts);

    return {
      footerTexts,
    };
  },
});
</script>
