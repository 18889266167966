<template>
  <component :is="tag" class="email-registration-form-element__label">
    <Markdown class="input-markdown" :data="label" />
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Markdown from "../../markdown/Markdown.vue";

export default defineComponent({
  name: "EmailRegistrationFormElement",
  components: {
    Markdown,
  },
  props: {
    tag: {
      type: String,
      default: "label",
    },
    label: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.email-registration-form-element__label {
  position: relative;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.input-markdown {
  display: inline-block;
  color: var(--form_element_input_style_label_color);
}
</style>
