import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type ImageFileProperties = {
  link?: string;
  props?: any;
};
export type GetImagesResult = Result<
  Record<string, Record<string, ImageFileProperties>>
>;

export type PostImageAttributesInput = {
  props: any;
};
export type PostImageChangeResult = Result<{
  upserted_row_id: number;
}>;

export const images = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetImagesResult>(path.value, config);

  const postAttributes = (
    templateType: string,
    fieldName: string,
    body: PostImageAttributesInput,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostImageChangeResult>(
      path.slash(templateType).slash(fieldName).slash("attributes").value,
      body,
      config
    );

  const postEmpty = (
    templateType: string,
    fieldName: string,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostImageChangeResult>(
      path.slash(templateType).slash(fieldName).slash("empty").value,
      undefined,
      config
    );

  const post = (
    templateType: string,
    fieldName: string,
    image: File,
    config?: AxiosRequestConfig<FormData>
  ) => {
    const formData = new FormData();
    formData.append("file", image);

    return client.postForm<PostImageChangeResult>(
      path.slash(templateType).slash(fieldName).value,
      formData,
      config
    );
  };

  return {
    get,
    postAttributes,
    postEmpty,
    post,
  };
};
