<template>
  <div id="otp-verification" class="page otp-verification-page">
    <HeaderLayout />
    <OTPVerificationPageBody
      :texts="bodyTexts"
      :verify-button-config="verifyButtonConfig"
      :resend-button-config="resendButtonConfig"
      :edit-phone-button-config="editPhoneButtonConfig"
      :input-config="inputConfig"
      :phone-number="DEFAULT_PHONE_NUMBER"
      :verification-code="verificationCode"
      @update:option="onUpdateVerificationCode"
    />
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { editIconMixin } from "@/mixins/editIcon/EditIconMixin";
import { OTPVerificationPageConfig } from "@/mixins/editIcon/configs/OTPverificationPage";
import {
  FooterLayout,
  HeaderLayout,
  OTPVerificationPageBody,
  useCommonGeneralStore,
  type InputConfig,
  type OTPVerificationPageTexts,
} from "@cna/common";
import { computed, defineComponent, ref, type Ref } from "vue";

const DEFAULT_PHONE_NUMBER = {
  code: "+44",
  phoneNumber: "7753456600",
  iso: "",
};

export default defineComponent({
  name: "OTPVerificationPage",
  components: { FooterLayout, HeaderLayout, OTPVerificationPageBody },
  mixins: [editIconMixin(OTPVerificationPageConfig)],
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const bodyTexts = computed(
      () => commonGeneral.bodyTexts as OTPVerificationPageTexts
    );
    const verifyButtonConfig = computed(() => ({
      title: bodyTexts.value.verification_button_label,
      disabled: verificationCode.value === "",
    }));
    const verificationCode = ref("");

    const resendButtonConfig = computed(() => ({
      title: bodyTexts.value.resend_button_label,
      title_if_resent: bodyTexts.value.resend_button_label_if_resent,
    }));

    const editPhoneButtonConfig = computed(() => ({
      title: bodyTexts.value.edit_phone_button_label,
    }));

    const inputConfig: Ref<InputConfig> = computed(() => ({
      error: "",
    }));

    const onUpdateVerificationCode = (value: string) =>
      (verificationCode.value = value);

    return {
      bodyTexts,
      inputConfig,
      verifyButtonConfig,
      resendButtonConfig,
      editPhoneButtonConfig,
      DEFAULT_PHONE_NUMBER,
      verificationCode,
      onUpdateVerificationCode,
    };
  },
});
</script>
