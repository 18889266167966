import { APP_NAME } from "@/constants";
import textsService from "@/services/texts.service";
import { useLanguagesStore } from "@/stores/languages";
import { type Language } from "@cna/api/editor";
import {
  injectedHooks,
  useCommonGeneralStore,
  type TypesOfButtons,
  type TypesOfLinksBlocks,
} from "@cna/common";
import { computed } from "vue";

const useLanguagesHelper = () => {
  const commonGeneral = useCommonGeneralStore();
  const languagesStore = useLanguagesStore();
  const languages = computed(() => languagesStore.sortedLanguages);
  const allLanguageTags = computed(() => languagesStore.allLanguageTags);
  const currentLanguageKey = computed(() => commonGeneral.currentLanguage);
  const currentLanguage = computed(() => ({
    key: commonGeneral.currentLanguage,
    value: allLanguageTags.value[commonGeneral.currentLanguage].longname,
  }));

  const dropdownOptions = computed(() => {
    return languages.value.map((language: Language) => ({
      key: language.lang_tag,
      value: allLanguageTags.value[language.lang_tag].longname,
    }));
  });

  const onSelectLanguage = async (value: string) => {
    commonGeneral.currentLanguage = value;
    await textsService.fetchTexts(value);
  };

  // check if we have this lang for the project
  const isLanguageAllowed =
    currentLanguageKey.value &&
    languagesStore.sortedLanguages
      .map((language) => language.lang_tag)
      .includes(currentLanguageKey.value);
  if (!isLanguageAllowed) {
    onSelectLanguage(languagesStore.primaryLanguage);
  }

  return {
    dropdownOptions,
    onSelectLanguage,
    currentLanguage,
    currentLanguageKey,
  };
};

const TYPES_OF_LINKS_BLOCKS: TypesOfLinksBlocks = {
  headerLogoLink: "div",
  footerLink: "span",
  bodyTermsLink: "span",
};

const TYPES_OF_BUTTONS: TypesOfButtons = {
  connectButtonType: "button",
};

export default function saveGeneralDataToCommonStore() {
  const commonGeneral = useCommonGeneralStore();
  commonGeneral.appName = APP_NAME;
  injectedHooks.useLanguageDropdown = useLanguagesHelper;

  commonGeneral.typesOfLinksBlocks = TYPES_OF_LINKS_BLOCKS;
  commonGeneral.typesOfButtons = TYPES_OF_BUTTONS;
  commonGeneral.isVenueHealthy = true;
}
