<template>
  <p>To access the Portal Editor, you must log in with Iconic first.</p>
  <a href="/services/cna-editor/v1/login">Log in</a>
</template>

<style lang="scss" scoped>
p {
  margin: 0;
}
.box a {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  background: $dark-blue;
  color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: $dark-blue;
  }
  &:visited {
    background: $dark-blue;
  }
}
</style>
