import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import { type PathBuilder } from "../../utils";
import { client } from "./axios";

export type GetFlagsResult = Result<
  Partial<{
    permissions: boolean;
  }>
>;

export const flags = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<GetFlagsResult>(path.value, config);

  return {
    get,
  };
};
