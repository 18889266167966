export const modalProps = {
  closable: {
    type: Boolean,
    default: true,
  },
} as const;

export const modalEmits = {
  close: () => true,
} as const;
