import { adminRoute } from "@/admin/routes";
import CenterLayout from "@/layouts/CenterLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import ProjectsLayout from "@/layouts/ProjectsLayout.vue";
import { WithProjectId } from "@/layouts/WithProjectId";
import { api } from "@/services/api";
import { useProjectsStore } from "@/stores/projects";
import { getLocalstorageItem } from "@cna/common";
import { AxiosError } from "axios";
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from "vue-router";
import Editor from "./Editor.vue";
import Login from "./Login.vue";
import UserActions from "./UserActions.vue";
import VersionsHistory from "./VersionsHistory.vue";
import Home from "./home.vue";
import Settings from "./settings/Settings.vue";

export const metaKeyOrTextEditor = (to: RouteLocationNormalized) =>
  to.meta?.key ?? "text-editor";

const legacyRouteRedirects = [
  "text-editor",
  "theme-editor",
  "settings",
  "versions-history",
].map(
  (key): RouteRecordRaw => ({
    path: `/${key}`,
    meta: {
      key,
    },
    redirect: (to) => {
      const project = useProjectsStore();
      if (!project.currentProjectId) {
        return { path: "/" };
      }

      return {
        path: `/${project.currentProjectId}/${metaKeyOrTextEditor(to)}`,
      };
    },
  })
);

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length;
};

const preserveQuery: RouteRecordRaw["beforeEnter"] = (to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({ ...to, query: from.query });
  }

  return next();
};

const goToProjectIfExists: RouteRecordRaw["beforeEnter"] = (_, __, next) => {
  const projectId = getLocalstorageItem("projectId");
  if (projectId) {
    next({
      path: `/${projectId}/text-editor`,
    });
  }
  return next();
};

const getAuthStatus = () =>
  api.v1.me
    .getAuthenticated()
    .then((r) => r.status)
    .catch((e) => {
      if (e instanceof AxiosError) {
        return e.status;
      }

      return -1;
    });

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: CenterLayout,
    props: {
      title: "You are logged out",
    },
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
    ],
    async beforeEnter(_, __, next) {
      const authStatus = await getAuthStatus();

      if (authStatus === 200) {
        return next("/");
      }

      return next();
    },
  },
  {
    path: "/",
    component: ProjectsLayout,
    async beforeEnter(_, __, next) {
      const authStatus = await getAuthStatus();

      if (authStatus === 200) {
        return next();
      }

      return next("/login");
    },
    children: [
      {
        path: "",
        component: CenterLayout,
        props: {
          title: "Portal Editor",
          maxWidth: "100%",
        },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            beforeEnter: goToProjectIfExists,
          },
        ],
      },
      {
        path: "/:projectId",
        component: WithProjectId,
        props: true,
        children: [
          adminRoute,
          {
            path: "",
            component: MainLayout,
            children: [
              {
                path: "settings",
                component: Settings,
                beforeEnter: preserveQuery,
                meta: {
                  key: "settings",
                },
              },
              {
                path: "theme-editor",
                component: Editor,
                beforeEnter: preserveQuery,
                props: { pageKey: "theme-editor" },
                meta: {
                  key: "theme-editor",
                },
              },
              {
                path: "text-editor",
                component: Editor,
                props: { pageKey: "text-editor" },
                beforeEnter: preserveQuery,
                meta: {
                  key: "text-editor",
                },
              },
              {
                path: "versions-history",
                component: VersionsHistory,
                beforeEnter: preserveQuery,
                meta: {
                  key: "versions-history",
                },
              },
              {
                path: "user-actions",
                component: UserActions,
                beforeEnter: preserveQuery,
                meta: {
                  key: "user-actions",
                },
              },
            ],
          },
          {
            path: "preview",
            component: () => import("./preview/Preview.vue"),
            meta: {
              key: "preview",
            },
          },
        ],
      },
    ],
  },
  ...legacyRouteRedirects,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
