<template>
  <div class="flex-row-center">
    <span class="status" :class="status">
      <span v-if="text">{{ capitalizeFirstLetter(text) }}</span>
      <slot v-else />
    </span>
  </div>
</template>

<script lang="ts">
import { capitalizeFirstLetter } from "@/utils";
import { defineComponent, type PropType } from "vue";
import type { Statuses } from "../types";

export default defineComponent({
  name: "StatusColumn",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    status: {
      type: String as PropType<Statuses>,
      required: false,
      default: "",
    },
  },
  setup() {
    return { capitalizeFirstLetter };
  },
});
</script>

<style lang="scss" scoped>
.status {
  position: relative;
  padding-left: 15px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.success:before {
  background-color: $success;
}

.failure:before {
  background-color: $failure;
}

.enabled:before {
  background-color: $blue;
}
</style>
