<template>
  <div>
    <span class="flex-row-center">
      {{ formattedDateTime }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "DateTimeColumn",
  props: {
    dateTime: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedDateTime = computed(() => {
      const timestamp = Date.parse(props.dateTime);

      if (isNaN(timestamp)) {
        return "";
      }

      const date = new Date(timestamp);

      return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    });

    return { formattedDateTime };
  },
});
</script>

<style lang="scss" scoped>
.icon-clock {
  font-size: 24px;
  margin-right: 11px;
  margin-bottom: 3px;
}
</style>
