import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type Language = {
  lang_tag: string;
  primary: boolean;
  order: number;
};

export type GetLanguagesResult = Result<Language[]>;
export type GetLanguageResult = Result<Language>;
export type PutReorderSequenceEntry = {
  lang_tag: string;
  order: number;
};
export type PutReorderBody = {
  new_sequence: PutReorderSequenceEntry[];
};

export type PutReorderResult = Result<"success">;
export type PostPrimaryLanguageResult = Result<{
  rows_affected: number;
}>;
export type PostLanguageResult = Result<{
  text_values_count: Record<string, number>;
  language_result: Record<string, number>;
}>;
export type DeleteLanguageResult = Result<{
  text_rows_affected: number;
  language_rows_affected: number;
}>;

export const languages = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetLanguagesResult>(path.value, config);

  const getPrimary = (config?: AxiosRequestConfig) =>
    client.get<GetLanguageResult>(path.slash("primary").value, config);

  const putReorder = (body: PutReorderBody, config?: AxiosRequestConfig) =>
    client.put<PutReorderResult>(path.slash("reorder").value, body, config);

  const postPrimaryLanguage = (langTag: string, config?: AxiosRequestConfig) =>
    client.post<PostPrimaryLanguageResult>(
      path.slash(langTag).slash("set_primary").value,
      undefined,
      config
    );

  const getLanguage = (langTag: string, config?: AxiosRequestConfig) =>
    client.get<GetLanguageResult>(path.slash(langTag).value, config);

  const postLanguage = (langTag: string, config?: AxiosRequestConfig) =>
    client.post<PostLanguageResult>(
      path.slash(langTag).value,
      undefined,
      config
    );

  const deleteLanguage = (langTag: string, config?: AxiosRequestConfig) =>
    client.delete<DeleteLanguageResult>(path.slash(langTag).value, config);

  return {
    get,
    getPrimary,
    putReorder,
    postPrimaryLanguage,
    getLanguage,
    postLanguage,
    deleteLanguage,
  };
};
