import type { CountriesData } from "./types";

export const countries: Readonly<CountriesData> = [
  {
    name: "Afghanistan",
    code: "+93",
    iso: "AF",
    native: "افغانستان",
  },
  {
    name: "Aland Islands",
    code: "+358",
    iso: "AX",
    native: "Åland",
  },
  {
    name: "Albania",
    code: "+355",
    iso: "AL",
    native: "Shqipëria",
  },
  {
    name: "Algeria",
    code: "+213",
    iso: "DZ",
    native: "الجزائر",
  },
  {
    name: "American Samoa",
    code: "+1",
    iso: "AS",
    native: "American Samoa",
  },
  {
    name: "Andorra",
    code: "+376",
    iso: "AND",
    native: "Andorra",
  },
  {
    name: "Angola",
    code: "+244",
    iso: "AO",
    native: "Angola",
  },
  {
    name: "Anguilla",
    code: "+1",
    iso: "AI",
    native: "Anguilla",
  },
  {
    name: "Antarctica",
    code: "+672",
    iso: "AQ",
    native: "Antarctica",
  },
  {
    name: "Antigua and Barbuda",
    code: "+1",
    iso: "AG",
    native: "Antigua and Barbuda",
  },
  {
    name: "Argentina",
    code: "+54",
    iso: "AR",
    native: "Argentina",
  },
  {
    name: "Armenia",
    code: "+374",
    iso: "AM",
    native: "Հայաստան",
  },
  {
    name: "Aruba",
    code: "+297",
    iso: "AW",
    native: "Aruba",
  },
  {
    name: "Ascension Island",
    code: "+247",
    iso: "AC",
  },
  {
    name: "Australia",
    code: "+61",
    iso: "AU",
    native: "Australia",
  },
  {
    name: "Austria",
    code: "+43",
    iso: "AT",
    native: "Österreich",
  },
  {
    name: "Azerbaijan",
    code: "+994",
    iso: "AZ",
    native: "Azərbaycan",
  },
  {
    name: "Bahamas",
    code: "+1",
    iso: "BS",
    native: "Bahamas",
  },
  {
    name: "Bahrain",
    code: "+973",
    iso: "BH",
    native: "‏البحرين",
  },
  {
    name: "Bangladesh",
    code: "+880",
    iso: "BD",
    native: "Bangladesh",
  },
  {
    name: "Barbados",
    code: "+1",
    iso: "BB",
    native: "Barbados",
  },
  {
    name: "Belarus",
    code: "+375",
    iso: "BY",
    native: "Белару́сь",
  },
  {
    name: "Belgium",
    code: "+32",
    iso: "BE",
    native: "België",
  },
  {
    name: "Belize",
    code: "+501",
    iso: "BZ",
    native: "Belize",
  },
  {
    name: "Benin",
    code: "+229",
    iso: "BJ",
    native: "Bénin",
  },
  {
    name: "Bermuda",
    code: "+1",
    iso: "BM",
    native: "Bermuda",
  },
  {
    name: "Bhutan",
    code: "+975",
    iso: "BT",
    native: "ʼbrug-yul",
  },
  {
    name: "Bolivia",
    code: "+591",
    iso: "BO",
    native: "Bolivia",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "+387",
    iso: "BA",
    native: "Bosna i Hercegovina",
  },
  {
    name: "Botswana",
    code: "+267",
    iso: "BW",
    native: "Botswana",
  },
  {
    name: "Brazil",
    code: "+55",
    iso: "BR",
    native: "Brasil",
  },
  {
    name: "British Indian Ocean Territory",
    code: "+246",
    iso: "IO",
    native: "British Indian Ocean Territory",
  },
  {
    name: "Brunei Darussalam",
    code: "+673",
    iso: "BN",
    native: "Negara Brunei Darussalam",
  },
  {
    name: "Bulgaria",
    code: "+359",
    iso: "BG",
    native: "България",
  },
  {
    name: "Burkina Faso",
    code: "+226",
    iso: "BF",
    native: "Burkina Faso",
  },
  {
    name: "Burundi",
    code: "+257",
    iso: "BI",
    native: "Burundi",
  },
  {
    name: "Cambodia",
    code: "+855",
    iso: "KH",
    native: "Kâmpŭchéa",
  },
  {
    name: "Cameroon",
    code: "+237",
    iso: "CM",
    native: "Cameroon",
  },
  {
    name: "Canada",
    code: "+1",
    iso: "CA",
    native: "Canada",
  },
  {
    name: "Cape Verde",
    code: "+238",
    iso: "CV",
    native: "Cabo Verde",
  },
  {
    name: "Cayman Islands",
    code: "+1",
    iso: "KY",
    native: "Cayman Islands",
  },
  {
    name: "Central African Republic",
    code: "+236",
    iso: "CF",
    native: "Ködörösêse tî Bêafrîka",
  },
  {
    name: "Chad",
    code: "+235",
    iso: "TD",
    native: "Tchad",
  },
  {
    name: "Chile",
    code: "+56",
    iso: "CL",
    native: "Chile",
  },
  {
    name: "China",
    code: "+86",
    iso: "CN",
    native: "中国",
  },
  {
    name: "Christmas Island",
    code: "+61",
    iso: "CX",
    native: "Christmas Island",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "+61",
    iso: "CC",
    native: "Cocos (Keeling) Islands",
  },
  {
    name: "Colombia",
    code: "+57",
    iso: "CO",
    native: "Colombia",
  },
  {
    name: "Comoros",
    code: "+269",
    iso: "KM",
    native: "Komori",
  },
  {
    name: "Congo",
    code: "+242",
    iso: "CG",
    native: "République du Congo",
  },
  {
    name: "Cook Islands",
    code: "+682",
    iso: "CK",
    native: "Cook Islands",
  },
  {
    name: "Costa Rica",
    code: "+506",
    iso: "CR",
    native: "Costa Rica",
  },
  {
    name: "Croatia",
    code: "+385",
    iso: "HR",
    native: "Hrvatska",
  },
  {
    name: "Cuba",
    code: "+53",
    iso: "CU",
    native: "Cuba",
  },
  {
    name: "Cyprus",
    code: "+357",
    iso: "CY",
    native: "Κύπρος",
  },
  {
    name: "Czech Republic",
    code: "+420",
    iso: "CZ",
    native: "Česká republika",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "+243",
    iso: "CD",
    native: "République démocratique du Congo",
  },
  {
    name: "Denmark",
    code: "+45",
    iso: "DK",
    native: "Danmark",
  },
  {
    name: "Djibouti",
    code: "+253",
    iso: "DJ",
    native: "Djibouti",
  },
  {
    name: "Dominica",
    code: "+1",
    iso: "DM",
    native: "Dominica",
  },
  {
    name: "Dominican Republic",
    code: "+1",
    iso: "DO",
    native: "República Dominicana",
  },
  {
    name: "Ecuador",
    code: "+593",
    iso: "EC",
    native: "Ecuador",
  },
  {
    name: "Egypt",
    code: "+20",
    iso: "EG",
    native: "مصر‎",
  },
  {
    name: "El Salvador",
    code: "+503",
    iso: "SV",
    native: "El Salvador",
  },
  {
    name: "Equatorial Guinea",
    code: "+240",
    iso: "GQ",
    native: "Guinea Ecuatorial",
  },
  {
    name: "Eritrea",
    code: "+291",
    iso: "ER",
    native: "ኤርትራ",
  },
  {
    name: "Estonia",
    code: "+372",
    iso: "EE",
    native: "Eesti",
  },
  {
    name: "Eswatini",
    code: "+268",
    iso: "SZ",
    native: "Swaziland",
  },
  {
    name: "Ethiopia",
    code: "+251",
    iso: "ET",
    native: "ኢትዮጵያ",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "+500",
    iso: "FK",
    native: "Falkland Islands",
  },
  {
    name: "Faroe Islands",
    code: "+298",
    iso: "FO",
    native: "Føroyar",
  },
  {
    name: "Fiji",
    code: "+679",
    iso: "FJ",
    native: "Fiji",
  },
  {
    name: "Finland",
    code: "+358",
    iso: "FI",
    native: "Suomi",
  },
  {
    name: "France",
    code: "+33",
    iso: "FR",
    native: "France",
  },
  {
    name: "French Guiana",
    code: "+594",
    iso: "GF",
    native: "Guyane française",
  },
  {
    name: "French Polynesia",
    code: "+689",
    iso: "PF",
    native: "Polynésie française",
  },
  {
    name: "Gabon",
    code: "+241",
    iso: "GA",
    native: "Gabon",
  },
  {
    name: "Gambia",
    code: "+220",
    iso: "GM",
    native: "Gambia",
  },
  {
    name: "Georgia",
    code: "+995",
    iso: "GE",
    native: "საქართველო",
  },
  {
    name: "Germany",
    code: "+49",
    iso: "DE",
    native: "Deutschland",
  },
  {
    name: "Ghana",
    code: "+233",
    iso: "GH",
    native: "Ghana",
  },
  {
    name: "Gibraltar",
    code: "+350",
    iso: "GI",
    native: "Gibraltar",
  },
  {
    name: "Greece",
    code: "+30",
    iso: "GR",
    native: "Ελλάδα",
  },
  {
    name: "Greenland",
    code: "+299",
    iso: "GL",
    native: "Kalaallit Nunaat",
  },
  {
    name: "Grenada",
    code: "+1",
    iso: "GD",
    native: "Grenada",
  },
  {
    name: "Guadeloupe",
    code: "+590",
    iso: "GP",
    native: "Guadeloupe",
  },
  {
    name: "Guam",
    code: "+1",
    iso: "GU",
    native: "Guam",
  },
  {
    name: "Guatemala",
    code: "+502",
    iso: "GT",
    native: "Guatemala",
  },
  {
    name: "Guernsey",
    code: "+44",
    iso: "GG",
    native: "Guernsey",
  },
  {
    name: "Guinea",
    code: "+224",
    iso: "GN",
    native: "Guinée",
  },
  {
    name: "Guinea-Bissau",
    code: "+245",
    iso: "GW",
    native: "Guiné-Bissau",
  },
  {
    name: "Guyana",
    code: "+592",
    iso: "GY",
    native: "Guyana",
  },
  {
    name: "Haiti",
    code: "+509",
    iso: "HT",
    native: "Haïti",
  },
  {
    name: "Holy See (Vatican City State)",
    code: "+39",
    iso: "VA",
    native: "Vaticano",
  },
  {
    name: "Honduras",
    code: "+504",
    iso: "HN",
    native: "Honduras",
  },
  {
    name: "Hong Kong",
    code: "+852",
    iso: "HK",
    native: "香港",
  },
  {
    name: "Hungary",
    code: "+36",
    iso: "HU",
    native: "Magyarország",
  },
  {
    name: "Iceland",
    code: "+354",
    iso: "IS",
    native: "Ísland",
  },
  {
    name: "India",
    code: "+91",
    iso: "IN",
    native: "भारत",
  },
  {
    name: "Indonesia",
    code: "+62",
    iso: "ID",
    native: "Indonesia",
  },
  {
    name: "Iran",
    code: "+98",
    iso: "IR",
    native: "ایران",
  },
  {
    name: "Iraq",
    code: "+924",
    iso: "IQ",
    native: "العراق",
  },
  {
    name: "Ireland",
    code: "+353",
    iso: "IE",
    native: "Éire",
  },
  {
    name: "Isle of Man",
    code: "+44",
    iso: "IM",
    native: "Isle of Man",
  },
  {
    name: "Israel",
    code: "+972",
    iso: "IL",
    native: "יִשְׂרָאֵל",
  },
  {
    name: "Italy",
    code: "+39",
    iso: "IT",
    native: "Italia",
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    code: "+225",
    iso: "CI",
    native: "Côte d'Ivoire",
  },
  {
    name: "Jamaica",
    code: "+1",
    iso: "JM",
    native: "Jamaica",
  },
  {
    name: "Japan",
    code: "+81",
    iso: "JP",
    native: "日本",
  },
  {
    name: "Jersey",
    code: "+44",
    iso: "JE",
    native: "Jersey",
  },
  {
    name: "Jordan",
    code: "+962",
    iso: "JO",
    native: "الأردن",
  },
  {
    name: "Kazakhstan",
    code: "+77",
    iso: "KZ",
    native: "Қазақстан",
  },
  {
    name: "Kenya",
    code: "+254",
    iso: "KE",
    native: "Kenya",
  },
  {
    name: "Kiribati",
    code: "+686",
    iso: "KI",
    native: "Kiribati",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: "+850",
    iso: "KP",
    native: "북한",
  },
  {
    name: "Korea, Republic of South Korea",
    code: "+82",
    iso: "KR",
    native: "대한민국",
  },
  {
    name: "Kosovo",
    code: "+383",
    iso: "XK",
    native: "Republika e Kosovës",
  },
  {
    name: "Kuwait",
    code: "+965",
    iso: "KW",
    native: "الكويت",
  },
  {
    name: "Kyrgyzstan",
    code: "+996",
    iso: "KG",
    native: "Кыргызстан",
  },
  {
    name: "Laos",
    code: "+856",
    iso: "LA",
    native: "ສປປລາວ",
  },
  {
    name: "Latvia",
    code: "+371",
    iso: "LV",
    native: "Latvija",
  },
  {
    name: "Lebanon",
    code: "+961",
    iso: "LB",
    native: "لبنان",
  },
  {
    name: "Lesotho",
    code: "+266",
    iso: "LS",
    native: "Lesotho",
  },
  {
    name: "Liberia",
    code: "+231",
    iso: "LR",
    native: "Liberia",
  },
  {
    name: "Libya",
    code: "+218",
    iso: "LY",
    native: "‏ليبيا",
  },
  {
    name: "Liechtenstein",
    code: "+423",
    iso: "LI",
    native: "Liechtenstein",
  },
  {
    name: "Lithuania",
    code: "+370",
    iso: "LT",
    native: "Lietuva",
  },
  {
    name: "Luxembourg",
    code: "+352",
    iso: "LU",
    native: "Luxembourg",
  },
  {
    name: "Macau",
    code: "+853",
    iso: "MO",
    native: "澳門",
  },
  {
    name: "Madagascar",
    code: "+261",
    iso: "MG",
    native: "Madagasikara",
  },
  {
    name: "Malawi",
    code: "+265",
    iso: "MW",
    native: "Malawi",
  },
  {
    name: "Malaysia",
    code: "+60",
    iso: "MY",
    native: "Malaysia",
  },
  {
    name: "Maldives",
    code: "+960",
    iso: "MV",
    native: "Maldives",
  },
  {
    name: "Mali",
    code: "+223",
    iso: "ML",
    native: "Mali",
  },
  {
    name: "Malta",
    code: "+356",
    iso: "MT",
    native: "Malta",
  },
  {
    name: "Marshall Islands",
    code: "+692",
    iso: "MH",
    native: "M̧ajeļ",
  },
  {
    name: "Martinique",
    code: "+596",
    iso: "MQ",
    native: "Martinique",
  },
  {
    name: "Mauritania",
    code: "+222",
    iso: "MR",
    native: "موريتانيا",
  },
  {
    name: "Mauritius",
    code: "+230",
    iso: "MU",
    native: "Maurice",
  },
  {
    name: "Mayotte",
    code: "+262",
    iso: "YT",
    native: "Mayotte",
  },
  {
    name: "Mexico",
    code: "+52",
    iso: "MX",
    native: "México",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    code: "+691",
    iso: "FM",
    native: "Micronesia",
  },
  {
    name: "Moldova",
    code: "+373",
    iso: "MD",
    native: "Moldova",
  },
  {
    name: "Monaco",
    code: "+377",
    iso: "MC",
    native: "Monaco",
  },
  {
    name: "Mongolia",
    code: "+976",
    iso: "MN",
    native: "Монгол улс",
  },
  {
    name: "Montenegro",
    code: "+382",
    iso: "ME",
    native: "Црна Гора",
  },
  {
    name: "Montserrat",
    code: "+1",
    iso: "MS",
    native: "Montserrat",
  },
  {
    name: "Morocco",
    code: "+212",
    iso: "MA",
    native: "المغرب",
  },
  {
    name: "Mozambique",
    code: "+258",
    iso: "MZ",
    native: "Moçambique",
  },
  {
    name: "Myanmar",
    code: "+95",
    iso: "MM",
    native: "မြန်မာ",
  },
  {
    name: "Namibia",
    code: "+224",
    iso: "NA",
    native: "Namibia",
  },
  {
    name: "Nauru",
    code: "+674",
    iso: "NR",
    native: "Nauru",
  },
  {
    name: "Nepal",
    code: "+977",
    iso: "NP",
    native: "नपल",
  },
  {
    name: "Netherlands",
    code: "+31",
    iso: "NL",
    native: "Nederland",
  },
  {
    name: "Netherlands Antilles",
    code: "+599",
    iso: "AN",
  },
  {
    name: "New Caledonia",
    code: "+687",
    iso: "NC",
    native: "Nouvelle-Calédonie",
  },
  {
    name: "New Zealand",
    code: "+24",
    iso: "NZ",
    native: "New Zealand",
  },
  {
    name: "Nicaragua",
    code: "+505",
    iso: "NI",
    native: "Nicaragua",
  },
  {
    name: "Niger",
    code: "+227",
    iso: "NE",
    native: "Niger",
  },
  {
    name: "Nigeria",
    code: "+234",
    iso: "NG",
    native: "Nigeria",
  },
  {
    name: "Niue",
    code: "+683",
    iso: "NU",
    native: "Niuē",
  },
  {
    name: "Norfolk Island",
    code: "+672",
    iso: "NF",
    native: "Norfolk Island",
  },
  {
    name: "North Macedonia",
    code: "+389",
    iso: "MK",
    native: "Северна Македонија",
  },
  {
    name: "Northern Mariana Islands",
    code: "+1",
    iso: "MP",
    native: "Northern Mariana Islands",
  },
  {
    name: "Norway",
    code: "+47",
    iso: "NO",
    native: "Norge",
  },
  {
    name: "Oman",
    code: "+968",
    iso: "OM",
    native: "عمان",
  },
  {
    name: "Pakistan",
    code: "+92",
    iso: "PK",
    native: "Pakistan",
  },
  {
    name: "Palau",
    code: "+680",
    iso: "PW",
    native: "Palau",
  },
  {
    name: "Palestine",
    code: "+970",
    iso: "PS",
    native: "فلسطين",
  },
  {
    name: "Panama",
    code: "+507",
    iso: "PA",
    native: "Panamá",
  },
  {
    name: "Papua New Guinea",
    code: "+675",
    iso: "PG",
    native: "Papua Niugini",
  },
  {
    name: "Paraguay",
    code: "+595",
    iso: "PY",
    native: "Paraguay",
  },
  {
    name: "Peru",
    code: "+51",
    iso: "PE",
    native: "Perú",
  },
  {
    name: "Philippines",
    code: "+63",
    iso: "PH",
    native: "Pilipinas",
  },
  {
    name: "Pitcairn",
    code: "+870",
    iso: "PN",
    native: "Pitcairn Islands",
  },
  {
    name: "Poland",
    code: "+48",
    iso: "PL",
    native: "Polska",
  },
  {
    name: "Portugal",
    code: "+351",
    iso: "PT",
    native: "Portugal",
  },
  {
    name: "Puerto Rico",
    code: "+1",
    iso: "PR",
    native: "Puerto Rico",
  },
  {
    name: "Qatar",
    code: "+974",
    iso: "QA",
    native: "قطر",
  },
  {
    name: "Reunion",
    code: "+262",
    iso: "RE",
    native: "La Réunion",
  },
  {
    name: "Romania",
    code: "+40",
    iso: "RO",
    native: "România",
  },
  {
    name: "Russia",
    code: "+7",
    iso: "RU",
    native: "Россия",
  },
  {
    name: "Rwanda",
    code: "+250",
    iso: "RW",
    native: "Rwanda",
  },
  {
    name: "Saint Barthelemy",
    code: "+590",
    iso: "BL",
    native: "Saint-Barthélemy",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    code: "+290",
    iso: "SH",
    native: "Saint Helena",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "+1",
    iso: "KN",
    native: "Saint Kitts and Nevis",
  },
  {
    name: "Saint Lucia",
    code: "+1",
    iso: "LC",
    native: "Saint Lucia",
  },
  {
    name: "Saint Martin",
    code: "+590",
    iso: "MF",
    native: "Saint-Martin",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "+508",
    iso: "PM",
    native: "Saint-Pierre-et-Miquelon",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "+1",
    iso: "VC",
    native: "Saint Vincent and the Grenadines",
  },
  {
    name: "Samoa",
    code: "+685",
    iso: "WS",
    native: "Samoa",
  },
  {
    name: "San Marino",
    code: "+378",
    iso: "SM",
    native: "San Marino",
  },
  {
    name: "Sao Tome and Principe",
    code: "+239",
    iso: "ST",
    native: "São Tomé e Príncipe",
  },
  {
    name: "Saudi Arabia",
    code: "+966",
    iso: "SA",
    native: "العربية السعودية",
  },
  {
    name: "Senegal",
    code: "+221",
    iso: "SN",
    native: "Sénégal",
  },
  {
    name: "Serbia",
    code: "+381",
    iso: "RS",
    native: "Србија",
  },
  {
    name: "Seychelles",
    code: "+248",
    iso: "SC",
    native: "Seychelles",
  },
  {
    name: "Sierra Leone",
    code: "+232",
    iso: "SL",
    native: "Sierra Leone",
  },
  {
    name: "Singapore",
    code: "+65",
    iso: "SG",
    native: "Singapore",
  },
  {
    name: "Sint Maarten",
    code: "+1",
    iso: "SX",
    native: "Sint Maarten",
  },
  {
    name: "Slovakia",
    code: "+421",
    iso: "SK",
    native: "Slovensko",
  },
  {
    name: "Slovenia",
    code: "+386",
    iso: "SI",
    native: "Slovenija",
  },
  {
    name: "Solomon Islands",
    code: "+677",
    iso: "SB",
    native: "Solomon Islands",
  },
  {
    name: "Somalia",
    code: "+252",
    iso: "SO",
    native: "Soomaaliya",
  },
  {
    name: "South Africa",
    code: "+27",
    iso: "ZA",
    native: "South Africa",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "+500",
    iso: "GS",
    native: "South Georgia",
  },
  {
    name: "South Sudan",
    code: "+211",
    iso: "SS",
    native: "South Sudan",
  },
  {
    name: "Spain",
    code: "+34",
    iso: "ES",
    native: "España",
  },
  {
    name: "Sri Lanka",
    code: "+94",
    iso: "LK",
    native: "śrī laṃkāva",
  },
  {
    name: "Sudan",
    code: "+249",
    iso: "SD",
    native: "السودان",
  },
  {
    name: "Suriname",
    code: "+597",
    iso: "SR",
    native: "Suriname",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "+47",
    iso: "SJ",
    native: "Svalbard og Jan Mayen",
  },
  {
    name: "Sweden",
    code: "+46",
    iso: "SE",
    native: "Sverige",
  },
  {
    name: "Switzerland",
    code: "+41",
    iso: "CH",
    native: "Schweiz",
  },
  {
    name: "Syrian Arab Republic",
    code: "+963",
    iso: "SY",
    native: "سوريا",
  },
  {
    name: "Taiwan",
    code: "+886",
    iso: "TW",
    native: "臺灣",
  },
  {
    name: "Tajikistan",
    code: "+992",
    iso: "TJ",
    native: "Тоҷикистон",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    code: "+255",
    iso: "TZ",
    native: "Tanzania",
  },
  {
    name: "Thailand",
    code: "+66",
    iso: "TH",
    native: "ประเทศไทย",
  },
  {
    name: "Timor-Leste",
    code: "+670",
    iso: "TL",
    native: "Timor-Leste",
  },
  {
    name: "Togo",
    code: "+228",
    iso: "TG",
    native: "Togo",
  },
  {
    name: "Tokelau",
    code: "+690",
    iso: "TK",
    native: "Tokelau",
  },
  {
    name: "Tonga",
    code: "+676",
    iso: "TO",
    native: "Tonga",
  },
  {
    name: "Trinidad and Tobago",
    code: "+1",
    iso: "TT",
    native: "Trinidad and Tobago",
  },
  {
    name: "Tunisia",
    code: "+216",
    iso: "TN",
    native: "تونس",
  },
  {
    name: "Turkey",
    code: "+90",
    iso: "TR",
    native: "Türkiye",
  },
  {
    name: "Turkmenistan",
    code: "+993",
    iso: "TM",
    native: "Türkmenistan",
  },
  {
    name: "Turks and Caicos Islands",
    code: "+1",
    iso: "TC",
    native: "Turks and Caicos Islands",
  },
  {
    name: "Tuvalu",
    code: "+688",
    iso: "TV",
    native: "Tuvalu",
  },
  {
    name: "Uganda",
    code: "+256",
    iso: "UG",
    native: "Uganda",
  },
  {
    name: "Ukraine",
    code: "+380",
    iso: "UA",
    native: "Україна",
  },
  {
    name: "United Arab Emirates",
    code: "+971",
    iso: "AE",
    native: "دولة الإمارات العربية المتحدة",
  },
  {
    name: "United Kingdom",
    code: "+44",
    iso: "GB",
    native: "United Kingdom",
  },
  {
    name: "United States",
    code: "+1",
    iso: "US",
    native: "United States",
  },
  {
    name: "Uruguay",
    code: "+598",
    iso: "UY",
    native: "Uruguay",
  },
  {
    name: "Uzbekistan",
    code: "+998",
    iso: "UZ",
    native: "O‘zbekiston",
  },
  {
    name: "Vanuatu",
    code: "+678",
    iso: "VU",
    native: "Vanuatu",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    code: "+58",
    iso: "VE",
    native: "Venezuela",
  },
  {
    name: "Vietnam",
    code: "+84",
    iso: "VN",
    native: "Việt Nam",
  },
  {
    name: "Virgin Islands, British",
    code: "+1",
    iso: "VG",
    native: "British Virgin Islands",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "+1",
    iso: "VI",
    native: "United States Virgin Islands",
  },
  {
    name: "Wallis and Futuna",
    code: "+681",
    iso: "WF",
    native: "Wallis et Futuna",
  },
  {
    name: "Yemen",
    code: "+967",
    iso: "YE",
    native: "اليَمَن",
  },
  {
    name: "Zambia",
    code: "+260",
    iso: "ZM",
    native: "Zambia",
  },
  {
    name: "Zimbabwe",
    code: "+263",
    iso: "ZW",
    native: "Zimbabwe",
  },
] as const;
