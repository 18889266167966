import type { GetLanguageTagsResult } from "@cna/api/editor";
import { type Language } from "@cna/api/editor";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

export const DEFAULT_LANGUAGE = "";

export const useLanguagesStore = defineStore("languages", () => {
  const languages: Ref<Language[]> = ref([]);
  const currentLanguage = ref(DEFAULT_LANGUAGE);
  const primaryLanguage = ref(DEFAULT_LANGUAGE);
  const allLanguageTags: Ref<GetLanguageTagsResult["result"]> = ref({});

  const sortedLanguages = computed(() =>
    languages.value.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
  );

  return {
    languages,
    currentLanguage,
    primaryLanguage,
    allLanguageTags,
    sortedLanguages,
  };
});
