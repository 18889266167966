<template>
  <div class="version-history">
    <div v-if="isLocalPortalEnv" class="version-history__button">
      <EditorButton
        title="Monitoring Table"
        :on-click="openMonitoringTableModal"
        button-icon="icon-monitoring"
      />
    </div>
    <SimpleTable :rows="versions" :columns="tableColumnsConfig" row-key="id" />
  </div>
  <VehiclesMonitoringModal
    v-if="showVehiclesMonitoringModal"
    @close="showVehiclesMonitoringModal = false"
  />
  <LocalPublishVersionModal
    v-if="!!localPublishVersion"
    @close="localPublishVersion = undefined"
    :config="{
      modalTitle: `Publish version ${localPublishVersion}`,
      version: localPublishVersion,
    }"
  />
</template>

<script lang="ts">
import EditorButton from "@/components/button/EditorButton.vue";
import SimpleTable from "@/components/table/SimpleTable.vue";
import StatusColumn from "@/components/table/columns/StatusColumn.vue";
import type { TableColumnConfig } from "@/components/table/types";
import { vTooltip } from "@/directives/tooltip";
import { LocalPublishVersionModal, VehiclesMonitoringModal } from "@/modals";
import versionsHistoryService from "@/services/versionsHistory.service";
import { useProjectsStore } from "@/stores/projects";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import type { Snapshot } from "@cna/api/editor";
import { useRouteQuery } from "@cna/common";
import { computed, defineComponent, h, ref } from "vue";

export default defineComponent({
  name: "VersionsHistory",
  components: {
    SimpleTable,
    EditorButton,
    VehiclesMonitoringModal,
    LocalPublishVersionModal,
  },
  setup() {
    const localPublishVersion = useRouteQuery("localPublishVersion", {
      type: Number,
    });

    const showVehiclesMonitoringModal = ref(false);
    const projects = useProjectsStore();
    const versionsHistory = useVersionsHistoryStore();
    const versions = computed(() => versionsHistory.versions);
    const isLocalPortalEnv = computed(() => versionsHistory.isLocalPortalEnv);

    const openMonitoringTableModal = () => {
      showVehiclesMonitoringModal.value = true;
    };

    versionsHistoryService.fetchVersions();
    versionsHistoryService.fetchProjectVehiclesEvents();

    const renderCloudStatusSection = (row: Snapshot) => {
      const { production, preview } = versionsHistory.domains;

      const renderLink = (href: string, tooltip: string, text: string) =>
        vTooltip(tooltip)(
          h(
            "a",
            {
              class: "multiple-statuses-column__link",
              target: "_blank",
              href: href,
              style: "font-weight: bold",
            },
            { default: () => text }
          )
        );

      const mainDomain = row.published
        ? h(
            "span",
            {
              class: "domain-status",
            },
            {
              default: () => [
                "Live on the ",
                renderLink(
                  `https://${production}`,
                  "Production domain will open in a new tab",
                  "Production"
                ),
                " domain.",
              ],
            }
          )
        : undefined;

      const previewDomain = row.previewing
        ? h(
            "span",
            { class: "domain-status" },
            {
              default: () => [
                "Live on the ",
                renderLink(
                  `https://${preview}`,
                  "Staging domain will open in a new tab",
                  "Staging"
                ),
                " domain.",
              ],
            }
          )
        : undefined;

      return [mainDomain, previewDomain].filter(Boolean);
    };

    const renderLocalStatusSection = (row: Snapshot) => {
      const versionNumber = row.id;
      const events = versionsHistory.projectVehiclesEvents;

      const vehiclesWithVersion = events.events_list.filter(
        (x) => x.content_version === versionNumber
      );
      const numberOfVehiclesWithVersion = vehiclesWithVersion.length;

      if (numberOfVehiclesWithVersion < 1) {
        return undefined;
      }

      if (numberOfVehiclesWithVersion === 1) {
        return h(
          StatusColumn,
          { status: "success" },
          () => `Live on vehicle ${vehiclesWithVersion[0].system_id}`
        );
      }

      return h(
        StatusColumn,
        { status: "success" },
        () => `Live on ${numberOfVehiclesWithVersion} vehicles`
      );
    };

    const renderVersion = (row: Snapshot) => {
      const { id } = row;

      const sentence =
        id === projects.currentProject.default_version
          ? `${id} [default]`
          : `${id}`;

      return h("span", {}, sentence);
    };

    const tableColumnsConfig = computed(() => {
      const statusColumn: TableColumnConfig<Snapshot> = {
        type: "Render",
        heading: "Status",
        columnKey: "status",
        width: "25%",
        render: isLocalPortalEnv.value
          ? renderLocalStatusSection
          : renderCloudStatusSection,
      };

      const cols: TableColumnConfig<Snapshot>[] = [
        {
          type: "DateTime",
          heading: "Create time",
          width: "15%",
          columnKey: "created",
        },
        {
          type: "Render",
          heading: "Version",
          width: "10%",
          columnKey: "id",
          render: renderVersion,
        },
        {
          type: "Text",
          heading: "Description",
          width: "35%",
          columnKey: "description",
        },
        {
          type: "Text",
          heading: "Created by",
          width: "20%",
          columnKey: "created_by",
        },
        statusColumn,
        {
          type: "Actions",
          heading: "",
          width: "15%",
          columnKey: "actions",
        },
      ];
      return cols;
    });

    return {
      localPublishVersion,
      tableColumnsConfig,
      versions,
      openMonitoringTableModal,
      isLocalPortalEnv,
      showVehiclesMonitoringModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.version-history {
  padding-top: 20px;
}

.version-history__button {
  display: flex;
  justify-content: right;
}

.version-history:deep(.button) {
  font-size: 14px;
  padding: 7px 11px 9px 12px;
  line-height: 1.14;
  border-radius: 4px;
}

.version-history:deep(.multiple-statuses-column__link) {
  text-decoration: underline;
}

.version-history:deep(.domain-status) {
  word-break: break-all;
  display: block;
}

.version-history:deep(.table-column) {
  font-weight: normal;
  font-size: 14px;

  &:first-child {
    padding-left: 23px;
    font-size: 14px;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
}

.version-history:deep(.domain-status):not(:last-child) {
  margin-bottom: 12px;
}
</style>
