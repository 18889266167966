import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const welcomePageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".welcome-page",
      pageName: PageNames.WELCOME,
      properties: [
        {
          selector: ".page__text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_text",
          modifiers: {
            left: true,
            top: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".button-wrapper",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "connect_button",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".page__below-text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_text_below_form",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".page__email",
          iconType: "text",
          modalType: "EmailRegistrationPayloadsEditingModal",
          templateName: "body",
          fieldName: "email",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit label",
        },
        {
          selector: ".secondary-content",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "welcome_page_secondary_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit Text",
        },
        {
          selector: ".page__login_text_terms_rich_text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "login_text_terms_rich_text",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit label",
        },
        {
          selector: ".footer__rights-text",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "footer",
          fieldName: "footer_text",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit copyright text",
        },
        {
          selector: ".footer__nav-link:nth-child(1)",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "footer",
          fieldName: "terms_and_conditions_label_rich_text",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".footer__nav-link:nth-child(2)",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "footer",
          fieldName: "privacy_policy_label_rich_text",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".footer__nav-link:nth-child(3)",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "footer",
          fieldName: "contact_us_label_rich_text",
          modifiers: {
            outer: true,
            center: true,
            offsetY: -30,
          },
          modalTitle: "Edit text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".welcome-page",
      pageName: PageNames.WELCOME,
      properties: [
        {
          selector: ".header",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "header",
          fieldName: "header",
          modifiers: {
            center: true,
            offsetX: 250,
          },
        },
        {
          selector: ".page__email",
          iconType: "brush",
          modalType: "EmailRegistrationInputSettingsModal",
          templateName: "body",
          fieldName: "email",
          modifiers: {
            left: true,
            outer: true,
          },
        },
        {
          selector: ".checkbox",
          iconType: "brush",
          modalType: "CheckboxThemeModal",
          templateName: "body",
          fieldName: "form_element_checkbox_style",
          modifiers: {
            left: true,
            outer: true,
          },
        },
        {
          selector: ".header__logo",
          iconType: "image",
          modalType: "ImagesEditingModal",
          templateName: "header",
          fieldName: "logo",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".page__main-block",
          iconType: "brush",
          modalType: "PageBackgroundEditingModal",
          templateName: "body",
          fieldName: "background",
          modifiers: {
            outer: true,
            offsetX: 120,
          },
        },
        {
          selector: ".page__main-block",
          iconType: "brush",
          modalType: "PageMainBlockEditingModal",
          templateName: "body",
          fieldName: "main",
          modifiers: {
            top: true,
            center: true,
            offsetY: 100,
          },
        },
        {
          selector: ".button-wrapper",
          iconType: "brush",
          modalType: "ButtonStylesEditingModal",
          templateName: "body",
          fieldName: "button",
          modifiers: {
            outer: true,
          },
        },
        {
          selector: ".footer",
          iconType: "brush",
          modalType: "TextAndBackgroundColorEditingModal",
          templateName: "footer",
          fieldName: "footer",
          modifiers: {
            center: true,
          },
        },
      ],
    },
  ],
]);
