<template>
  <OneColumnLayout>
    <div class="page__main-block">
      <div class="page__text-block">
        <div class="page__text">
          <div class="page__markdown">
            <Markdown :data="texts.returning_text" />
            <form
              class="page__form"
              method="get"
              @submit="onConnectButtonClick"
            >
              <div class="page__text">
                <CommonCheckbox :label="texts.returning_text_terms_label">
                  <template #default>
                    <component
                      :is="termsLinkElementType"
                      class="page__t-a-c-link"
                      to="/terms-and-conditions"
                    >
                      {{ texts.returning_text_terms_link }}
                    </component>
                  </template>
                </CommonCheckbox>
              </div>
              <div class="page__main-block-actions">
                <CommonButton
                  :type="connectButtonType"
                  :config="buttonConfig"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  type ButtonHTMLAttributes,
  type PropType,
} from "vue";
import type { ReturningPageTexts } from "../../../stores";
import { useCommonGeneralStore } from "../../../stores";
import CommonButton from "../../button/CommonButton.vue";
import CommonCheckbox from "../../checkbox/CommonCheckbox.vue";
import { OneColumnLayout } from "../../columns";
import Markdown from "../../markdown/Markdown.vue";

export default defineComponent({
  name: "ReturningPageBody",
  components: { Markdown, CommonButton, CommonCheckbox, OneColumnLayout },
  props: {
    onConnectButtonClick: {
      type: Function as PropType<(event: Event) => void>,
      required: false,
      default: () => {},
    },
    termsLinkElementType: {
      type: String,
      required: false,
      default: "span",
    },
    connectButtonType: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const texts = computed(() => commonGeneral.bodyTexts as ReturningPageTexts);

    const buttonConfig = computed(() => ({
      title: texts.value.returning_button_label,
    }));

    return {
      buttonConfig,
      texts,
    };
  },
});
</script>
