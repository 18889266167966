<template>
  <div class="button-wrapper" :class="stylesButtonType">
    <button
      :type="type"
      class="page__btn"
      :class="buttonClass"
      :style="config.styles"
      :disabled="isDisabled"
      @click="config.onClick"
    >
      <Markdown
        class="page__btn-title"
        v-if="!config.loading"
        tag="span"
        :data="config.title"
      />
      <Spinner v-else />
    </button>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  type ButtonHTMLAttributes,
  type PropType,
} from "vue";
import Spinner from "../spinner/Spinner.vue";
import { type ButtonConfig } from "../types";
import Markdown from "../markdown/Markdown.vue";

export default defineComponent({
  name: "CommonButton",
  components: { Markdown, Spinner },
  props: {
    config: {
      type: Object as PropType<ButtonConfig>,
      required: true,
    },
    type: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
    stylesButtonType: {
      type: String,
      required: false,
      default: "button",
    },
  },
  setup(props) {
    const isDisabled = computed(
      () => props.config.disabled || props.config.loading
    );
    const buttonClass = computed(() => ({ disabled: props.config.disabled }));

    return { isDisabled, buttonClass };
  },
});
</script>

<style lang="scss" scoped>
.button-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-wrapper.text-button {
  width: fit-content;
}

.page__btn-title {
  position: relative;
  z-index: 0;
}

.text-button .page__btn {
  padding: 10px;
  font-size: 16px;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 1px;
  }
}
</style>
