export enum ButtonStyle {
  DARK = "dark",
  LIGHT = "light",
  WHITE = "white",
  GREEN = "green",
}

export interface ButtonProps {
  title?: string;
  onClick: (...args: any[]) => any;
  buttonStyle?: ButtonStyle;
  buttonIcon?: string;
  disabled?: boolean;
  withAcknowledge?: boolean;
  acknowledgeText?: string;
}
