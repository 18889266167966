import { onMounted } from "vue";
import { useProjectsStore } from "@/stores/projects";

export default function usePagesTabSwitcher() {
  // A threshold means the percent of the block height when will be invoked the callback.
  const threshold = [0, 0.05, 0.1, 0.2, 0.3, 0.7, 0.8, 0.9, 1];
  const projects = useProjectsStore();

  onMounted(() => {
    document.querySelectorAll(".page").forEach((page) => io.observe(page));
  });

  const setActivePageName = (pageName: string) =>
    (projects.activePageName = pageName);

  const io = new IntersectionObserver(
    (pages: IntersectionObserverEntry[]) => {
      for (const page in pages) {
        if (pages[page].isIntersecting) {
          const viewportHeight = window.innerHeight;
          // Height on the block that is being scrolled
          const targetBlockHeight = pages[page].boundingClientRect.height;

          // The percentage of the visible height of the block that is being scrolled
          const percentageOfBlockHeightThatIntersectsViewport =
            pages[page].intersectionRatio;

          // Percentage of the block height that should intersect viewport that this block was set as current page.
          const INTERSECTION_RATIO_FOR_SMALL_BLOCK = 0.7;
          const INTERSECTION_RATIO_FOR_BIG_BLOCK = 0.5;

          const isBlockIntersectsMoreThanIntersectionRatioForSmallBlock =
            percentageOfBlockHeightThatIntersectsViewport >
            INTERSECTION_RATIO_FOR_SMALL_BLOCK;
          const isBlockIntersectsMoreThanIntersectionRatioForBigBlock =
            percentageOfBlockHeightThatIntersectsViewport >
            INTERSECTION_RATIO_FOR_BIG_BLOCK /
              (targetBlockHeight / viewportHeight);

          if (
            (targetBlockHeight < viewportHeight &&
              isBlockIntersectsMoreThanIntersectionRatioForSmallBlock) ||
            (targetBlockHeight >= viewportHeight &&
              isBlockIntersectsMoreThanIntersectionRatioForBigBlock)
          ) {
            setActivePageName(pages[page].target.id);
          }
        }
      }
    },
    { threshold }
  );
}
