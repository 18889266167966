import { computed, ref, type Ref } from "vue";

export function useKeyboardEventsHandlerForDropdownWithSearch(
  areMenuItemsShown: Ref<boolean>,
  menuItemsRefs: Ref<Array<HTMLElement>>,
  searchInputRef: Ref<any>,
  searchInput: Ref<string>,
  items: Ref<any>
) {
  const maxIndex = computed(() => items.value.length - 1);
  const minIndex = 0;
  const currentMenuItem = ref(-1);

  const movePrev = (event: any) => {
    if (currentMenuItem.value === 0) {
      currentMenuItem.value = -1;
      searchInputRef.value.root.focus();
      event.preventDefault();
    } else if (currentMenuItem.value > minIndex) {
      event.target.previousElementSibling.focus();
      currentMenuItem.value--;
      event.preventDefault();
    }
  };

  const moveNext = (event: any) => {
    if (currentMenuItem.value === -1) {
      currentMenuItem.value = 0;
      menuItemsRefs.value[currentMenuItem.value].focus();
      event.preventDefault();
    } else if (currentMenuItem.value < maxIndex.value) {
      event.target.nextElementSibling.focus();
      currentMenuItem.value++;
      event.preventDefault();
    } else if (currentMenuItem.value === maxIndex.value) {
      event.preventDefault();
    }
  };

  const closeMenu = () => {
    if (areMenuItemsShown.value) {
      areMenuItemsShown.value = false;
    }
    currentMenuItem.value = -1;
    menuItemsRefs.value = [];
    searchInput.value = "";
  };

  return {
    currentMenuItem,
    movePrev,
    moveNext,
    closeMenu,
    maxIndex,
  };
}
