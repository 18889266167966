<template>
  <fieldset class="radio-button-group">
    <slot name="title"></slot>

    <div class="options">
      <label
        v-for="{ value, label } in options"
        :key="`${value}-${uid}`"
        class="option"
      >
        <input
          type="radio"
          :name="`radio-button-group-${uid}`"
          :value="value"
          :checked="label === valueCopy"
          v-model="valueCopy"
          :required="required"
        />
        <p>{{ label }}</p>
      </label>
    </div>
  </fieldset>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent, type PropType } from "vue";

let uid = 0;

export default defineComponent({
  props: {
    value: [Object, String, Number, Boolean, Date] as PropType<any>,
    options: Object as PropType<{ label: string; value: string }[]>,
    uid: {
      type: Number,
      default() {
        return uid++;
      },
    },
    required: Boolean,
  },
  emits: ["update:value"],
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);

    return {
      valueCopy,
    };
  },
});
</script>

<style lang="scss" scoped>
.radio-button-group {
  display: block;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 14px;
}

input[type="radio"] {
  --width: calc(
    1.4em + var(--form_element_radio_button_style_padding) +
      var(--form_element_radio_button_style_border_width) - 1px
  );
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  min-width: var(--width);
  width: var(--width);
  height: var(--width);
  border-style: var(--form_element_radio_button_style_border_style);
  border-width: var(--form_element_radio_button_style_border_width);
  border-color: var(--form_element_radio_button_style_border_color);
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: border-width 120ms ease-in-out;
}

input[type="radio"]:hover {
  border-width: var(--form_element_radio_button_style_hover_border_width);
  border-color: var(--form_element_radio_button_style_hover_border_color);
}

input[type="radio"]:focus {
  border-width: var(--form_element_radio_button_style_focus_border_width);
  border-color: var(--form_element_radio_button_style_focus_border_color);
}

input[type="radio"]::before {
  --dot-space: calc(
    0.6em + var(--form_element_radio_button_style_border_width) - 1px
  );
  content: "";
  width: calc(var(--width) - var(--dot-space));
  height: calc(var(--width) - var(--dot-space));
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 10em var(--form_element_radio_button_style_color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
