<template>
  <main class="page__body">
    <div class="layout__two-column">
      <div class="layout__first">
        <slot name="first" />
      </div>

      <div class="layout__second">
        <slot name="second" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>

<style scoped lang="scss">
.layout__two-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 8px 5px;

  @media (min-width: calc($icomera-tablet + 1px)) {
    gap: var(--two_column_style_gap);
    justify-content: space-between;
    flex-direction: row;
    max-width: 930px;
    padding: 28px 25px;
    height: fit-content;
  }
}

.layout__first {
  width: 100%;
}

.layout__second {
  width: 100%;
}
</style>
