<template>
  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
  <component data-markdown="" :is="tag" v-html="dataWithVars" />
</template>

<script lang="ts">
import Mustache from "mustache";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  toRefs,
  type PropType,
} from "vue";
import { findTextElement } from "../../utils";
import { injectGetLiveUserCount } from "./providables";

export default defineComponent({
  name: "Markdown",
  props: {
    data: {
      type: String,
    },
    tag: {
      type: String,
      required: false,
      default: "div",
    },
    vars: Object as PropType<any>,
  },
  setup(props) {
    const handleLiveUserCount = () => {
      // inject the live user count function
      const { getLiveUserCount } = injectGetLiveUserCount();
      // if the function does not exist, then we do nothing
      // so, for the editor, we do not have to provide a function,
      // and for the portal we can provide the ombord function
      if (!getLiveUserCount) {
        return;
      }
      const element = document.getElementById("live-user-count")!;

      // missed check here, we should not make any requests if the live-user-count feature is not enabled
      if (!element) {
        return;
      }
      element.innerHTML = "";

      getLiveUserCount()
        .then((count) => {
          element.innerHTML = count;
        })
        .catch(() => {
          const parentParagraph = findTextElement(element, ["div"]);
          parentParagraph.style.display = "none";
        });
    };

    onMounted(handleLiveUserCount);
    onUpdated(handleLiveUserCount);

    const { data, vars } = toRefs(props);
    const dataWithVars = computed(() =>
      data.value ? Mustache.render(data.value, vars.value) : ""
    );

    return {
      dataWithVars,
    };
  },
});
</script>
