<template>
  <h2 class="settings-section-header">
    <span class="flex-row-center">
      <span class="settings-section-header__icon" :class="icon" />
      <span class="settings-section-header__heading">{{ heading }}</span>
    </span>
    <!--    Slot for text in the header-->
    <slot />
  </h2>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsSectionHeader",
  props: {
    heading: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 18px;
  margin: 0;
  background: $grey0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 14px;
}

.settings-section-header__icon {
  margin-right: 8px;
  font-size: 24px;
}

.settings-section-header__heading {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
}
</style>
