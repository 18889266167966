<template>
  <div
    ref="selectedItemRef"
    class="country-codes-dropdown__selected-country flex-row-center"
    tabindex="1"
    @click.prevent="toggleMenu"
    @keyup.enter.exact="toggleMenu"
  >
    <img
      class="country-codes-dropdown__country-image"
      :src="getFlagIcon(selectedCountryInfo?.iso)"
      alt=""
    />
    {{ selectedCountryInfo?.code }}
    <span class="country-codes-dropdown__icon" :class="chevronIcon" />
  </div>
  <ul v-if="areMenuItemsShown" class="country-codes-dropdown__list">
    <li class="country-codes-dropdown__list-item">
      <SearchInput
        ref="searchInputRef"
        :config="searchInputConfig"
        :value="searchInput"
        :no-result="filteredMenuItems.length === 0"
        @update:searchInput="onSearchInputUpdate"
      />
    </li>
    <template v-for="(country, index) in filteredMenuItems" :key="country.iso">
      <li
        ref="menuItemsRefs"
        class="country-codes-dropdown__list-item"
        :tabindex="index"
        @click="onSelectMenuItem(country)"
        @keydown.tab.exact="moveNext"
        @keydown.shift.tab="movePrev"
        @keydown.esc="closeMenu"
        @keyup.enter.exact="onSelectMenuItem(country)"
        @keydown.down="moveNext"
        @keydown.up="movePrev"
      >
        <img
          class="country-codes-dropdown__country-image"
          :src="getFlagIcon(country.iso)"
          loading="lazy"
          alt=""
        />
        <span class="country-codes-dropdown__country-code">
          {{ country.code }}
        </span>
        <span>
          {{ country.name }}
          <span v-if="isShowNativeCountryName(country)"
            >({{ country.native }})</span
          >
        </span>
      </li>
    </template>
  </ul>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUpdate,
  ref,
  type PropType,
  type Ref,
} from "vue";
import { useKeyboardEventsHandlerForDropdownWithSearch } from "../../hooks/useKeyboardEventsHandlerForDropdownWithSearch";
import { getFlagIcon } from "../../utils/stylesUtils";
import { countries } from "../countryCodesDropdown/countries";
import SearchInput from "../searchInput/SearchInput.vue";
import type SimpleInput from "../simpleInput/SimpleInput.vue";
import {
  type CountriesData,
  type CountryData,
  type PreferredCountriesIso,
} from "./types";

export default defineComponent({
  name: "CountryCodesDropdown",
  components: {
    SearchInput,
  },
  props: {
    preferredCountriesIso: {
      type: Array as PropType<PreferredCountriesIso>,
      required: true,
    },
    selectedCountryInfo: {
      type: Object as PropType<CountryData>,
      required: true,
    },
    phoneInputRef: {
      type: Object as PropType<InstanceType<typeof SimpleInput>>,
    },
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const selectedItemRef = ref();
    const sortedCountriesByPreference: Ref<CountriesData> = ref([]);
    const searchInput = ref("");
    const searchInputRef: Ref<InstanceType<typeof SearchInput> | undefined> =
      ref();
    const areMenuItemsShown = ref(false);
    let menuItemsRefs = ref();

    const filteredMenuItemsBySearch = computed(() => {
      let allCountries = [...countries];
      const selectedCountryIndex = allCountries.findIndex(
        (country: CountryData) => country.iso === props.selectedCountryInfo.iso
      );
      allCountries.splice(selectedCountryIndex, 1);

      allCountries = allCountries.filter(
        ({ code, name, native }: CountryData) =>
          code?.toLowerCase().indexOf(`+${searchInput.value.toLowerCase()}`) ===
            0 ||
          name?.toLowerCase().indexOf(searchInput.value.toLowerCase()) === 0 ||
          native?.toLowerCase().indexOf(searchInput.value.toLowerCase()) === 0
      );
      return allCountries;
    });

    const filteredMenuItemsBySelectedCountry = computed(() => {
      const countries = [...sortedCountriesByPreference.value];
      const index = sortedCountriesByPreference.value.findIndex(
        (country: CountryData) => country.iso === props.selectedCountryInfo.iso
      );
      countries.splice(index, 1);

      return countries;
    });

    const filteredMenuItems = computed(() =>
      searchInput.value === ""
        ? filteredMenuItemsBySelectedCountry.value
        : filteredMenuItemsBySearch.value
    );

    const { movePrev, moveNext, closeMenu, currentMenuItem } =
      useKeyboardEventsHandlerForDropdownWithSearch(
        areMenuItemsShown,
        menuItemsRefs,
        searchInputRef,
        searchInput,
        filteredMenuItemsBySearch
      );

    onBeforeMount(() => {
      const preferredCountries: CountriesData = [];
      sortedCountriesByPreference.value = [...countries];
      props.preferredCountriesIso?.forEach((countryIso: string) => {
        const index = sortedCountriesByPreference.value.findIndex(
          (country: CountryData) => country.iso === countryIso
        );

        preferredCountries.push(sortedCountriesByPreference.value[index]);
        sortedCountriesByPreference.value.splice(index, 1);
      });
      sortedCountriesByPreference.value = [
        ...preferredCountries,
        ...sortedCountriesByPreference.value,
      ];

      if (!props.selectedCountryInfo.iso) {
        emit("update:option", sortedCountriesByPreference.value[0]);
        return;
      }

      const index = sortedCountriesByPreference.value.findIndex(
        (country: CountryData) => country.iso === props.selectedCountryInfo.iso
      );

      if (index !== -1) {
        emit("update:option", sortedCountriesByPreference.value[index]);
      }
    });

    onBeforeUpdate(() => {
      menuItemsRefs.value = [];
    });

    const toggleMenu = () => {
      if (areMenuItemsShown.value === true) {
        closeMenu();
      } else {
        areMenuItemsShown.value = true;
        nextTick(() => {
          searchInputRef.value?.root.focus();
        });
      }
    };

    const onSelectMenuItem = (item: CountryData) => {
      closeMenu();
      searchInput.value = "";
      emit("update:option", item);
      nextTick(() => {
        props.phoneInputRef?.root.focus();
      });
    };

    const onClickSearchInput = () => {
      currentMenuItem.value = -1;
    };

    const isShowNativeCountryName = (country: CountryData) =>
      country.name !== country.native && country.native;

    const searchInputConfig = computed(() => ({
      onClick: onClickSearchInput,
      onKeydownEsc: closeMenu,
      onKeydownShiftTab: () => {
        props.phoneInputRef?.root.focus();
        closeMenu();
      },
      onKeydownTabExact: moveNext,
      onKeydownDown: moveNext,
      onKeydownUp: closeMenu,
    }));

    const onSearchInputUpdate = (value: string) => {
      searchInput.value = value;
      searchInputRef.value?.root.focus();
      currentMenuItem.value = -1;
    };

    const chevronIcon = computed(() =>
      areMenuItemsShown.value ? "icon-chevron-up" : "icon-chevron-down"
    );

    return {
      selectedItemRef,
      searchInput,
      searchInputRef,
      areMenuItemsShown,
      menuItemsRefs,
      filteredMenuItems,
      searchInputConfig,
      chevronIcon,
      onSelectMenuItem,
      getFlagIcon,
      toggleMenu,
      closeMenu,
      moveNext,
      movePrev,
      onClickSearchInput,
      isShowNativeCountryName,
      onSearchInputUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.country-codes-dropdown__list {
  @include reset-list;
  position: absolute;
  top: 50px;
  max-height: 355px;
  width: 100%;
  background-color: $white;
  overflow-y: scroll;
  border-radius: 5px;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  z-index: 5;
}

.country-codes-dropdown__list-item {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 45px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $grey7;
  cursor: pointer;

  &:not(:first-child):focus-visible,
  &:not(:first-child):hover {
    background-color: $grey9;
  }

  &:last-child {
    margin-bottom: 15px;
  }
}

.country-codes-dropdown__country-image {
  margin-right: 10px;
  height: 17px;
  width: 24px;
}

.country-codes-dropdown__selected-country {
  padding: 0 28px 0 20px;
  min-width: 125px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 25px;
    background-color: $grey8;
  }

  &:focus-visible {
    outline: none;
  }
}

.country-codes-dropdown__country-code {
  margin-right: 6px;
  min-width: 40px;
  font-weight: 900;
  color: $black;
}

.country-codes-dropdown__icon {
  font-size: 13px;
  position: absolute;
  right: 10px;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>
