<template>
  <div class="checkbox">
    <input
      :id="`checkbox-${id}`"
      class="checkbox__input"
      type="checkbox"
      :checked="value"
      @change="onCheckboxChange(($event.target as HTMLInputElement).checked)"
    />
    <label class="checkbox__label flex-row-center" :for="`checkbox-${id}`">
      <span class="checkbox__title">{{ title }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from "vue";

export default defineComponent({
  name: "EditorCheckbox",
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:option"],
  setup(_, { emit }) {
    const onCheckboxChange = (value: boolean) => emit("update:option", value);
    const id = getCurrentInstance()!.uid;

    return { onCheckboxChange, id };
  },
});
</script>

<style lang="scss" scoped>
.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
}

.checkbox__label:after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  height: 18px;
  width: 18px;
  border: solid 2px $grey14;
}

.checkbox__input:checked + .checkbox__label:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  border: none;
  background: $dark-blue url("../../assets/images/check.svg");
  background-size: 100%;
}

.checkbox__input {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
}

.checkbox__title {
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  user-select: none;
  color: inherit;
}
</style>
