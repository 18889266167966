import {
  withDirectives,
  type App,
  type DirectiveBinding,
  type VNode,
} from "vue";

const setTooltip = (el: any, binding: DirectiveBinding) => {
  const { value, modifiers } = binding;
  const position = Object.keys(modifiers)[0];
  if (value) {
    el.classList.add("tooltip");
    el.style.position = "relative";
    el.setAttribute("text", value.text || value);
    el.setAttribute("position", value.position || position || "top");
  }
};

const tooltipDirective = {
  mounted(el: any, binding: DirectiveBinding) {
    setTooltip(el, binding);
  },
  updated(el: any, binding: DirectiveBinding) {
    setTooltip(el, binding);
  },
};

export const vTooltip = (text: string) => (node: VNode) =>
  withDirectives(node, [[tooltipDirective, text]]);

export default {
  install: (app: App): void => {
    app.directive("tooltip", tooltipDirective);
  },
};
