import { useProjectsStore } from "@/stores/projects";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

import {
  INITIAL_DOMAINS_DATA,
  INITIAL_PROJECT_VEHICLES_EVENTS,
} from "@/stores/INITIAL_VALUES";
import { type Version } from "@cna/api/editor";

export const useVersionsHistoryStore = defineStore("versionsHistory", () => {
  const versions: Ref<Array<Version>> = ref([]);
  const restoringVersionId = ref(-1);
  const publishedDomains = ref(INITIAL_DOMAINS_DATA);
  const projectVehiclesEvents = ref(INITIAL_PROJECT_VEHICLES_EVENTS);
  const VEHICLES_MONITORING_TABLE_PAGE_OFFSET = ref(20);
  const vehiclesMonitoringTableConfig = ref({
    currentPage: 1,
    pagesAmount: 0,
  });
  const selectedVehiclesIDs: Ref<Array<string>> = ref([]);

  const projects = useProjectsStore();

  const lastVersionId = computed(() => versions.value[0].id);
  const isLocalPortalEnv = computed(
    () => projects.currentProject.portal_env === "local"
  );
  const domains = computed(() => {
    const projects = useProjectsStore();
    const preview = publishedDomains.value.domains.preview;
    const previewObject = preview ? { preview } : {};
    return {
      ...(projects.currentProject.domains || {
        production: "",
      }),
      ...previewObject,
    } as { production?: string; preview?: string };
  });

  const vehiclesWithVersion = (version: number) => {
    const vehiclesWithVersion = projectVehiclesEvents.value.events_list.filter(
      (x) => x.content_version === version
    );
    return vehiclesWithVersion;
  };

  const versionIsPublishedToVehicles = (version: number) => {
    const vehicles = vehiclesWithVersion(version);
    return vehicles.length > 0;
  };

  return {
    versions,
    restoringVersionId,
    publishedDomains,
    projectVehiclesEvents,
    VEHICLES_MONITORING_TABLE_PAGE_OFFSET,
    vehiclesMonitoringTableConfig,
    selectedVehiclesIDs,
    lastVersionId,
    isLocalPortalEnv,
    domains,
    versionIsPublishedToVehicles,
  };
});
