<template>
  <div class="db-body flex-column">
    <div class="db-page__body db-page__wi-fi-img">
      <div class="db-page__container">
        <div class="db-page__main-block-container">
          <div class="db-page__main-block">
            <div class="db-page__content">
              <slot />
            </div>
            <slot name="post-content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
