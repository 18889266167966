<template>
  <span v-if="message" class="error-message">
    {{ message }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorMessageInput",
  props: {
    message: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.error-message {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--form_element_input_style_error_msg_color);
}
</style>
