<template>
  <div class="center-view">
    <div class="box">
      <h1 style="margin: 0; line-height: 34px">{{ title }}</h1>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "354px",
    },
  },
});
</script>

<style scoped>
.center-view {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  gap: 16px;
  color: #123962;
}

.box {
  background: white;
  padding: 48px 24px;
  display: flex;
  max-width: v-bind(maxWidth);
  gap: 16px;
  flex-direction: column;
  text-align: center;
}
</style>
