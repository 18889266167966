<template>
  <div class="flex-row-center">
    <span class="column-text">{{ text ?? "" }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextColumn",
  props: {
    text: {
      type: [String, Number],
    },
  },
});
</script>

<style lang="scss" scoped>
.column-text {
  word-break: break-all;
}
</style>
