<template>
  <DBBody>
    <DBPageHeader :logo-url="logoUrl" />
    <div class="db-page__content-info">
      <img
        src="../../../../assets/images/DB/check_online.svg"
        alt=""
        role="presentation"
      />
      <h1 class="db-page__now-connected-title">
        <Markdown :data="bodyTexts.now_connected_text" />
      </h1>
      <DBSmallButton class="db-page__logout-btn" :config="buttonConfig" />
    </div>
    <div v-show="isWithVenueRedirectButton" class="venue-link">
      <a :href="buttonLink" tabindex="-1">
        <DBButton :config="{ title: bodyTexts.venue_redirect_button_label }" />
      </a>
    </div>
  </DBBody>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useOnceAtATime } from "../../../../hooks/useOnceAtATime";
import { useVenueRedirectHelper } from "../../../../hooks/useVenueRedirectHelper";
import { useCommonGeneralStore } from "../../../../stores/general";
import { useCommonImagesStore } from "../../../../stores/images";
import Markdown from "../../../markdown/Markdown.vue";
import DBBody from "../../body/DBBody.vue";
import DBButton from "../../button/DBButton.vue";
import DBSmallButton from "../../button/DBSmallButton.vue";
import DBPageHeader from "../../pageHeader/DBPageHeader.vue";

export default defineComponent({
  name: "NowConnectedBody",
  components: {
    DBBody,
    DBPageHeader,
    DBSmallButton,
    DBButton,
    Markdown,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const commonImages = useCommonImagesStore();

    const bodyTexts = computed(() => commonGeneral.bodyTexts);
    const logoUrl = computed(() => commonImages.headerLogo.link);
    const isWithVenueRedirectButton = computed(
      () =>
        commonGeneral.isWithVenueRedirectButton && commonGeneral.isVenueHealthy
    );
    const { buttonLink } = useVenueRedirectHelper();

    const onLogoutButtonClick = useOnceAtATime(
      commonGeneral.buttonsActions.onLogoutButtonClick
    );
    const buttonConfig = computed(() => ({
      title: bodyTexts.value.logout_button_label,
      onClick: onLogoutButtonClick,
    }));

    return {
      bodyTexts,
      logoUrl,
      buttonConfig,
      isWithVenueRedirectButton,
      buttonLink,
    };
  },
});
</script>

<style lang="scss">
.db-page__content-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $bright-gray;
  margin-top: 68px;
  margin-bottom: 135px;
}

.db-page__logout-btn {
  margin-top: 25px;
}

.db-page__now-connected-title {
  margin-top: 2px;

  > div *,
  > div {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
  }
}
</style>
