import { computed } from "vue";
import { useCommonGeneralStore } from "../stores";
import type { EmailRegistrationTwoColumnPageTexts } from "../stores/types";

export const useEmailRegistrationSecondaryText = (
  fieldName: keyof EmailRegistrationTwoColumnPageTexts
) => {
  const commonGeneral = useCommonGeneralStore();

  const text = computed(
    () =>
      (commonGeneral.bodyTexts as EmailRegistrationTwoColumnPageTexts)[
        fieldName
      ]
  );

  return text;
};
