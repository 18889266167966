import { type ExtractPropTypes } from "vue";
import { modalProps } from "../elements";

export const checkboxThemeModalProps = {
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Edit Checkbox Field Theme",
  },
  ...modalProps,
} as const;

export type CheckboxThemeModalProps = ExtractPropTypes<
  typeof checkboxThemeModalProps
>;
