import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import { type PathBuilder } from "../../utils";
import { client } from "./axios";

type UserMetadata = Result<{
  email: string;
  role_external: string;
  role_internal: string;
}>;

export const permissions = [
  "admin.access",
  "project.files.write",
  "project.design.write",
  "project.email.defaults.write",
  "project.form.write",
  "project.styles.fonts.write",
  "project.write",
  "project.delete",
  "project.version.write",
  "project.version.publish",
  "project.domains.write",
  "template.write",
] as const;

export type Permission = (typeof permissions)[number];

export type GetPermissionsResult = Result<Permission[]>;

export const me = (path: PathBuilder) => {
  const getAuthenticated = (config?: AxiosRequestConfig<undefined>) =>
    client.get<void>(path.slash("authenticated").value, config);

  const getPermissions = (config?: AxiosRequestConfig<undefined>) =>
    client.get<GetPermissionsResult>(path.slash("permissions").value, config);

  const get = (config?: AxiosRequestConfig<undefined>) =>
    client.get<UserMetadata>(path.value, config);

  const postLogout = (config?: AxiosRequestConfig<undefined>) =>
    client.post<Result<{ url: string }>>(
      path.slash("logout").value,
      undefined,
      config
    );

  const postRole = (roleId: string, config?: AxiosRequestConfig<undefined>) =>
    client.post<Result<"role set">>(
      path.slash(`role`).value,
      { external_role: roleId },
      config
    );

  return {
    getPermissions,
    getAuthenticated,
    get,
    postLogout,
    postRole,
  };
};
