<template>
  <div class="loader" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loader",
});
</script>

<style lang="scss" scoped>
.loader {
  background: conic-gradient(
    from 0.25turn,
    rgba(0, 170, 255, 0),
    rgba(0, 170, 255, 0) 0.09turn,
    #00aaff 1turn,
    rgba(0, 170, 255, 0)
  );
  mask: radial-gradient(farthest-side, transparent calc(100% - 10px), $white 0);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  animation: rot 2s linear infinite;
  user-select: none;
}

@keyframes rot {
  100% {
    transform: rotate(360deg);
  }
}
</style>
