<template>
  <div class="editor">
    <div class="editor__content">
      <EditorHeader />
      <div class="editor__route-content">
        <router-view v-if="isProjectDataLoaded" />
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EditorHeader, {
  headerHeight,
} from "@/components/header/EditorHeader.vue";
import { useProjectsStore } from "@/stores/projects";
import { Loader } from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainLayout",
  components: {
    EditorHeader,
    Loader,
  },
  setup() {
    const projects = useProjectsStore();
    const { isProjectDataLoaded } = storeToRefs(projects);

    return {
      headerHeight,
      isProjectDataLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor {
  display: flex;
  height: 100%;
}

.editor__content {
  width: 100%;
  height: fit-content;
  min-height: 100%;
  font-weight: bold;
  background-color: $grey2;
}

.editor__route-content {
  padding-top: v-bind(headerHeight);
  padding-left: 20px;
  padding-right: 20px;
}
</style>
