const isString = (x: any): x is string => typeof x === "string";

const errorMessageReplacers: [RegExp | string, string][] = [
  [
    /(.*)system_ids must have at least \d+ entries(.*)/g,
    "Please select vehicle IDs before publishing.",
  ],
];

export const transformMessage = (message: any) => {
  if (!isString(message)) {
    return message;
  }

  for (const [pattern, newMessage] of errorMessageReplacers) {
    if (isString(pattern) && message.toLowerCase() === pattern.toLowerCase()) {
      return newMessage;
    }
    if (!isString(pattern) && pattern.test(message)) {
      return message.replace(pattern, newMessage);
    }
  }
  return message;
};
