import { useWatchLangQuery } from "@/hooks/useWatchLangQuery";
import { metaKeyOrTextEditor } from "@/routes/router";
import projectsService from "@/services/projects.service";
import saveGeneralDataToCommonStore from "@/utils/commonLibUtils";
import {
  setCssVariablesForImages,
  setCssVariablesForStyles,
  useCommonImagesStore,
  useCommonStylesStore,
} from "@cna/common";
import { defineComponent, h, toRefs, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";

export const WithProjectId = defineComponent({
  name: "with-project-id",
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { projectId } = toRefs(props);

    const commonImages = useCommonImagesStore();
    const commonStyles = useCommonStylesStore();

    useWatchLangQuery();
    saveGeneralDataToCommonStore();

    const initSettings = (projectId: number) => {
      projectsService.updateCurrentProjectId(projectId);
      projectsService.initProjectData();
    };

    watch(
      () => commonStyles,
      () => {
        setCssVariablesForStyles(commonStyles.mainBlockStyles);
        setCssVariablesForStyles(commonStyles.footerStyles);
        setCssVariablesForStyles(commonStyles.headerStyles);
        setCssVariablesForStyles(commonStyles.defaultFonts);
      },
      { deep: true }
    );
    watch(
      () => commonImages,
      () => {
        setCssVariablesForImages(commonImages.bodyBackgroundStyles);
      },
      { deep: true }
    );

    watch(
      projectId,
      (val) => {
        initSettings(+val);

        router.push({
          path: `/${val}/${metaKeyOrTextEditor(route)}`,
          query: { ...route.query },
        });
      },
      { immediate: true }
    );

    return () => h(RouterView);
  },
});
