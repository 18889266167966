<template>
  <section class="domains settings-section">
    <SettingsSectionHeader heading="Domains" icon="icon-domain" />
    <DomainSectionRow
      v-for="(value, key) in domains"
      :key="key"
      :domain="value"
      :type="key"
      :is-creating="isCreating"
      @update:isCreating="onChangeIsCreating"
      :disabled="!projectdomainswrite"
    />
    <SettingsCreateRow
      v-if="!domains.production && projectdomainswrite"
      :config="createDomainButtonConfig"
    />
  </section>
</template>

<script lang="ts">
import { usePermissions } from "@/hooks/usePermissions";
import DomainSectionRow from "@/routes/settings/domainsSection/DomainSectionRow.vue";
import SettingsCreateRow from "@/routes/settings/sectionElements/SettingsCreateRow.vue";
import SettingsSectionHeader from "@/routes/settings/sectionElements/SettingsSectionHeader.vue";
import { useVersionsHistoryStore } from "@/stores/versionsHistory";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "DomainsSection",
  components: {
    SettingsSectionHeader,
    DomainSectionRow,
    SettingsCreateRow,
  },
  setup() {
    const { projectdomainswrite } = usePermissions();
    const versionsHistory = useVersionsHistoryStore();
    const isCreating = ref(false);
    const domains = computed(() => {
      return versionsHistory.domains?.production
        ? versionsHistory.domains
        : { production: "" };
    });
    const createDomainButtonConfig = {
      onClick: () => (isCreating.value = true),
      title: "+ Create Domain",
      rowsQuantity: 1,
    };
    const onChangeIsCreating = (value: boolean) => (isCreating.value = value);

    return {
      projectdomainswrite,
      domains,
      createDomainButtonConfig,
      isCreating,
      onChangeIsCreating,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings-section {
  overflow: hidden;
}
</style>
