<template>
  <ul v-if="config.items.length > 0" v-click-outside="onClick">
    <li
      v-for="item in config.items"
      :key="item.title"
      class="button-with-menu-list__list-item"
      @click="onClick"
    >
      <button
        class="button-with-menu-list__btn"
        @click="item.onClick()"
        :disabled="item.disabled"
      >
        <i
          v-if="item.icon"
          class="button-with-menu-list__btn--icon"
          :class="item.icon"
        />
        <span class="button-with-menu-list__btn-title">{{ item.title }}</span>
      </button>
      <div class="button-with-menu-list__info-text" v-if="item.infoText">
        <Markdown :data="item.infoText" />
      </div>
    </li>
  </ul>
  <div
    v-else
    class="button-with-menu-list__list-item button-with-menu-list__no-items"
  >
    {{ config.textIfNoItems }}
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import {
  type ItemMenuListProps,
  type MenuListProps,
} from "@/components/buttonWithMenuList/types";
import vClickOutside from "click-outside-vue3";
import { Markdown } from "@cna/common";

export default defineComponent({
  name: "MenuListWithButtons",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { Markdown },
  props: {
    config: {
      type: Object as PropType<MenuListProps>,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup() {
    const onItemClick = (item: ItemMenuListProps) => {
      item.onClick();
    };

    return {
      onItemClick,
    };
  },
});
</script>
