<template>
  <NPopconfirm
    placement="bottom-end"
    :show-icon="false"
    @positive-click="onClick"
  >
    <template #trigger>
      <EditorButton :button-style="buttonStyle" :disabled="disabled">
        <slot>
          {{ title }}
        </slot>
      </EditorButton>
    </template>
    {{ acknowledgeText }}
  </NPopconfirm>
</template>

<script lang="ts">
import { NPopconfirm } from "naive-ui";
import { defineComponent } from "vue";
import EditorButton from "./EditorButton.vue";
import { buttonsSharedProps } from "./buttonsSharedProps";

export default defineComponent({
  name: "ButtonWithAcknowledge",
  components: { EditorButton, NPopconfirm },
  props: {
    ...buttonsSharedProps,
    acknowledgeText: {
      type: String,
      false: true,
      default: "",
    },
  },
});
</script>
