<template>
  <div
    v-if="isDataLoaded && idDataExist"
    class="table"
    :class="[styles, bottomBorderStyle]"
    :style="
      fixed
        ? { position: 'relative', height: fixedHeight, overflow: 'hidden' }
        : undefined
    "
  >
    <TableHeader
      v-if="header"
      ref="tableHeader"
      :columns="columns"
      :style="fixed ? { position: 'absolute', zIndex: '1' } : undefined"
    />
    <div
      v-if="fixed"
      :style="{
        maxHeight: fixedHeight,
        overflow: 'auto',
        position: 'absolute',
        paddingTop: tableHeaderHeight,
        top: '0',
        left: '0',
        right: '0',
      }"
    >
      <TableRow
        v-for="row in rows"
        :key="row"
        :row="row"
        :columns="columns"
        :row-key="rowKey"
      />
    </div>
    <template v-else>
      <TableRow
        v-for="row in rows"
        :key="row"
        :row="row"
        :columns="columns"
        :row-key="rowKey"
      />
    </template>
  </div>

  <div v-if="isDataLoaded && !idDataExist" class="table__no-data">No data</div>
  <div v-if="!isDataLoaded" class="table__loader flex-row-center">
    <LoaderWithText>Data is loading</LoaderWithText>
  </div>
</template>

<script lang="ts">
import TableHeader from "@/components/table/TableHeader.vue";
import TableRow from "@/components/table/TableRow.vue";
import { type TableColumnConfig } from "@/components/table/types";
import { LoaderWithText } from "@cna/common";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  type PropType,
} from "vue";

export default defineComponent({
  name: "SimpleTable",
  components: { TableHeader, TableRow, LoaderWithText },
  props: {
    // array of objects with key, value for each column
    rows: {
      type: Array as PropType<any[]>,
      required: true,
    },
    rowKey: {
      type: String,
      required: true,
    },
    // array of column keys and types by which need to access value from row object
    columns: {
      type: Array as PropType<TableColumnConfig[]>,
      required: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    styles: {
      type: String,
      default: "double-colored",
    },
    isDataLoaded: {
      type: Boolean,
      default: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    fixedHeight: {
      type: String,
      default: "500px",
    },
  },
  setup(props) {
    const tableHeader = ref<InstanceType<typeof TableHeader> | undefined>();
    const idDataExist = computed(() => props.rows?.length > 0);

    const bottomBorderStyle = computed(() => {
      return props.rows.length % 2 !== 0 ? "bottom-border" : "";
    });

    const tableHeaderHeight = ref("55px");

    const setTableHeaderHeight = () => {
      if (!tableHeader.value?.$el) {
        return;
      }

      const style = getComputedStyle(tableHeader.value.$el);

      tableHeaderHeight.value = style.height;
    };

    onMounted(() => {
      setTableHeaderHeight();
      document.addEventListener("resize", setTableHeaderHeight);
    });

    onUnmounted(() => {
      document.removeEventListener("resize", setTableHeaderHeight);
    });

    return {
      tableHeader,
      idDataExist,
      bottomBorderStyle,
      tableHeaderHeight,
    };
  },
});
</script>

<style lang="scss" scoped>
.table {
  background-color: $white;
  border-radius: 8px;
  color: $dark-blue;
  height: 100%;
  border: 1px solid $grey3;
}

.table__loader {
  min-height: 300px;
  justify-content: center;
}

.table__no-data {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-border {
  border-bottom: 1px solid $grey3;
}
</style>
