import {
  type DropdownItems,
  useKeyboardEventsHandlerForDropdownWithSearch,
} from "@cna/common";
import { computed, nextTick, type Ref, ref, type SetupContext } from "vue";

export const useDropdownHelper = (
  items: Ref<DropdownItems>,
  { emit }: SetupContext<{ close: [] }>
) => {
  const areMenuItemsShown = ref(false);
  const searchValue = ref("");
  const searchInputRef = ref();
  const selectedItemRef = ref();
  const menuItemsRefs: Ref<Array<HTMLElement>> = ref([]);

  const { movePrev, moveNext, closeMenu, currentMenuItem } =
    useKeyboardEventsHandlerForDropdownWithSearch(
      areMenuItemsShown,
      menuItemsRefs,
      searchInputRef,
      searchValue,
      items
    );

  const searchInputConfig = computed(() => ({
    onClick: onClickSearchInput,
    onKeydownEsc: toggleMenu,
    onKeydownShiftTab: toggleMenu,
    onKeydownTabExact: moveNext,
    onKeydownDown: moveNext,
    onKeydownUp: toggleMenu,
  }));

  const toggleMenu = () => {
    if (areMenuItemsShown.value === true) {
      onClose();
      selectedItemRef.value.focus();
    } else {
      areMenuItemsShown.value = true;
      nextTick(() => {
        searchInputRef.value.root.focus();
      });
    }
  };

  const onClose = () => {
    closeMenu();
    emit("close");
  };

  const onClickSearchInput = () => {
    currentMenuItem.value = -1;
  };

  return {
    areMenuItemsShown,
    selectedItemRef,
    menuItemsRefs,
    searchValue,
    searchInputRef,
    searchInputConfig,
    moveNext,
    movePrev,
    closeMenu,
    toggleMenu,
    onClose,
  };
};
