<template>
  <RadioButtonGroup
    v-model:value="valueCopy"
    :options="options"
    :required="config.required"
  >
    <template #title>
      <LabelRender class="radio-button-label" tag="legend" :label="label" />
    </template>
  </RadioButtonGroup>
</template>

<script lang="ts">
import type { EmailRegistrationField } from "@cna/api/editor";
import { useVModel } from "@vueuse/core";
import { defineComponent, toRefs, type PropType } from "vue";
import RadioButtonGroup from "../../radioButton/RadioButtonGroup.vue";
import LabelRender from "./LabelRender.vue";
import { useOptionsHelper } from "../hooks/useOptionsHelper";

export default defineComponent({
  components: {
    RadioButtonGroup,
    LabelRender,
  },
  props: {
    value: String,
    config: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);

    const { config } = toRefs(props);
    const { options } = useOptionsHelper(config, valueCopy);

    return {
      valueCopy,
      options,
    };
  },
});
</script>

<style lang="scss" scoped>
.radio-button-label :deep(.input-markdown) {
  color: var(--form_element_radio_button_style_label_color);
}
</style>
