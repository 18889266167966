<script lang="ts">
import { getSnapshotsTableColumnComponentAndProps } from "@/components/table/tableHelpers";
import { type TableColumnConfig } from "@/components/table/types";
import { defineComponent, h, type PropType } from "vue";

export default defineComponent({
  name: "TableColumn",
  props: {
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object as PropType<TableColumnConfig>,
      required: true,
    },
    hasRowDetails: Boolean,
  },
  setup(props, ctx) {
    return () => {
      const { columnComponent, columnProps } =
        getSnapshotsTableColumnComponentAndProps(
          props.row,
          props.column,
          props.hasRowDetails,
          ctx
        );

      const columnStyles = {
        width: props.column.width,
        maxWidth: props.column.width,
        padding: "22px",
        fontSize: "14px",
      };

      if (!columnComponent && props.column.type !== "Render") {
        console.error(props.column);
        throw new Error("invalid column configuration");
      }

      return h(
        "div",
        {
          class: {
            "table-column": true,
            ellipsis: props.column.ellipsis,
          },
          style: [columnStyles, props.column.columnStyle],
        },
        {
          default: () =>
            columnComponent
              ? [h(columnComponent, columnProps)]
              : props.column.type === "Render"
              ? [props.column.render(props.row)]
              : undefined,
        }
      );
    };
  },
});
</script>

<style scoped>
.ellipsis:deep(span) {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  vertical-align: bottom;
}
</style>
