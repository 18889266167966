<template>
  <EditorDropdown
    :items="dropdownOptions"
    :on-change="onSelect"
    :current-option-key="activePageName"
  />
</template>

<script lang="ts">
import { PAGES_CONFIGS } from "@/constants";
import { type Pages } from "@/pages/types";
import { useProjectsStore } from "@/stores/projects";
import { computed, defineComponent, type PropType } from "vue";
import EditorDropdown from "@/components/dropdown/EditorDropdown.vue";

export default defineComponent({
  name: "PagesDropdown",
  components: { EditorDropdown },
  props: {
    pages: {
      type: Object as PropType<Array<Pages>>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(value: Pages) => void>,
      required: true,
    },
  },
  setup(props) {
    const projects = useProjectsStore();
    const activePageName = computed(() => projects.activePageName);
    const dropdownOptions = computed(() => {
      return props.pages.map((page: Pages) => ({
        key: page,
        value: PAGES_CONFIGS.get(page)!.title,
      }));
    });

    return {
      dropdownOptions,
      activePageName,
    };
  },
});
</script>
