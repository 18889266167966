export const entries = <T extends {}>(obj: T) =>
  Object.entries(obj) as [keyof T, T[keyof T]][];

export const paramsSerializer = (params: Record<string | number, any>) => {
  const paramKV: string[] = [];

  entries(params).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      v.forEach((vv) => {
        paramKV.push(encodeURIComponent(k) + "=" + encodeURIComponent(vv));
      });
    } else if (v !== null && v !== undefined) {
      paramKV.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  });

  return paramKV.join("&");
};
