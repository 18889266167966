import {
  type EditIconConfig,
  type RouteEditIconConfig,
} from "@/mixins/editIcon/types";
import { PageNames } from "@/pages/types";

export const dbNowConnectedPageConfig: RouteEditIconConfig = new Map<
  string,
  EditIconConfig
>([
  [
    "text-editor",
    {
      rootSelector: ".db_now-connected-page",
      pageName: PageNames.DB_NOW_CONNECTED,
      properties: [
        {
          selector: ".db-page__now-connected-title",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "now_connected_text",
          modifiers: {
            left: true,
            outer: true,
          },
          modalTitle: "Edit text",
        },
        {
          selector: ".db-page__logout-btn",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "logout_button_label",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".db-primary-button",
          iconType: "text",
          modalType: "OneTextFieldEditingModal",
          templateName: "body",
          fieldName: "venue_redirect_button_label",
          modifiers: {
            outer: true,
          },
          modalTitle: "Edit button label",
        },
        {
          selector: ".db-page__below-main-block-content-text",
          iconType: "text",
          modalType: "MarkdownEditingModal",
          templateName: "body",
          fieldName: "below_main_block_content",
          modifiers: {
            top: true,
            left: true,
            offsetX: 160,
          },
          modalTitle: "Edit text",
        },
      ],
    },
  ],
  [
    "theme-editor",
    {
      rootSelector: ".db_now-connected-page",
      pageName: PageNames.DB_NOW_CONNECTED,
      properties: [],
    },
  ],
]);
