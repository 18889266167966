<template>
  <OneColumnLayout>
    <div class="page__main-block">
      <div class="page__text-block">
        <div class="page__text">
          <div class="page__markdown">
            <Markdown :data="texts.email_verification_text" />
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { type EmailVerificationPageTexts } from "../../../stores/types";
import { OneColumnLayout } from "../../columns";
import Markdown from "../../markdown/Markdown.vue";
import { useCommonGeneralStore } from "@cna/common";

export default defineComponent({
  name: "EmailVerificationPageBody",
  components: {
    Markdown,
    OneColumnLayout,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const texts = computed(
      () => commonGeneral.bodyTexts as EmailVerificationPageTexts
    );

    return {
      texts,
    };
  },
});
</script>
