import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../types";
import type { PathBuilder } from "../../utils";
import { client } from "./axios";

export type GetTemplatesResult = Result<Record<string, Record<string, string>>>;

export type PostTemplateBody = {
  name: string;
  display_name: string;
  template_type: string;
};

export type PostStyleBody = {
  template_name: string;
  field_name: string;
  default_value: any;
};

export type PostTextBody = {
  template_name: string;
  field_name: string;
  en_default_value: any;
};

export type PostImageBody = {
  template_name: string;
  field_name: string;
  allowed_mime_types: string[];
  default_file: string;
  default_attributes: any;
  max_size: number;
};

export const templates = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetTemplatesResult>(path.value, config);

  const post = (
    body: PostTemplateBody,
    config?: AxiosRequestConfig<undefined>
  ) => client.post<undefined>(path.value, body, config);

  const postDropInDev = (
    templateName: string,
    config?: AxiosRequestConfig<undefined>
  ) =>
    client.post<undefined>(
      path.slash(templateName).slash("drop_in_dev").value,
      config
    );

  const postStyleField = (
    body: PostStyleBody,
    config?: AxiosRequestConfig<undefined>
  ) =>
    client.post<undefined>(
      path.slash("fields").slash("style").value,
      body,
      config
    );

  const postTextField = (
    body: PostTextBody,
    config?: AxiosRequestConfig<undefined>
  ) =>
    client.post<undefined>(
      path.slash("fields").slash("text").value,
      body,
      config
    );

  const postImageField = (
    body: PostImageBody,
    config?: AxiosRequestConfig<undefined>
  ) =>
    client.post<undefined>(
      path.slash("fields").slash("image").value,
      body,
      config
    );

  return {
    get,
    post,
    postDropInDev,
    postStyleField,
    postImageField,
    postTextField,
  };
};
