import type { AxiosRequestConfig } from "axios";
import type { Result } from "../../../../types";
import type { PathBuilder } from "../../../../utils";
import { client } from "../../axios";

export type GetStylesResult = Result<Record<string, Record<string, any>>>;

export type PostGeneralFontInput = {
  default: any;
  list: {
    id?: string;
    project_id: string;
    title: string;
    value: string;
  }[];
};
export type PostGeneralFontsResult = Result<"success">;

export type PostStyleInput = Record<string, any>;

export type PostStyleResult = Result<Record<string, number>>;

export const styles = (path: PathBuilder) => {
  const get = (config?: AxiosRequestConfig) =>
    client.get<GetStylesResult>(path.value, config);

  const post = (
    templateType: string,
    body: PostStyleInput,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostStyleResult>(path.slash(templateType).value, body, config);

  const postGeneralFonts = (
    body: PostGeneralFontInput,
    config?: AxiosRequestConfig
  ) =>
    client.post<PostGeneralFontsResult>(
      path.slash("general").slash("fonts").value,
      body,
      config
    );

  return {
    get,
    post,
    postGeneralFonts,
  };
};
