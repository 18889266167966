import { useCommonGeneralStore } from "../stores/general";
import { type CustomError } from "../stores/types";

export const isString = (variable: any) => {
  return typeof variable === "string" || variable instanceof String;
};

export const isNullOrUndefinedOrEmptyWhenTrimmed = (str: string) =>
  !str || !str.trim();

export const errorHandler = (error: CustomError | string): string => {
  const commonGeneral = useCommonGeneralStore();
  return isString(error)
    ? (error as string)
    : (error as CustomError)[commonGeneral.currentLanguage];
};

export const objectDeepCopy = <T>(value: T): T => {
  return value && typeof value === "object"
    ? JSON.parse(JSON.stringify(value))
    : null;
};

export const getLocalstorageItem = (item: string): string | null => {
  // LocalStorage doesn’t work properly on Android 9
  if (window.localStorage) {
    return window.localStorage.getItem(item);
  }
  return null;
};

export const setLocalstorageItem = (key: string, value: any): void => {
  // LocalStorage doesn’t work properly on Android 9
  if (window.localStorage) {
    return window.localStorage.setItem(key, value);
  }
};

export const removeLocalstorageItem = (key: string): void => {
  if (window.localStorage) {
    return window.localStorage.removeItem(key);
  }
};

export const entries = <T extends {}>(obj: T) =>
  Object.entries(obj) as [keyof T, T[keyof T]][];

const textElements = ["p", "h6", "h5", "h4", "h3", "h2", "h1"];

export const findTextElement = (
  element: HTMLElement,
  additionalElements: string[] = []
): HTMLElement => {
  const tagName = element.tagName.toLowerCase();
  if ([...textElements, ...additionalElements].includes(tagName)) {
    return element;
  }

  const parent = element.parentElement;

  if (parent === null || parent.classList.contains("fr-element")) {
    return element;
  }

  return findTextElement(parent, additionalElements);
};
