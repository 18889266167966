import { ref } from "vue";
import type { Maybe } from "../types";

export const useOnceAtATime = <P extends any[], R>(
  fn: Maybe<(...args: P) => R>
) => {
  const running = ref(false);

  const newFn = async (...args: P) => {
    if (running.value || !fn) {
      return;
    }

    running.value = true;
    try {
      return await fn(...args);
    } finally {
      running.value = false;
    }
  };

  return newFn;
};
