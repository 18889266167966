import { type JSON } from "./types";

export type MemoizeReturn<P extends any[], R> = (
  key?: JSON,
  ...args: [...P]
) => R;

export type AsyncMemoizeReturn<P extends any[], R> = MemoizeReturn<
  P,
  Promise<R>
>;

const createKey = (
  key: JSON | undefined = undefined,
  combineKeyAndArgs: boolean = false,
  args: any[]
) => {
  let argsKey;
  if (key && combineKeyAndArgs) {
    argsKey = JSON.stringify({ key, args });
  } else if (key) {
    argsKey = JSON.stringify(key);
  } else {
    argsKey = JSON.stringify(args);
  }
  return argsKey;
};

export const asyncMemoize = <P extends any[], R>(
  fn: (...args: [...P]) => Promise<R>,
  combineKeyAndArgs = false,
  timeout?: number
): AsyncMemoizeReturn<P, R> => {
  const cache = new Map<string, R>();
  const timeAdded = new Map<string, number>();
  const promiseCache = new Map<string, Promise<R>>();
  const promiseTimeAdded = new Map<string, number>();

  return async (key, ...args) => {
    const argsKey = createKey(key, combineKeyAndArgs, args);

    const notTimedOut =
      !timeout ||
      Boolean(
        timeAdded.has(argsKey) &&
          new Date().getTime() - timeAdded.get(argsKey)! <= timeout
      );

    const promiseNotTimedOut =
      !timeout ||
      Boolean(
        promiseTimeAdded.has(argsKey) &&
          new Date().getTime() - promiseTimeAdded.get(argsKey)! <= timeout
      );

    const hasCacheValue = Boolean(cache.has(argsKey) && notTimedOut);
    const hasPromiseCacheValue = Boolean(
      promiseCache.has(argsKey) && promiseNotTimedOut
    );

    if (hasCacheValue) {
      return cache.get(argsKey)!;
    }

    if (hasPromiseCacheValue) {
      return await promiseCache.get(argsKey)!;
    }

    const prom = fn(...args);
    promiseCache.set(argsKey, prom);
    if (timeout) promiseTimeAdded.set(argsKey, new Date().getTime());

    try {
      const result = await prom;
      cache.set(argsKey, result);
      if (timeout) timeAdded.set(argsKey, new Date().getTime());
      return result;
    } catch (e) {
      // do not cache rejected promises
      promiseCache.delete(argsKey);
      if (timeout) promiseTimeAdded.delete(argsKey);

      throw e;
    }
  };
};
